import { DependencyList, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * Works like useMemo, except it uses a deep comparison to the previous memoization result to return
 * a value with a stable identity
 */
export const useDeepMemo = <T>(factory: () => T, deps: DependencyList): T => {

  // adding the factory to the deps list would void memoization, as the factory has an unstable identity
  // eslint-disable-next-line
  const value = useMemo<T>(factory, [deps]);
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {

    ref.current = value;

  }

  return ref.current;

};
