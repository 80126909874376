import React, { FC } from 'react';

import moment from 'moment';

import { useTimer } from 'lib';

export type TimerProps = {
  initialTimeMs: number;
};

const Timer: FC<TimerProps> = (props) => {

  const {
    initialTimeMs,
  } = props;

  const time = moment.utc(useTimer(initialTimeMs));

  return (
    <>
      {time.format(time.hours() > 0 ? 'HH:mm:ss' : 'mm:ss')}
    </>
  );

};

export default Timer;
