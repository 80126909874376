import React, { FC, ReactNode } from 'react';

import { AsyncStatus } from 'types';
import { Loader } from 'components/atoms';

type Renderable = ReactNode | ((defaultView?: ReactNode) => ReactNode);

type WithAsyncProps = {
  status: AsyncStatus;
  loading?: Renderable;
  error: Renderable;
  children: Renderable;
};

const render = (component: Renderable, defaultView?: ReactNode): ReactNode => {

  return typeof component === 'function' ? component(defaultView) : (component ?? defaultView);

};

export const WithAsync: FC<WithAsyncProps> = ({
  status,
  loading,
  error,
  children,
}) => {

  let Component: ReactNode = null;

  switch (status.code) {

  case 'idle':
    Component = null;
    break;
  case 'pending':
    Component = render(
      loading, (
        <Loader/>
      ),
    );
    break;
  case 'rejected':
    Component = render(error);
    break;
  case 'fulfilled':
    Component = render(children);
    break;
  default:
    throw new Error(`${status} out of range`);

  }

  return (
    <>
      {Component}
    </>
  );

};
