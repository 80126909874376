import { useMemo } from 'react';
import { isPointInPolygon } from 'geolib';
import { Overlay, PointGeometry } from '@x-guard/xgac-types/xgac';

const getIsInsideBounds = (overlay: Overlay, currentPosition: PointGeometry): boolean => { // eslint-disable-line @typescript-eslint/no-unused-vars

  const {
    ne, nw, sw, se,
  } = overlay.points;

  return isPointInPolygon(currentPosition.coordinates, [ne, nw, sw, se]);

};

// Returns a list of map overlays to display.
export const useMultiFloorOverlays = (overlays: Overlay[], currentPosition: PointGeometry): Overlay[] => {

  return useMemo(() => {

    if (!currentPosition || !overlays) return overlays;

    return overlays.filter(() => {

      /* TODO: Add overlay level.
      // Only perform checks on overlays that have a level
      if (typeof overlay.meta.level === 'number' && getIsInsideBounds(overlay, currentPosition)) {

        // If the current position is inside the bounds of the map overlay, check if floors match
        return overlay.meta.level === currentPosition.floor;

      }
       */

      return true;

    });

  }, [overlays, currentPosition]);

};
