import { SyntheticEvent } from 'react';

export const pause = (ms: number): Promise<void> => new Promise<void>((resolve) => {

  setTimeout(resolve, ms);

});

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => { };

// Creates an abort controller that aborts when the parent signal is aborted.
// This is handy for cleaning up short-lived tasks that are ran under a long-running parent task
export const childController = (signal: AbortSignal): AbortController => {

  const controller = new AbortController();

  signal.addEventListener('abort', () => {

    controller.abort();

  });

  return controller;

};

export const stopPropagation = (e: SyntheticEvent): void => {

  e.stopPropagation();

};
