import React, { FC, CSSProperties } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type TextVariant = 'span' | 'boldSpan' | 'contentSpan' | 'titleSpan' | 'h6' | 'h5' | 'h4' | 'label';

type TextTheme = 'light' | 'dark';

type TextProps = {
  variant?: TextVariant;
  theme?: TextTheme;
  className?: string;
  textAlign?: CSSProperties['textAlign'];
  onClick?: () => void;
};

const getIntrinsicHTMLTag = (variant: TextVariant): string => {

  switch (variant) {

  case 'h4':
    return 'h4';
  case 'h5':
    return 'h5';
  case 'h6':
    return 'h6';
  default:
    return 'span';

  }

};

export const Text: FC<TextProps> = ({
  children,
  variant,
  theme = 'light',
  textAlign = 'initial',
  className,
  onClick = null,
}) => {

  const htmlTag = getIntrinsicHTMLTag(variant);

  return (
    <div
      className={cx(
        styles[variant],
        styles[theme],
        onClick && styles.clickable,
        className,
      )}
      onClick={onClick}
    >
      {React.createElement(htmlTag, {
        style: {
          textAlign,
        },
      }, children)}
    </div>
  );

};
