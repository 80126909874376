import React, {
  FC, memo, useMemo, useRef,
} from 'react';
import cx from 'clsx';
import { ErrorBoundary, WithAsync } from 'components/molecules';
import { useHeaderLocation } from 'components/organisms';
import { useSelector } from 'react-redux';
import { errorSelector, alarmDetailsStatusSelector, completeMergeHistorySelector } from 'features/details';
import { Navigate } from 'react-router-dom';
import { useToggle, useLocale } from 'lib';
import { ackedSelector } from 'features/details/ack/exports';
import styles from './style.module.scss';

import { Contact } from './contact';
import { ProvideAlarmContext } from './provideAlarmContext';
import { Chat } from './chat';
import { Info } from './info';
import { Helpers } from './helpers';
import { Acknowledge, AcknowledgementSurvey } from './acknowledge';
import { AlarmMap } from './map';
import { Procedure } from './procedure';
import { useResponsiveScrollContainer, ScrollContainerAlignment } from './useResponsiveScrollContainer';
import { useBlockNotifications } from './useBlockNotifications';
import { useAlarmId } from './useAlarmId';
import { useFetchAlarmDetails } from './useFetchAlarmDetails';
import { Response } from './response';
import { DynamicConferenceCall } from './dynamicConferenceCall';
import { MergeHistory } from './mergeHistory';
import { AlarmContent } from './alarmContent';
import { AlarmProfessionalResponderOverlay } from './response/professionalResponder/overlay';

const InfoSection = () => (
  <section>
    <ErrorBoundary>
      <Info/>
    </ErrorBoundary>
  </section>
);

const HelpersSection = () => (
  <section>
    <ErrorBoundary>
      <Helpers/>
    </ErrorBoundary>
  </section>
);

const AcknowledgeSection = () => {

  const acked = useSelector(ackedSelector);

  return (!acked || null) && (
    <section>
      <ErrorBoundary>
        <Acknowledge/>
      </ErrorBoundary>
    </section>
  );

};

const ProcedureSection = () => (
  <div className={styles.responseContainer}>
    <ErrorBoundary>
      <Procedure/>
    </ErrorBoundary>
    <ErrorBoundary>
      <Response/>
    </ErrorBoundary>
  </div>
);

const ChatSection = () => (
  <section>
    <ErrorBoundary>
      <Chat/>
    </ErrorBoundary>
  </section>
);

const MergedAlarmsSection = () => {

  const mergedAlarms = useSelector(completeMergeHistorySelector);

  return mergedAlarms.length > 1 && (
    <section>
      <ErrorBoundary>
        <MergeHistory/>
      </ErrorBoundary>
    </section>
  );

};

const AlarmDetailsContent: FC<{ alignment: ScrollContainerAlignment }> = ({ alignment }) => {

  return alignment === 'doubleColumn' ? (
    <>
      {/* LEFT COLUMN */}
      <div className={styles.column}>
        <InfoSection/>
        <MergedAlarmsSection/>
        <HelpersSection/>
        <ChatSection/>
        <AcknowledgeSection/>
      </div>
      {/* RIGHT COLUMN */}
      <div className={styles.column}>
        <ProcedureSection/>
      </div>
    </>
  ) : (
    <div className={styles.column}>
      <InfoSection/>
      <MergedAlarmsSection/>
      <ProcedureSection/>
      <HelpersSection/>
      <Chat/>
      <AcknowledgeSection/>
    </div>
  );

};

const AlarmDetails = memo(() => {

  const [fullscreen, toggleFullscreen] = useToggle(false);
  const [scrollContainerRef, alignment] = useResponsiveScrollContainer();
  const alarmContentRef = useRef<HTMLDivElement>(null);
  const status = useSelector(alarmDetailsStatusSelector);

  return (
    <WithAsync status={status} error={null}>
      {/* Render when finished loading */}
      {() => (
        <div className={styles.verticalLayout}>
          <div className={styles.alarmContentContainer}>
            <AlarmContent ref={alarmContentRef}>
              <ErrorBoundary>
                <div className={cx(
                  styles.detailsContainer,
                  fullscreen && styles.hide,
                )}>
                  <ErrorBoundary>
                    <DynamicConferenceCall/>
                  </ErrorBoundary>
                  <div
                    ref={scrollContainerRef}
                    className={cx(styles.scrollContainer, styles[alignment])}
                  >
                    <AlarmDetailsContent alignment={alignment}/>
                  </div>
                </div>
              </ErrorBoundary>
              <ErrorBoundary>
                <AlarmMap
                  parentItemRef={alarmContentRef}
                  fullscreen={fullscreen}
                  toggleFullscreen={toggleFullscreen}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <Contact containerRef={alarmContentRef} />
              </ErrorBoundary>
              <ErrorBoundary>
                <AcknowledgementSurvey/>
              </ErrorBoundary>
              <ErrorBoundary>
                <AlarmProfessionalResponderOverlay/>
              </ErrorBoundary>
            </AlarmContent>
          </div>
        </div>
      )}
    </WithAsync>
  );

});

export const AlarmDetailsGuard = memo(() => {

  const { t } = useLocale();
  const error = useSelector(errorSelector);
  const alarmId = useAlarmId();

  useBlockNotifications();
  useFetchAlarmDetails(alarmId);
  useHeaderLocation(useMemo(() => ({
    label: t('TopBar_AlarmDetails'),
    location: `/alarms/${alarmId}`,
    order: 1,
  }), [t, alarmId]));

  // Return to alarms root when error occurs
  if (error || !alarmId) {

    return <Navigate to={'../'}/>;

  }

  return (
    <ProvideAlarmContext>
      <AlarmDetails/>
    </ProvideAlarmContext>
  );

});
