import React, { FC } from 'react';

import { useLocale } from 'lib';
import {
  APP_VERSION, DISCLAIMER_HREF, PRIVACY_HREF, STATUS_HREF, TERMS_AND_CONDITIONS_HREF,
} from 'config';
import { LanguageArray } from 'components/molecules';
import styles from './style.module.scss';

type AnchorProps = {
  href: string;
};

const Anchor: FC<AnchorProps> = ({ href, children }) => {

  return (
    <a target={'_blank'} rel={'noreferrer noopener'} href={href}>
      {children}
    </a>
  );

};

export const Footer: FC = () => {

  const { t } = useLocale();

  return (
    <div className={styles.footer}>
      <Anchor href={TERMS_AND_CONDITIONS_HREF}>{t('Footer_TermsAndConditions')}</Anchor>
      <div className={styles.spacer}/>
      <Anchor href={DISCLAIMER_HREF}>{t('Footer_Disclaimer')}</Anchor>
      <div className={styles.spacer}/>
      <Anchor href={PRIVACY_HREF}>{t('Footer_Privacy')}</Anchor>
      <div className={styles.spacer}/>
      <Anchor href={STATUS_HREF}>{t('Footer_Status')}</Anchor>
      <div className={styles.languageArrayContainer}>
        <LanguageArray/>
      </div>
      <span>{t('AppVersion', { semVer: APP_VERSION })}</span>
    </div>
  );

};
