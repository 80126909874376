import React, { FC } from 'react';

import { useLocale } from 'lib';
import { useSelector } from 'react-redux';
import { alarmCreatorResponderSelector, allowAlarmCreatorContactSelector } from 'features/details';
import { Title } from '../title';
import { ContactableSubsection } from '../contactableSubsection';
import { Section } from '../section';

/**
 * Displays the section dedicated to the customer that made the alarm
 */
export const AlarmCreatorSection: FC = () => {

  const { t } = useLocale();
  const responder = useSelector(alarmCreatorResponderSelector);
  const allowAlarmCreatorContact = useSelector(allowAlarmCreatorContactSelector);

  return (
    <Section
      title={(
        <Title
          variant={'danger'}
          text={t('Alarm_Detail_Response.Section.AlarmCreator.Heading')}
        />
      )}
    >
      <ContactableSubsection
        responder={responder}
        hideDistanceLabel={true}
        hideContactChip={!allowAlarmCreatorContact}
      />
    </Section>
  );

};
