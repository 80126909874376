import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { Box, Image } from 'react-html-email';
import { Section } from './section';
import { Text } from './text';

type PersonalInformationProps = {
  name: string;
  image: string;
};

export const PersonalInformation: FC<PersonalInformationProps> = ({
  name,
  image,
}) => {

  return (
    <Section sectionTitle={'Personal Information'}>
      <Box width={'100%'} bgcolor={colors.backgroundCultured}>
        <Text
          padding={'12px'}
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={'18px'}
        >
          {name}
        </Text>
        {image && (
          <Image
            height={200}
            width={200}
            src={image}
            alt={'Profile picture'}
            style={{
              margin: '0 auto 24px auto',
            }}
          />
        )}
      </Box>
    </Section>
  );

};
