import { createContext } from 'react';

export type HeaderContext = {
  path: HeaderLocation[];
  pushLocation: (location: HeaderLocation) => () => void;
};

export type HeaderLocation = {
  location: string;
  label: string;
  order: number;
};

export const headerContext = createContext<HeaderContext>({
  path: [],
  // noop
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pushLocation: () => () => { },
});
