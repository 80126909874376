import { useContext, useEffect } from 'react';
import { headerContext, HeaderLocation } from './headerContext';

export const useHeaderLocation = (location: HeaderLocation): void => {

  const context = useContext(headerContext);

  useEffect(() => {

    const cleanUp = context.pushLocation(location);

    return () => {

      cleanUp();

    };
    // Don't include context, otherwise an infinite effect loop would occur

  }, [location]); // eslint-disable-line

};
