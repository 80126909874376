import {
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
  api, advancedQs, createThunk, withPollableRetrying,
} from 'lib';
import {
  AbortableThunkArg, AsyncStatus, LonLat,
  MapState, Page, PopulatedAlarm,
  S,
} from 'types';
import { createAction } from '@reduxjs/toolkit';
import { Overlay } from '@x-guard/xgac-types/xgac';
import { cloneDeep } from 'lodash';

export const setFocusedPosition = createAction<LonLat>('details/map/setFocusedPosition');

const name = 'details/map';

export type FetchMapOverlayArgs = AbortableThunkArg & {
  alarm: PopulatedAlarm;
};

export const fetchMapOverlay = createThunk<Overlay[], FetchMapOverlayArgs>({
  name,
  handler: async ({ alarm, controller: { signal } }): Promise<Overlay[]> => {

    const query = advancedQs({
      'customer._id': alarm.customer._id,
    });

    const { data } = await api.get<Page<Overlay>>(`/overlays?${query}`, {
      signal,
    });

    return data.result;

  },
});

export const map = createSlice<MapState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    focusedPosition: null,
  },
  extraReducers: (builder) => builder
    .addCase(setFocusedPosition, (state, action) => {

      state.focusedPosition = cloneDeep(action.payload);

    })
    .addCase(fetchMapOverlay.pending, withPollablePending)
    .addCase(fetchMapOverlay.fulfilled, withPollableFulfilled)
    .addCase(fetchMapOverlay.rejected, withPollableRejected)
    .addCase(fetchMapOverlay.retrying, withPollableRetrying),
});

export const focusedPositionSelector: S<LonLat> = (state) => state.details.map.focusedPosition;
export const statusSelector: S<AsyncStatus> = (state) => state.details.map.status;
export const valueSelector: S<Overlay[]> = (state) => state.details.map.value;
