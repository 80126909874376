import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { Box } from 'react-html-email';
import { Section } from './section';
import { Text } from './text';

type MessageProps = {
  message: string;
};

export const Message: FC<MessageProps> = ({ message }) => {

  return (
    <Section sectionTitle={'Bericht van de centralist'}>
      <Box style={{
        width: '100%',
        backgroundColor: colors.backgroundCultured,
      }}>
        <Text padding={'8px 16px'} textAlign={'center'}>
          {message}
        </Text>
      </Box>
    </Section>
  );

};
