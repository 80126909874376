import React, {
  FC, useCallback, useRef, useState,
} from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { imageOverlayContext, useDelayedUnmount } from 'lib';
import { Overlay } from 'components/molecules';
import { mdiClose } from '@mdi/js';
import styles from './style.module.scss';

export const ImageOverlay: FC = ({ children }) => {

  const srcRef = useRef<string>(null);
  const [active, setActive] = useState(false);

  const src = srcRef.current;
  const render = useDelayedUnmount(active, 250);
  const setSrc = useCallback((newSrc: string) => {

    srcRef.current = newSrc;

    setActive(true);

  }, []);

  const dismiss = useCallback(() => {

    setActive(false);

  }, []);

  return (
    <imageOverlayContext.Provider value={{
      setSrc,
    }}>
      {render && (
        <div className={cx(styles.shadow, active && styles.active)}>
          <div className={styles.imageContainer}>
            <Overlay
              className={styles.dialog}
              onDismiss={dismiss}
            >
              <div className={styles.close} onClick={dismiss}>
                <Icon path={mdiClose}/>
              </div>
              <img src={src} alt={`Popup for (${src})`}/>
            </Overlay>
          </div>
        </div>
      )}
      {children}
    </imageOverlayContext.Provider>
  );

};
