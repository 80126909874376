import { XGAC_AUTH_API_BASE_URL } from 'config';

export const useRedirectUri = () => {

  return `${window.location.protocol}//${window.location.host}/auth/callback`;

};

export const useLoginHRef = () => {

  const redirectUri = useRedirectUri();

  return `${XGAC_AUTH_API_BASE_URL}/auth/login?redirectUri=${redirectUri}`;

};
