import React, { FC, useMemo } from 'react';

import { ModalGridItem } from 'components/molecules';
import { useLocale } from 'lib';
import { mdiEmail } from '@mdi/js';
import type { ContactOptionModalItemProps } from '..';

export const EmailOption: FC<ContactOptionModalItemProps> = ({
  responder,
  onClick,
}) => {

  const { t } = useLocale();

  const [title, subtitle] = useMemo(() => {

    return [
      t('Alarm_Detail_Contact.Email.Title'),
      responder.actions.email.length > 1
        ? t('Alarm_Detail_Contact.Email.Subtitle.other')
        : t('Alarm_Detail_Contact.Email.Subtitle.one'),
    ];

  }, [t, responder]);

  return (
    <ModalGridItem
      iconPath={mdiEmail}
      label={title}
      subtitle={subtitle}
      onClick={onClick}
    />
  );

};
