const env = <T extends string | boolean = string>(key: string, defaultValue?: T): T => {

  const value = import.meta.env[key] ?? defaultValue;

  if (typeof value === 'undefined') {

    throw new Error(`env key: ${key} is undefined. Make sure it's defined`);

  }

  return value as unknown as T;

};

export const XG_SENTRY_KEY = 'https://c3eaeb81fa054ad08d57254ae8f366b8@o146569.ingest.sentry.io/5285527';
export const AUTO_TOKEN_LOGIN_TIMEOUT_HOURS = 1;
export const STATIC_RESPONSE_DISTANCE_THRESHOLD = 250;
export const MAP_AUTOZOOM_BOUNDS_RADIUS = 500;

export const NODE_ENV = env('DEV') ? 'development' : 'production';

export const GOOGLE_MAPS_KEY = env('REACT_APP_GOOGLE_MAPS_KEY');
export const XGAC_WS_API_URL = env('REACT_APP_XGAC_WS_API_URL');
export const XGAC_API_BASE_URL = env('REACT_APP_XGAC_API_BASE_URL');
export const XGAC_AUTH_API_BASE_URL = env('REACT_APP_XGAC_AUTH_API_BASE_URL');
export const XGAC_FUSION_AUTH_CLIENT_ID = env('REACT_APP_XGAC_FUSION_AUTH_CLIENT_ID');
export const APP_VERSION = __APP_VERSION__;
export const TERMS_AND_CONDITIONS_HREF = env('REACT_APP_TERMS_AND_CONDITIONS_HREF');
export const DISCLAIMER_HREF = env('REACT_APP_DISCLAIMER_HREF');
export const PRIVACY_HREF = env('REACT_APP_PRIVACY_HREF');
export const STATUS_HREF = env('REACT_APP_STATUS_HREF');
export const DCC_PHONE_NUMBER_FORMATTED = env('REACT_APP_DCC_PHONE_NUMBER_FORMATTED');
export const DCC_PHONE_NUMBER_RAW = env('REACT_APP_DCC_PHONE_NUMBER_RAW');
export const USERSNAP_GLOBAL_API_KEY = env('REACT_APP_USERSNAP_GLOBAL_API_KEY');
export const HELPDESK_HREF = env('REACT_APP_HELPDESK_HREF');
export const DEVICE_REGISTRATION_API_BASE_URL = env('REACT_APP_DEVICE_REGISTRATION_API_BASE_URL');

export const ENABLE_REDUX_DEV_TOOLS = env<boolean>('REACT_APP_ENABLE_REDUX_DEV_TOOLS', false);
export const WEBSOCKET_SWAP_INTERVAL = 1000 * 60 * 50;
