import React, { useContext, useMemo } from 'react';

import { Icon } from '@mdi/react';

import { Text, Chip } from 'components/atoms';
import { mdiBellRing } from '@mdi/js';
import { useLocale } from 'lib';
import { alarmCenterSelector } from 'features/details';
import { useSelector } from 'react-redux';
import { connectedCustomerSelector } from 'features/identity';
import { alarmContext } from '../alarmContext';
import styles from './style.module.scss';
import { OverlayContext } from '../../../../molecules/overlay/overlayContext';

export * from './survey';

export const Acknowledge = () => {

  const { t } = useLocale();
  const { onRequestAckForm } = useContext(alarmContext);
  const { overlayCount } = useContext(OverlayContext);

  const currentCustomer = useSelector(connectedCustomerSelector);
  const alarmCenter = useSelector(alarmCenterSelector);

  const blockedByAlarmCenter = useMemo(() => {

    return alarmCenter && alarmCenter._id !== currentCustomer._id;

  }, [currentCustomer, alarmCenter]);

  const blockedByOverlay = overlayCount > 0;

  return (
    <div className={styles.acknowledge}>
      <Text variant={'boldSpan'} className={styles.title}>
        <Icon className={styles.icon} path={mdiBellRing}/>
        {t('Alarm_Detail_Acknowledge.Title')}
      </Text>
      {blockedByAlarmCenter ? (
        <>
          <hr/>
          <Text variant={'titleSpan'} className={styles.subtitle}>
            {t('Alarm_Detail_Acknowledge.Blocked', alarmCenter)}
          </Text>
        </>
      ) : (
        <>
          <Text variant={'titleSpan'} className={styles.subtitle}>
            {t('Alarm_Detail_Acknowledge.Subtitle')}
          </Text>
          <hr/>
          <Chip
            label={t('Alarm_Detail_Acknowledge.Button_Label')}
            size={'large'}
            disabled={blockedByOverlay}
            variant={'green400'}
            onClick={onRequestAckForm}
          />
        </>
      )}
    </div>
  );

};
