import React, {
  FC, memo, MutableRefObject, useRef,
} from 'react';

import cx from 'clsx';

import { Text } from 'components/atoms';
import { Overlay } from '../overlay';
import styles from './style.module.scss';

export type DropdownControlValueMeta = {
  key: number | string;
  label: string;
};

type DropdownItemProps = {
  isSelected: boolean;
  isCurrent: boolean;
  label: string;
  onSelect: () => void;
  theme: DropdownItemsTheme;
};

export const DropdownItem = memo<DropdownItemProps>(({
  isSelected,
  isCurrent,
  label,
  onSelect,
  theme,
}) => {

  const ref = useRef<HTMLDivElement>(null);

  // Scrolls the parent container so that the item is visible if it is selected
  if (ref.current !== null && isSelected) {

    ref.current.scrollIntoView({
      block: 'nearest',
      inline: 'start',
    });

  }

  return (
    <div
      ref={ref}
      className={cx(
        styles.dropdownItem,
        styles[theme],
        isSelected && styles.selected,
      )}
      onClick={onSelect}
    >
      {isCurrent && <div className={styles.isCurrent}/>}
      <Text
        className={cx(
          !isCurrent && styles.marginLeft,
        )}
        variant={'label'}
        theme={theme}
      >
        {label}
      </Text>
    </div>
  );

});

type DropdownItemsTheme = 'light' | 'dark';

type DropdownItemsProps<T> = {
  value: T;
  onSelectValue: (value: T) => void;
  values: T[];
  getMeta: (value: T) => DropdownControlValueMeta;
  selectedIndex: number;
  theme?: DropdownItemsTheme;
  noResults?: FC;

  // Props for the outside alerter hook. As we only want this hook to be active when the items are rendered
  parent: MutableRefObject<HTMLDivElement>;
  onDismiss: () => void;
};

export const DropdownItems = memo<DropdownItemsProps<unknown>>(({
  value,
  onSelectValue,
  values,
  getMeta,
  selectedIndex,
  theme = 'light',
  parent,
  onDismiss,
  noResults: NoResultsView = null,
}) => {

  const noItems = (values.length === 0 && NoResultsView !== null);
  const valueMeta = value !== null ? getMeta(value) : null;

  return (
    <Overlay
      className={cx(
        styles.itemWrapper,
        styles[theme],
        noItems && styles.noItems,
      )}
      onDismiss={onDismiss}
      customRef={parent}
    >
      <div className={styles.itemContainer}>
        {values.map((item, index) => {

          const meta = getMeta(item);
          return (
            <DropdownItem
              theme={theme}
              isSelected={index === selectedIndex}
              isCurrent={valueMeta?.key === meta.key ?? false}
              key={meta.key}
              label={meta.label}
              onSelect={() => onSelectValue(item)}
            />
          );

        })}
        {noItems && (
          <NoResultsView/>
        )}
      </div>
    </Overlay>
  );

});
