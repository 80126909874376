import React, {
  FC, useEffect, useState,
} from 'react';
import { webSocketContext } from 'lib';
import { accessTokenSelector } from 'features/identity/auth';
import { WEBSOCKET_SWAP_INTERVAL, XGAC_WS_API_URL } from 'config';
import { logger } from 'classes/logger';
import { useSelector } from 'react-redux';
import { WebSocketController, webSocketController } from '@x-guard/xgac-ts-ws-client';
import { useRefreshOnce } from '../../../lib/hooks/useRefreshOnce';
import { appLogger } from '../../../lib/logger';

export const ProvideWebSocket: FC = ({ children }) => {

  const accessToken = useSelector(accessTokenSelector);
  const [ws, setWs] = useState<WebSocketController | null>(null);
  const [retryIndex, setRetryIndex] = useState(0);
  const refresh = useRefreshOnce();

  useEffect(() => {

    const controller = new AbortController();

    const newWs = webSocketController({
      logger: appLogger,
      signal: controller.signal,
      url: XGAC_WS_API_URL,
      swapInterval: WEBSOCKET_SWAP_INTERVAL,
      handlers: {
        onRetryStart: (ev) => {

          logger.error('Websocket closed', { ev });

        },
        onRetrySuccess: () => {

          logger.info('Websocket reconnected');
          setRetryIndex((prev) => prev + 1);

        },
        onAccessTokenExpired: async () => {

          logger.info('Access token expired');

          // Types dont work here, but this is fine
          const result = await refresh();

          return result.accessToken;

        },
      },
    });

    newWs.setAccessToken(accessToken);
    newWs.waitForConnection().then(() => setWs(newWs));

    return () => controller.abort();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    ws?.setAccessToken(accessToken);

  }, [accessToken, ws]);

  return (
    <webSocketContext.Provider value={{ ws, retryIndex }}>
      {children}
    </webSocketContext.Provider>
  );

};
