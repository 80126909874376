import { XGAC_FUSION_AUTH_CLIENT_ID } from 'config';
import { JWTAuthPair } from 'types';
import { authApi, createThunk } from 'lib';
import { Store } from '@reduxjs/toolkit';
import { refreshStateSelector, refreshTokenSelector } from './exports';

type RefreshAuthArgs = {
  refreshToken?: string;
  controller: AbortController;
};

type RefreshResponse = JWTAuthPair & { userId: string };

export const refresh = createThunk<JWTAuthPair, RefreshAuthArgs>({
  name: 'identity/auth/refresh',
  handler: async ({ refreshToken, controller }, { getState }) => {

    refreshToken ??= refreshTokenSelector(getState());

    const { data } = await authApi.post<RefreshResponse>('/auth/refresh', {
      refreshToken,
      clientId: XGAC_FUSION_AUTH_CLIENT_ID,
    }, {
      signal: controller.signal,
    });

    return {
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
    };

  },
});

export const refreshOnce = (store: Store): Promise<JWTAuthPair> => {

  const initialState = refreshStateSelector(store.getState());

  if (!initialState.realStatus.pending) {

    return store.dispatch(refresh({ controller: new AbortController() }) as any).unwrap();

  }

  return new Promise((resolve, reject) => {

    const unsubscribe = store.subscribe(() => {

      const state = refreshStateSelector(store.getState());

      if (state.realStatus.pending || state.realStatus.idle) return;

      if (state.realStatus.fulfilled) resolve(state.value);

      if (state.realStatus.rejected) reject(state.error);

      unsubscribe();

    });

  });

};
