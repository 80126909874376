import moment from 'moment';
import { createAction, createSelector } from '@reduxjs/toolkit';
import {
  AnyMessage,
  ChatState,
  FetchMessagesArgs,
  FetchMessagesResult,
  DispatchLogOperatorAction,
  LogOperatorActionArgs, RS, LogableActions,
} from 'types';
import {
  computePaginationMeta,
  createSlice,
  paginationDefaults,
  withPagination, withPollableRetrying,
  createThunk, defaultRetryConfig, advancedQs, api,
} from 'lib';
import { filter, pick } from 'lodash';
import { v4 } from 'uuid';
import { alarmDetailsSelector } from 'features/details/exports';
import { userContextSelector } from 'features/identity/auth';
import { AlarmEventType } from '@x-guard/xgac-types/xgac';
import { ackCommentSelector } from '../ack/exports';

const name = 'details/chat';

export const appendMessage = createAction<AnyMessage>('details/chat/appendMessage');

// eslint-disable-next-line
export const logOperatorAction: DispatchLogOperatorAction = createThunk<void, LogOperatorActionArgs<any>>({
  name: `${name}/logOperatorAction`,
  handler: async (args, { dispatch, getState }) => {

    const userContext = userContextSelector(getState());
    const currentAlarm = alarmDetailsSelector(getState());

    const message: LogableActions = {
      ...args,
      _id: v4(),
      customer: currentAlarm.customer,
      share: currentAlarm.share,
      alarm: {
        _id: currentAlarm._id,
        _ref: 'Alarm',
      },
      type: AlarmEventType.Action,
      createdAt: moment.utc().toISOString(),
      updatedAt: moment.utc().toISOString(),
      user: userContext.user,
    };

    dispatch(appendMessage(message));

    const postDto = pick(message, ['type', 'text', 'action', 'meta']);

    await api.post(`/alarms/${currentAlarm._id}/alarm-events`, postDto);

  },
});

export const fetchAlarmEvents = createThunk<FetchMessagesResult, FetchMessagesArgs>({
  name,
  handler: async ({
    alarmId, controller: { signal }, paginate,
  }, { getState }) => {

    const { data } = await api.get(`/alarms/${alarmId}/alarm-events?${advancedQs({
      $sort: '-createdAt',
      ...(paginate ? {
        $offset: getState().details.chat.value.length,
      } : {}),
    })}`, { signal });

    return data;

  },
  retryConfig: defaultRetryConfig,
});

export const chat = createSlice<ChatState, typeof name>({
  name,
  initialState: paginationDefaults,
  extraReducers: (builder) => withPagination(builder, fetchAlarmEvents)
    .addCase(fetchAlarmEvents.retrying, withPollableRetrying)
    .addCase(appendMessage, (state, action) => {

      state.total += 1;
      state.value = [action.payload, ...state.value];

    }),
});

export const stateSelector = (state: RS) => state.details.chat;

// Select the count of messages that are actually displayed
export const countSelector = createSelector(
  stateSelector,
  ackCommentSelector,
  (state, comment): number => {

    if (state.value === null) {

      return null;

    }

    let count = state.total - filter(state.value, (message) => message.type === 'hidden'
      || (message.type === 'action' && message.action === 'ACTION_ALARM_ACKNOWLEDGE')).length;

    if (comment) {

      count += 1;

    }

    return count;

  },
);

export const metaSelector = createSelector(stateSelector, computePaginationMeta);
export const isRetryingSelector = createSelector(stateSelector, (state) => state.isRetrying);
