import React, { useMemo } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { SplitChipButtonProps, Text } from 'components/atoms';
import { useLocale } from 'lib';
import { mdiVolumeHigh, mdiVolumeMute } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { mutedSelector, setMute } from 'features/identity/preferences';
import styles from './style.module.scss';

export const useMuteButton = (): SplitChipButtonProps => {

  const { t } = useLocale();
  const dispatch = useDispatch();
  const muted = useSelector(mutedSelector);

  return useMemo(() => {

    return {
      className: styles.muteButton,
      left: {
        className: cx(styles.left, !muted && styles.active),
        variant: !muted ? 'tertiary' : 'light100',
        label: (
          <>
            <Text variant={'label'} theme={muted ? 'light' : 'dark'}>
              {t('TopBar_Sound_Muted')}
            </Text>
            <Icon path={mdiVolumeMute} />
          </>
        ),
        onClick: !muted ? () => dispatch(setMute(true)) : null,
      },
      right: {
        variant: muted ? 'tertiary' : 'light100',
        label: (
          <Icon path={mdiVolumeHigh} />
        ),
        onClick: muted ? () => dispatch(setMute(false)) : null,
      },
    };

  }, [dispatch, muted, t]);

};
