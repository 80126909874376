import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { Box } from 'react-html-email';
import { Text } from './text';

export const Footer: FC = () => {

  return (
    <Box style={{
      width: '100%',
      backgroundColor: colors.backgroundCultured,
    }}>
      <Text padding={'8px'} textAlign={'center'} fontSize={'10px'}>
        Deze e-mail kan niet worden beantwoord.<br/>
        x-guard.nl / +31 88 126 12 12 / info@x-guard.nl
      </Text>
    </Box>
  );

};
