import React, { FC, useMemo } from 'react';
import { completeMergeHistorySelector } from 'features/details';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocale, useToggle } from 'lib';
import { Chip } from 'components/atoms';
import lodash from 'lodash';
import styles from './style.module.scss';

const truncateAfter = 2;

export const MergeHistory: FC = () => {

  const [showAllAlarms, toggleShowAllAlarms] = useToggle(false);
  const { t } = useLocale();

  const mergedAlarms = useSelector(completeMergeHistorySelector);
  const visibleAlarms = useMemo(() => {

    const source = lodash.reverse([...mergedAlarms]);

    return showAllAlarms || source.length === truncateAfter + 1
      ? source
      : source.slice(0, truncateAfter);

  }, [showAllAlarms, mergedAlarms]);

  const truncatedCount = mergedAlarms.length - truncateAfter;

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.counter}>
          {mergedAlarms.length}x
        </div>
        <span>{t('Alarm_Detail_Merge_History.counter.subText')}</span>
      </div>
      <div className={styles.right}>
        <span className={styles.title}>
          {t('Alarm_Detail_Merge_History.title', { count: mergedAlarms.length })}
        </span>
        {visibleAlarms.map((alarm) => (
          <div key={alarm._id} className={styles.alarm}>
            <div className={styles.createdAt}>
              {moment(alarm.createdAt).format('HH:mm:ss')}
            </div>
            <span className={styles.name}>
              {alarm.name}
            </span>
          </div>
        ))}
        {truncatedCount > 1 && (
          <Chip
            className={styles.showAllAlarms}
            size={'medium'}
            variant={'warning200'}
            onClick={() => toggleShowAllAlarms()}
            label={t(`Alarm_Detail_Merge_History.showAllAlarms.${showAllAlarms ? 'hide' : 'show'}`, {
              count: truncatedCount,
            })}
          />
        )}
      </div>
    </div>
  );

};
