import React, { FC, ReactNode } from 'react';
import { Chip, Text } from 'components';
import styles from './style.module.scss';

type LabelProps = {
  text: ReactNode;
};

export const Label: FC<LabelProps> = ({ text }) => {

  return (
    <Chip
      variant={'dark'}
      flex={true}
      size={'small'}
      className={styles.labelChip}
      onClick={(e) => e.stopPropagation()}
      label={(
        <Text variant={'contentSpan'} theme={'dark'} className={styles.labelText}>
          {text}
        </Text>
      )}
    />
  );

};
