/**
 * Wraps a given number between 0 and max
 *
 * @example
 * 7 === wrap(-3, 10) // true
 * @param curr
 * @param max
 */
export const wrap = (curr: number, max: number): number => {

  return ((curr % max) + max) % max;

};

// number.toFixed() without trailing 0s
export const toFixed = (value: number, decimals = 2): string => {

  return (+value).toFixed(decimals).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');

};
