import React, { FC } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { mdiPlus } from '@mdi/js';
import { useLocale } from 'lib';
import { AsyncStatus } from 'types';
import { Loader } from 'components/atoms';
import styles from './style.module.scss';

type LoadMoreProps = {
  onClick: () => void;
  status: AsyncStatus;
  size: 'large' | 'small';
};

export const LoadMore: FC<LoadMoreProps> = ({
  onClick,
  size,
  status,
}) => {

  const { t } = useLocale();

  return (
    <div
      className={cx(
        styles.more,
        styles[size],
        status.pending ? styles.disabled : styles.clickable,
      )}
      onClick={() => !status.pending && onClick()}
    >
      <div className={styles.sub1}>
        <span>{t('Main_ShowMore')}</span>
      </div>
      <div className={styles.divider} />
      <div className={styles.sub2}>
        {status.pending ? (
          <Loader variant={'small'}/>
        ) : (
          <Icon path={mdiPlus}/>
        )}
      </div>
    </div>
  );

};
