import React, { FC } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type TailAlignment = 'bottom' | 'top';

type TailProps = {
  alignment: TailAlignment;
};

export const Tail: FC<TailProps> = ({ alignment }) => {

  return (
    <div className={cx(styles.tailContainer, styles[alignment])}>
      <div className={styles.tail}/>
    </div>
  );

};
