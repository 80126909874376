import React, { FC } from 'react';

import { Text } from 'components/atoms';
import { useLocale } from 'lib';
import styles from './style.module.scss';

export const NoCustomersFound: FC = () => {

  const { t } = useLocale();

  return (
    <div className={styles.noCustomers}>
      <Text variant={'h6'} textAlign={'center'}>
        {t('Menu_Linked_No_Customers_Found')}
      </Text>
    </div>
  );

};
