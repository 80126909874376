import React, { FC, ReactNode } from 'react';

import { Chip, ChipVariant, Text } from 'components';

type TitleProps = {
  variant: ChipVariant;
  text: ReactNode;
};

export const Title: FC<TitleProps> = ({ text, variant }) => {

  return (
    <Chip
      variant={variant}
      flex={true}
      size={'medium'}
      label={(
        <Text variant={'span'} theme={'dark'}>
          {text}
        </Text>
      )}
    />
  );

};
