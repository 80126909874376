import React, { FC, useMemo } from 'react';

import { AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { useLocale } from 'lib';
import { SplitChipButton, Text } from 'components/atoms';
import { TranslationKey } from 'config/i18n';
import { useSelector } from 'react-redux';
import { alarmDetailsSelector } from 'features/details';
import { BetterMarkdown } from 'components/molecules';
import { useProcedurePriority } from './useProcedurePriority';
import styles from './style.module.scss';

const alarmSeverityToDefaultTranslation: Record<AlarmSeverity, TranslationKey> = {
  [AlarmSeverity.Red]: 'Alarm_Detail_Response_Protocol_Panic',
  [AlarmSeverity.Orange]: 'Alarm_Detail_Response_Protocol_Assistance',
  [AlarmSeverity.Green]: 'Alarm_Detail_Response_Protocol_Test',
};

export const Procedure: FC = () => {

  const { procedure, severity } = useSelector(alarmDetailsSelector);
  const { t } = useLocale();

  const buttonProps = useProcedurePriority();

  const steps = useMemo<string>(() => {

    if (procedure) return procedure;

    return t(alarmSeverityToDefaultTranslation[severity] ?? 'Alarm_Detail_Response_Protocol_Test');

  }, [procedure, severity, t]);

  return (
    <div className={styles.procedure}>
      <div className={styles.container}>
        <Text variant={'h6'} className={styles.title}>
          {t('Protocol_Response')}
        </Text>
        <div className={styles.rule}/>
        <div className={styles.steps}>
          <BetterMarkdown>
            {steps}
          </BetterMarkdown>
        </div>
        <div className={styles.rule}/>
        <SplitChipButton {...buttonProps}/>
      </div>
    </div>
  );

};
