import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'components/molecules';
import styles from './style.module.scss';

import { AlarmFiltersForm } from './alarmFilters';
import { AlarmLists } from './alarmLists';
import { useRedirectToAlarmOfAsset } from './useRedirectToAlarmOfAsset';

export const AlarmOverview = memo(() => {

  const alarmId = useRedirectToAlarmOfAsset();

  if (alarmId) {

    return (
      <Navigate to={`alarms/${alarmId}`} />
    );

  }

  return (
    <>
      <div className={styles.header}/>
      <div className={styles.content}>
        <div className={styles.left}>
          <ErrorBoundary>
            <AlarmFiltersForm/>
          </ErrorBoundary>
        </div>
        <div className={styles.right}>
          <ErrorBoundary>
            <AlarmLists/>
          </ErrorBoundary>
        </div>
      </div>
    </>
  );

});
