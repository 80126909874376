import React, { FC } from 'react';

import moment, { Moment } from 'moment';
import { Icon } from '@mdi/react';
import cx from 'clsx';

import { AnyDisplayableMessageProps, MessageImageMode } from 'types';
import { BetterMarkdown, BetterMoment, Image } from 'components';
import { FormatCalendar, useLocale } from 'lib';
import { PresentationValues } from './usePresentationValues';
import styles from './style.module.scss';

interface TimeProps {
  calendar: FormatCalendar;
  dateColor: string;
  localTime: Moment;
}

const Time: FC<TimeProps> = ({ calendar, dateColor, localTime }) => {

  return (
    <div className={styles.time} style={{ color: dateColor }}>
      <BetterMoment calendar={calendar}>
        {localTime}
      </BetterMoment>
    </div>
  );

};

type MessageProps = AnyDisplayableMessageProps & {
  presentationValues: PresentationValues;
};

/**
 * Display a single message
 */
export const Message: FC<MessageProps> = ({
  content,
  isFirstInDay,
  joinWithTop,
  joinWithBottom,
  imageMode,
  bottomMargin,
  presentationValues: {
    side,
    isAlarmCreator,
    messageText,
    messageTitle,
    messageHeader,
    messageIcon,
    backgroundColor,
    textColor,
    dateColor,
    smallCalendar,
    largeCalendar,
    displayTime,
  },
}) => {

  // Get data and options from props
  const { t } = useLocale();

  const localTime = moment.utc(content.createdAt).local();
  const inlineTime = content.type === 'user' && content.action === 'ACTION_CHAT' && displayTime;
  const acknowledgement = content.type === 'action' && content.action === 'ACTION_ALARM_ACKNOWLEDGE';

  return (
    <li
      id={content._id.toString()}
      className={cx(
        styles.container,
        styles[side],
        styles[content.type],
        styles[imageMode],
        joinWithTop && styles.joinWithTop,
        joinWithBottom && styles.joinWithBottom,
        inlineTime && styles.inlineTime,
        acknowledgement && styles.acknowledgement,
        bottomMargin && styles.bottomMargin,
      )}
    >
      {isFirstInDay && (
        <div className={styles.timeSpacer}>
          <BetterMoment calendar={largeCalendar}>{localTime}</BetterMoment>
        </div>
      )}
      <div className={styles.mainLayout}>
        <div className={styles.imageColumn}>
          {(imageMode === MessageImageMode.Display) && (
            <Image src={content.user.properties?.image} alt={'user'} />
          )}
        </div>
        <div className={styles.mainColumn}>
          {(joinWithTop === false) && (
            <div className={styles.titleRow}>
              <span className={styles.title}>{messageTitle}</span>
              {isAlarmCreator && (
                <span className={cx(styles.title, styles.alarmCreator)}>{t('Alarm_Detail_Chat.AlarmCreator')}</span>
              )}
            </div>
          )}
          <div className={styles.bubble} style={{ backgroundColor }}>
            {messageHeader && (
              <span className={styles.messageHeader}>{messageHeader}</span>
            )}
            {(messageHeader && messageText) && (
              <hr/>
            )}
            <div className={styles.content} style={{ color: textColor }}>
              <div className={styles.contentTextWrapper}>
                <span className={styles.contentText}>{acknowledgement
                  ? <BetterMarkdown>{messageText}</BetterMarkdown>
                  : messageText
                }</span>
                {inlineTime && (
                  // Empty div that makes sure text wraps if it encroaches on the time
                  <div className={styles.contentWrap}/>
                )}
              </div>
              {content.type !== 'user' && messageIcon !== null && (
                <Icon path={messageIcon}/>
              )}
            </div>
            {displayTime && (
              <Time calendar={smallCalendar} dateColor={dateColor} localTime={localTime} />
            )}
            {acknowledgement && (
              <div className={styles.acknowledgementTail}/>
            )}
          </div>
        </div>
      </div>
    </li>
  );

};
