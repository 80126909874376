import { OverviewFilters, RS } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';

/**
 * Empty alarm filters
 */
export const defaultFilters: OverviewFilters = {
  externalId: [],
  name: [],
  severity: [
    AlarmSeverity.Red,
    AlarmSeverity.Orange,
    AlarmSeverity.Green,
  ].sort(),
  startDate: null,
  endDate: null,
};

export const getIsDefault = (filters: OverviewFilters) => {

  // For equality checking purposes, clone the filter and sort arrays.
  const values = cloneDeep(filters);

  return isEqual(defaultFilters, {
    ...values,
    externalId: values.externalId.sort(),
    name: values.name.sort(),
    severity: values.severity.sort(),
  } as OverviewFilters);

};

export const currentFiltersSelector = (state: RS) => state.overview.alarmFilters.current;
export const filtersSourceSelector = (state: RS) => state.overview.alarmFilters.source;
export const isDefaultFiltersSelector = createSelector(currentFiltersSelector, getIsDefault);
export const effectiveFiltersSelector = createSelector(
  currentFiltersSelector,
  (filters) => {

    return getIsDefault(filters) ? null : filters;

  },
);
