import React, { FC } from 'react';

import { SplitChipButton, ChipVariant, SplitChipButtonProps } from 'components/atoms';

type ChipVariantInput = ChipVariant | [ChipVariant, ChipVariant];

type ToggleButtonControlProps = SplitChipButtonProps & {
  input: {
    toggled: boolean;
    onChange: (value: boolean) => void;
  };
  variants?: {
    active: ChipVariantInput;
    inactive: ChipVariantInput;
  };
};

export const ToggleButtonControl: FC<ToggleButtonControlProps> = ({
  input: {
    toggled,
    onChange,
  },
  left,
  right,
  variants,
  ...rest
}) => {

  const produceVariant = (active: boolean, index: number): ChipVariant => {

    if (!variants) {

      return null;

    }

    const input = active ? variants.active : variants.inactive;

    return Array.isArray(input) ? input[index] as ChipVariant : input;

  };

  return (
    <SplitChipButton
      left={{
        onClick: !toggled ? () => onChange(true) : null,
        variant: produceVariant(toggled, 0),
        ...left,
      }}
      right={{
        onClick: toggled ? () => onChange(false) : null,
        variant: produceVariant(!toggled, 1),
        ...right,
      }}
      {...rest}
    />
  );

};
