import React, {
  FC, KeyboardEvent, useCallback, useEffect, useRef, useState,
} from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { Text } from 'components/atoms';
import { wrap } from 'lib';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { DropdownItems, DropdownControlValueMeta } from '../dropdownItems';
import styles from './style.module.scss';

type DropdownControlProps<T> = {
  values: T[];
  value: T;
  onSelectValue: (value: T) => void;
  getMeta: (value: T) => DropdownControlValueMeta;
  label: string;
};

export const DropdownControl: FC<DropdownControlProps<unknown>> = ({
  values,
  value,
  onSelectValue,
  getMeta,
  label,
}) => {

  // Whether or not the field values are visible
  const [open, setOpen] = useState(false);

  // Tracks the index of the selected item
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {

    setSelectedIndex(0);

  }, [values]);

  const ref = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {

    setOpen(false);

  }, [setOpen]);

  const toggle = () => setOpen(!open);

  // Moves the selected index by either 1 or -1. Allows for index wrapping
  const moveSelectedIndexBy = (diff: 1 | -1) => {

    const newIndex = wrap(selectedIndex + diff, values.length);
    setSelectedIndex(newIndex);

  };

  const onControlKeyDown = (e: KeyboardEvent): void => {

    // If the dropdown is closed, open it
    if (open === false) {

      setOpen(true);
      setSelectedIndex(values.indexOf(value));
      return;

    }

    switch (e.key) {

    // Move selection index up
    case 'ArrowUp':
      moveSelectedIndexBy(-1);
      e.preventDefault();
      break;
      // Move selection index down
    case 'ArrowDown':
      moveSelectedIndexBy(1);
      e.preventDefault();
      break;
    default: break;

    }

  };

  const onSelectItem = useCallback((item: unknown) => {

    if (value === null) {

      setOpen(false);
      onSelectValue(item);
      return;

    }

    const valueMeta = getMeta(value);
    const itemMeta = getMeta(item);

    setOpen(false);

    if (itemMeta.key !== valueMeta.key) {

      onSelectValue(item);

    }

  }, [value, setOpen, onSelectValue, getMeta]);

  const icon = open ? mdiChevronUp : mdiChevronDown;

  return (
    <div
      ref={ref}
      className={cx(
        styles.dropdown,
        open && styles.open,
      )}
      onKeyDown={onControlKeyDown}
      tabIndex={-1}
    >
      <div className={styles.control} onClick={toggle}>
        <Text variant={'label'} theme={'dark'}>
          {label}
        </Text>
        <div className={styles.iconContainer}>
          <Icon path={icon}/>
        </div>
      </div>
      {open && (
        <DropdownItems
          values={values}
          value={value}
          onSelectValue={onSelectItem}
          getMeta={getMeta}
          parent={ref}
          selectedIndex={selectedIndex}
          onDismiss={onClickOutside}
          theme={'dark'}
        />
      )}
    </div>
  );

};
