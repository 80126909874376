import { useDispatch, useSelector } from 'react-redux';
import {
  ackSelector,
  alarmIdSelector, updateAlarm, updateAlarmAsset,
} from 'features/details';
import { useWebSocketContext } from 'lib';
import { useEffect } from 'react';
import { appendMessage } from 'features/details/chat';
import { AnyMessage, PopulatedAlarm } from 'types';
import { userContextSelector } from 'features/identity/auth';
import { addHelper, updateHelper } from 'features/details/helpers';

export const useAlarmDetailsSubscription = () => {

  const alarmId = useSelector(alarmIdSelector);
  const acked = useSelector(ackSelector);
  const { ws, retryIndex } = useWebSocketContext();
  const dispatch = useDispatch();
  const userCtx = useSelector(userContextSelector);

  useEffect(() => {

    if (!alarmId || !ws || acked) return undefined;

    return ws.subscribe('Alarm', {
      events: ['create', 'update'],
      id: [alarmId],
      handler: {
        Asset: {
          update: ({ document }) => dispatch(updateAlarmAsset(document)),
        },
        AlarmHelper: {
          update: ({ document }) => dispatch(updateHelper(document)),
          create: ({ document }) => dispatch(addHelper(document)),
        },
        AlarmEvent: {
          create: ({ document }) => {

            if (document.user._id === userCtx.user._id) return;

            dispatch(appendMessage(document as AnyMessage));

          },
        },
        Alarm: {
          update: ({ document }) => dispatch(updateAlarm(document as PopulatedAlarm)),
        },
      },
    });

  }, [alarmId, ws, dispatch, userCtx, acked, retryIndex]);

};
