import { DependencyList, useCallback, useState } from 'react';

import { AsyncState, AsyncStatus } from 'types';

export type PromiseValue<T> = T extends PromiseLike<infer V> ? V : T;

// Type safety at this point is guaranteed, and no other type works in this case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseAsyncReturn<TFunc extends (...args: any[]) => Promise<any>> = {
  execute: ((...args: Parameters<TFunc>) => void);
} & AsyncState<ReturnType<TFunc>>;

export const useAsync = <
  // Type safety at this point is guaranteed, and no other type works in this case
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunc extends (...args: any[]) => Promise<any>,
  TError extends Error = Error,
>(asyncFunction: TFunc, deps: DependencyList): UseAsyncReturn<TFunc> => {

  const [status, setStatus] = useState<AsyncStatus>(AsyncStatus.Idle);
  const [value, setValue] = useState<PromiseValue<ReturnType<TFunc>> | null>(null);
  const [error, setError] = useState<TError | null>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  const execute = useCallback<((...args: Parameters<TFunc>) => void)>((...args) => {

    setStatus(AsyncStatus.Pending);
    setValue(null);
    setError(null);

    asyncFunction(...args)
      .then((response) => {

        setValue(response);
        setStatus(AsyncStatus.Fulfilled);

      })
      .catch((e) => {

        setError(e);
        setStatus(AsyncStatus.Rejected);

      });

    // Static analysis is not supported here, this is fine

  }, [asyncFunction, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    execute,
    status,
    value,
    error,
  } as unknown as UseAsyncReturn<TFunc>;

};
