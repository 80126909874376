import { bcpLangSelector, isoLangSelector } from 'features/identity/preferences/exports';
import { useSelector } from 'react-redux';
import { t, TranslationFunc } from 'config/i18n';
import { BCPLang, ISOLang } from 'types';

const cache = { } as Record<BCPLang, TranslationFunc>;

/**
 * Get a translation function for every locale
 * The locale itself is not forwarded to the implementation, the only purpose of this is to get function with a
 * different reference for every locale, so it works nicely with dependency arrays in react hooks.
 */
const getTranslateFunction = (locale: BCPLang): TranslationFunc => {

  if (typeof cache[locale] === 'undefined') {

    cache[locale] = (searchString, options) => t(searchString, options);

  }

  return cache[locale];

};

type UseLocaleReturn = {
  bcp: BCPLang;
  iso: ISOLang;
  t: TranslationFunc; // Translation function with different referential equality for every locale
};

/**
 * Hook used to rerender consumers when to global locale changes
 */
export const useLocale = (): UseLocaleReturn => {

  const bcpLang = useSelector(bcpLangSelector);
  const isoLang = useSelector(isoLangSelector);

  return {
    bcp: bcpLang,
    iso: isoLang,
    t: getTranslateFunction(bcpLang),
  };

};
