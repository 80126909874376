import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { bcpLangSelector, setSelectedLocale } from 'features/identity/preferences';
import { BCPLang, supportedBCP47Languages } from 'types';
import { clone } from 'lodash';
import { ChipArrayControl, GetChipProps } from '../chipArrayControl';
import { useCountryAssets } from './useCountryAssets';
import styles from './style.module.scss';

/**
 * Array of chip fields that allow the user to switch languages
 */
export const LanguageArray = memo(() => {

  const dispatch = useDispatch();
  const localeCode = useSelector(bcpLangSelector);
  const localeMap = useCountryAssets();

  const getChipProps: GetChipProps<BCPLang> = useCallback((value) => {

    const isSelected = localeCode === value;

    return {
      size: 'small',
      label: () => {

        const [iconSrc, label] = localeMap[value];

        return (
          <>
            <img src={iconSrc} alt={'Country Logo'} />
            <span>{label}</span>
          </>
        );

      },
      className: styles.chip,
      variant: isSelected ? 'dark' : 'light300',
      onClick: () => {

        if (!isSelected) {

          dispatch(setSelectedLocale(value));

        }

      },
    };

  }, [localeMap, localeCode, dispatch]);

  return (
    <ChipArrayControl
      values={clone(supportedBCP47Languages) as unknown as BCPLang[]}
      getChipProps={getChipProps}
    />
  );

});
