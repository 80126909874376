import { createAction } from '@reduxjs/toolkit';
import { IncomingEntityMessage, WebSocketController } from '@x-guard/xgac-ts-ws-client';
import { AlarmProfessionalResponder, StaticResponder } from '@x-guard/xgac-types/xgac';
import {
  api,
  asyncPollableDefaults,
  createSlice,
  createThunk, withPollableFulfilled,
  withPollablePending, withPollableRejected, withPollableRetrying,
} from 'lib';
import { maxBy, uniqBy } from 'lodash';
import {
  AsyncStatus, Page, PopulatedAlarmProfessionalResponder, ProfessionalResponseState, RS,
} from 'types';

const name = 'details/response/professional';

export type StartProfessionalResponseArgs = {
  controller: AbortController;
  alarmId: string;
};

export type CancelProfessionalResponseArgs = {
  controller: AbortController;
  alarmId: string;
  aprId: string;
};

export type FetchProfessionalResponseArgs = {
  ws: WebSocketController;
  controller: AbortController;
  alarmId: string;
  customerId: string;
};

const createResponder = createAction<PopulatedAlarmProfessionalResponder>('details/response/professional/create');
const updateResponder = createAction<AlarmProfessionalResponder>('details/response/professional/update');

const populate = async (responder: AlarmProfessionalResponder): Promise<PopulatedAlarmProfessionalResponder> => {

  const { data } = await api.get<StaticResponder>(`static-responders/${responder.staticResponder._id}`);

  return {
    ...responder,
    staticResponder: {
      ...responder.staticResponder,
      ...data,
    },
  };

};

export const startProfessionalResponse = createThunk<unknown, StartProfessionalResponseArgs>({
  name: `${name}/start`,
  handler: async ({ alarmId }) => {

    const { data } = await api.post(`/alarms/${alarmId}/professional-responder`, {
      type: 'alarmProfessionalResponder',
      data: {},
    });

    return data;

  },
});

export const cancelProfessionalResponse = createThunk<unknown, CancelProfessionalResponseArgs>({
  name: `${name}/cancel`,
  handler: async ({ alarmId, aprId }) => {

    const { data } = await api.patch(`/alarms/${alarmId}/professional-responder/${aprId}`, {
      status: 'canceled',
    });

    return data;

  },
});

export const fetchProfessionalResponse = createThunk<PopulatedAlarmProfessionalResponder[], FetchProfessionalResponseArgs>({
  name: `${name}/fetch`,
  handler: async ({
    ws,
    controller,
    alarmId,
    customerId,
  }, { dispatch }) => {

    const onUpdate = (event: IncomingEntityMessage<'AlarmProfessionalResponder', 'update'>) => {

      dispatch(updateResponder(event.document));

    };

    const onCreate = async (event: IncomingEntityMessage<'AlarmProfessionalResponder', 'create'>) => {

      ws.subscribe('AlarmProfessionalResponder', {
        signal: controller.signal,
        id: [event.document._id],
        events: ['update'],
        handler: onUpdate,
      });

      const { data } = await api.get<AlarmProfessionalResponder>(`/alarm-professional-responders/${event.document._id}`);

      const populated = await populate(data);

      dispatch(createResponder(populated));

    };

    ws.subscribe('AlarmProfessionalResponder', {
      signal: controller.signal,
      customers: [customerId],
      events: ['create', 'update'],
      handler: {
        create: onCreate,
        update: onUpdate,
      },
    });

    const { data } = await api.get<Page<AlarmProfessionalResponder>>('/alarm-professional-responders', {
      params: {
        'alarm._id': alarmId,
      },
    });

    return Promise.all(data.result.map(populate));

  },
});

export const professionalResponse = createSlice<ProfessionalResponseState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    startStatus: AsyncStatus.Idle,
    cancelStatus: AsyncStatus.Idle,
    value: [],
  },
  extraReducers: (builder) => builder
    .addCase(createResponder, (state, { payload }) => {

      state.value = uniqBy([payload, ...state.value], '_id');

    })
    .addCase(updateResponder, (state, { payload }) => {

      const item = state.value.find(({ _id }) => _id === payload._id);

      if (!item) return;

      const newItem: PopulatedAlarmProfessionalResponder = {
        ...payload,
        staticResponder: item.staticResponder,
      };

      state.value = uniqBy([newItem, ...state.value], '_id');

    })
    .addCase(startProfessionalResponse.pending, (state) => {

      state.startStatus = AsyncStatus.Pending;

    })
    .addCase(startProfessionalResponse.fulfilled, (state) => {

      state.startStatus = AsyncStatus.Fulfilled;

    })
    .addCase(startProfessionalResponse.rejected, (state) => {

      state.startStatus = AsyncStatus.Rejected;

    })
    .addCase(fetchProfessionalResponse.pending, withPollablePending)
    .addCase(fetchProfessionalResponse.fulfilled, withPollableFulfilled)
    .addCase(fetchProfessionalResponse.retrying, withPollableRetrying)
    .addCase(fetchProfessionalResponse.rejected, withPollableRejected),
});

export const professionalResponderSelector = (state: RS) => {

  return maxBy(
    state.details.response.professional.value,
    (responder) => new Date(responder.updatedAt),
  ) ?? null;

};

export const startResponseStatusSelector = (state: RS) => state.details.response.professional.startStatus;
export const cancelResponseStatusSelector = (state: RS) => state.details.response.professional.cancelStatus;

export const inProgressSelector = (state: RS) => {

  const responder = professionalResponderSelector(state);

  if (responder) return true;

  const status = startResponseStatusSelector(state);

  return status.fulfilled || status.pending;

};
