import { useStore } from 'react-redux';
import { refreshOnce } from '../../features/identity/auth/refresh';
import { JWTAuthPair } from '../../types';

export const useRefreshOnce = (): () => Promise<JWTAuthPair> => {

  const store = useStore();

  return () => refreshOnce(store);

};
