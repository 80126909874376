import React, { FC, useEffect } from 'react';

import { ChoiceGrid, Text } from 'components';
import {
  mdiEmoticonHappyOutline,
  mdiEmoticonSadOutline,
  mdiPhoneCheck,
  mdiPhoneMissed,
  mdiPhoneRemove,
} from '@mdi/js';
import { useLocale } from 'lib';
import { LogableActions } from 'types';
import styles from './style.module.scss';

type OmittedActionMessage = Extract<LogableActions, { action: 'ACTION_CALL' }>;

export type Answer = Exclude<LogableActions, OmittedActionMessage>['action'];

type ResultProps = {
  onAnswer: (answer: Answer) => void;
  setStep: (step: number) => void;
  setOnBack: (handler: () => void) => void;
  isAlarmCreator: boolean;
};

export const Result: FC<ResultProps> = ({
  isAlarmCreator,
  onAnswer,
  setStep,
  setOnBack,
}) => {

  const { t } = useLocale();

  useEffect(() => {

    setOnBack(() => setStep(0));

    return () => {

      setOnBack(null);

    };

  }, [setStep, setOnBack]);

  return (
    <>
      <ChoiceGrid
        title={t('Alarm_Detail_Contact.Phone_Call.Result.Title')}
        modalGridProps={{
          mode: 'contain',
        }}
        items={{
          ACTION_CALL_RESULT_RESPONDING: {
            iconPath: isAlarmCreator ? mdiEmoticonHappyOutline : mdiPhoneCheck,
            label: isAlarmCreator
              ? t('Alarm_Detail_Contact.Phone_Call.AlarmCreator.Result.Responding')
              : t('Alarm_Detail_Contact.Phone_Call.Result.Responding'),
          },
          ACTION_CALL_RESULT_NOT_REACHABLE: {
            iconPath: isAlarmCreator ? mdiEmoticonSadOutline : mdiPhoneRemove,
            label: isAlarmCreator
              ? t('Alarm_Detail_Contact.Phone_Call.AlarmCreator.Result.Not_Reachable')
              : t('Alarm_Detail_Contact.Phone_Call.Result.Not_Reachable'),
          },
          ACTION_CALL_RESULT_NO_RESPONSE: {
            iconPath: mdiPhoneMissed,
            label: isAlarmCreator
              ? t('Alarm_Detail_Contact.Phone_Call.AlarmCreator.Result.No_Response')
              : t('Alarm_Detail_Contact.Phone_Call.Result.No_Response'),
          },
        }}
        onAnswer={onAnswer}
      />
      <div className={styles.skip}>
        <Text
          textAlign={'center'}
          variant={'label'}
          className={styles.text}
          onClick={() => onAnswer('ACTION_CALL_RESULT_CANCELLED')}
        >
          {t('Alarm_Detail_Contact.Phone_Call.Result.Skipped')}
        </Text>
      </div>
    </>
  );

};
