import React, { FC, useMemo } from 'react';

import { renderEmail } from 'react-html-email';
import { PopulatedAlarm } from 'types';
import { useFormattedContactCode } from 'lib';
import { Responder } from '@x-guard/xgac-types/xgac';
import type { FormState } from '.';
import {
  Template, AlarmPosition, PersonalInformation, Message,
} from './emailBuilder';

type UseEmailBuilder = (formValues: FormState, alarm: PopulatedAlarm, contact: Responder) => {
  component: FC;
  getHtmlString: () => string;
};

/**
 * Hook that generates an alarm component and its accompanying email-friendly html string
 */
export const useEmailBuilder: UseEmailBuilder = (formValues, alarm, contact) => {

  const formattedContactCode = useFormattedContactCode(formValues.includeContactCode ? contact.info.contactCode : null);

  return useMemo(() => {

    const component = () => (
      <Template
        alarmId={alarm._id}
        contactCode={formattedContactCode}
      >
        {formValues.message && (
          <Message message={formValues.message}/>
        )}
        {formValues.personalInformation && (
          <PersonalInformation
            name={alarm.asset.name}
            image={alarm.asset.properties?.image || null}
          />
        )}
        {formValues.alarmPosition && (
          <AlarmPosition
            address={alarm.position.properties.address.formattedAddress}
            coordinates={alarm.position.coordinates}
          />
        )}
      </Template>
    );

    return {
      component,
      getHtmlString: () => renderEmail(component()),
    };

  }, [formValues, alarm, formattedContactCode]);

};
