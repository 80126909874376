import React from 'react';
import { useLocale } from 'lib';
import { Icon } from '@mdi/react';
import { mdiAlert } from '@mdi/js';
import styles from './style.module.scss';

export const AprReplacedByZoneNotice = () => {

  const { t } = useLocale();

  return (
    <div className={styles.notice}>
      <div className={styles.top}>
        <Icon path={mdiAlert}/>
        <span>{t('Alarm_Detail_Response.APR.Replaced_By_Zone.Title')}</span>
      </div>
      <div className={styles.bottom}>
        <span>{t('Alarm_Detail_Response.APR.Replaced_By_Zone.Sub')}</span>
      </div>
    </div>
  );

};
