import React, {
  FC, memo, useMemo,
} from 'react';

import { useLocale } from 'lib';
import { Responder, ResponseGroup, ResponseGroupType } from '@x-guard/xgac-types/xgac';
import { useSelector } from 'react-redux';
import { ContactableSubsection } from '../contactableSubsection';
import { Section } from '../section';
import { Label } from '../label';
import { Title } from '../title';
import { ProfessionalResponder } from '../professionalResponder';
import { staticResponseAprReplacedByZoneSelector } from '../../../../../../features/details/response/static';
import { AprReplacedByZoneNotice } from '../professionalResponder/replacedByZoneNotice';

type GroupTitleProps = {
  group: ResponseGroup;
};

type ResponseSectionGroupProps = {
  group: ResponseGroup;
  className: string;
};

const GroupTitle: FC<GroupTitleProps> = ({ group }) => {

  const { t } = useLocale();

  if (group.type === ResponseGroupType.Nearby) {

    return <>
      <Title text={t('Alarm_Detail_Response.Type.ACR_QR.Title')} variant={'green200'} />
      <Label text={t('Alarm_Detail_Response.Type.ACR_QR.Subtitle')}/>
    </>;

  }

  if (group.type === ResponseGroupType.AlarmProfessionalResponder) {

    return (
      <Title text={t('Alarm_Detail_Response.Type.ACR_APR.Title')} variant={'green300'}/>
    );

  }

  if (group.type as string === 'responsePartners') {

    return <>
      <Title text={t('Alarm_Detail_Response.Type.ACR_NQR.Title')} variant={'green400'}/>
      <Label text={t('Alarm_Detail_Response.Type.ACR_NQR.Subtitle')}/>
    </>;

  }

  if (group.type === ResponseGroupType.Zone) {

    return <>
      <Title text={t('Alarm_Detail_Response.Type.ACR_RA.Title')} variant={'green400'}/>
      <Label text={t('Alarm_Detail_Response.Type.ACR_RA.Subtitle', { code: group.zone.name })}/>
    </>;

  }

  if (group.type === ResponseGroupType.Police) {

    return (
      <Title text={t('Alarm_Detail_Response.Type.ACR_PD.Title')} variant={'primary'}/>
    );

  }

  if (group.type === ResponseGroupType.Personal) {

    return (
      <Title text={t('Alarm_Detail_Response.Type.ACR_PC.Title')} variant={'green250'}/>
    );

  }

  throw new Error(`Unknown group type: ${group.type}`);

};

export const ResponseSectionGroup = memo<ResponseSectionGroupProps>(({ group, className }) => {

  const aprReplacedByZone = useSelector(staticResponseAprReplacedByZoneSelector);

  const responders = useMemo<Responder[]>(() => {

    /* TODO: Verify we even need this
    if (currentPosition) {
      return orderBy(group.contacts, (contact) => hasPosition(contact)
        ? getDistance(contact.position, currentPosition)
        : Number.MAX_VALUE
      );
    } */

    return group.responders;

  }, [group.responders]);

  return (
    <Section title={<GroupTitle group={group} />} className={className}>
      {(group.type === ResponseGroupType.Zone && aprReplacedByZone) && <AprReplacedByZoneNotice/>}
      {group.type === ResponseGroupType.AlarmProfessionalResponder && <ProfessionalResponder/>}
      {responders.map((responder, index) => (
        <ContactableSubsection
          type={group.type}
          key={`${group.type}-${index}`}
          responder={responder}
        />
      ))}
    </Section>
  );

});
