import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'features/identity/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { qs } from 'lib';

export const useAuthenticationRedirect = (): ReactNode | null => {

  const authenticated = useSelector(authenticatedSelector);
  const location = useLocation();

  // Redirect back to login, pass the current pathname as query parameter, so the login page can redirect us back to the
  // current location
  if (authenticated) return null;

  const search = new URLSearchParams(location.search);
  const refreshToken = search.get('refreshToken');
  const customerId = search.get('customerId');

  search.delete('refreshToken');
  search.delete('customerId');

  return (
    <Navigate to={`/?${qs({
      r: `${location.pathname}?${search.toString()}`,
      refreshToken,
      customerId,
    })}`} />
  );

};
