import { MutableRefObject, useState } from 'react';

type DateTimePickerAlignment = 'top' | 'bottom';

export const useDateTimePickerAlignment = (pickerOverlayRef: MutableRefObject<HTMLDivElement>): DateTimePickerAlignment => { // eslint-disable-line

  const [alignment] = useState<DateTimePickerAlignment>('bottom');

  // Cannot get this to work as of now
  /*
  const { contentRootElement } = useContentContext();

  useEffect(() => {
    const target = pickerOverlayRef.current;

    if (target) {
      const handleIntersect = ([entry]: IntersectionObserverEntry[]) => {
        const alignment = entry.boundingClientRect.top > 0 ? 'top' : 'bottom';

        setAlignment(alignment);
      }

      const observer = new IntersectionObserver(handleIntersect, {
        threshold: 1,
        root: contentRootElement,
      });

      observer.observe(target);

      return () => {
        observer.unobserve(target);
      }
    }
  }, [pickerOverlayRef, contentRootElement])
   */

  return alignment;

};
