import { captureException, setExtra } from '@sentry/react';

type LoggerArgs = {
  ctx?: Record<string, unknown>;
  location?: string[];
};

const makeLogger = (args?: LoggerArgs) => {

  const logImpl = (method: 'error' | 'log' | 'debug', message: string, error?: unknown, ctx?: Record<string, unknown>) => {

    message = args?.location?.length ? `[${args.location.join('/')}] ${message}` : message;
    ctx = { ...(args?.ctx ?? {}), ...(ctx ?? {}) };

    const loggerArgs = [message, error, Object.keys(ctx).length ? ctx : undefined].filter(Boolean);

    console[method](...loggerArgs);

  };

  return {
    at: (location: string) => makeLogger({
      ...args,
      location: args?.location ? [...args.location, location] : [location],
    }),
    with: (context: Record<string, unknown>) => makeLogger({
      ...args,
      ctx: { ...(args?.ctx ?? {}), ...context },
    }),
    log: (message: string, extraCtx?: Record<string, unknown>) => {

      logImpl('log', message, undefined, extraCtx);

    },
    debug: (message: string, extraCtx?: Record<string, unknown>) => {

      logImpl('debug', message, undefined, extraCtx);

    },
    error: (message: string, error?: unknown, extraCtx?: Record<string, unknown>) => {

      logImpl('error', message, error, extraCtx);

      if (error) {

        setExtra('message', message);

        if (extraCtx) {

          Object.entries(extraCtx).forEach(([key, value]) => {

            setExtra(key, value);

          });

        }

        captureException(error);

      }

    },
  };

};

export const appLogger = makeLogger();
