import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { useLogOperatorAction } from 'lib';
import { ackedSelector } from 'features/details/ack/exports';
import { logOperatorAction } from 'features/details/chat';
import { Call } from './call';
import { Result, Answer } from './result';
import type { ContactOptionImplProps } from '..';

export * from './option';

export const PhoneCallModal: FC<ContactOptionImplProps> = ({
  isAlarmCreator,
  responder,
  setOnBack,
  onDismiss,
  onContactConfirmation,
}) => {

  const [step, setStep] = useState(0);
  const { call } = responder.actions;
  const name = responder.info.name;

  const logAction = useLogOperatorAction(logOperatorAction, ackedSelector, {
    phone: call,
    name,
    targetAssetId: responder.info._id,
  });

  useEffect(() => {

    logAction('ACTION_CALL');
    // Only want to log once on first render

  }, []); // eslint-disable-line

  const onAnswer = useCallback((answer: Answer) => {

    // A 'responding' answer to the result survey means the this contact is confirmed
    if (answer === 'ACTION_CALL_RESULT_RESPONDING') {

      onContactConfirmation();

    }

    logAction(answer);
    onDismiss();

  }, [onContactConfirmation, onDismiss, logAction]);

  return (
    <>
      {step === 0 && (
        <Call
          contactCode={responder.info.contactCode ?? null}
          phoneNumber={call}
          setStep={setStep}
        />
      )}
      {step === 1 && (
        <Result
          isAlarmCreator={isAlarmCreator}
          setOnBack={setOnBack}
          setStep={setStep}
          onAnswer={onAnswer}
        />
      )}
    </>
  );

};
