import React, { memo } from 'react';

import moment from 'moment';
import { Icon } from '@mdi/react';
import cx from 'clsx';

import { useNavigate } from 'react-router-dom';
import { BetterMoment } from 'components/molecules';
import { useLocale, useSeverityAssets } from 'lib';
import { mdiAccount, mdiArrowRight } from '@mdi/js';
import { useSelector } from 'react-redux';
import { PopulatedAlarm } from 'types';
import { connectedCustomerSelector } from 'features/identity';
import styles from './style.module.scss';

type OpenAlarmProps = {
  alarm: PopulatedAlarm;
};

export const OpenAlarm = memo<OpenAlarmProps>(({ alarm }) => {

  const { t } = useLocale();
  const { className, label } = useSeverityAssets(alarm);
  const dateCreated = moment.utc(alarm.createdAt).local();
  const navigate = useNavigate();
  const customer = useSelector(connectedCustomerSelector);

  if (!alarm.asset || !alarm.customer) return null;

  return (
    <div
      className={cx(styles.openAlarm, styles.clickable, className)}
      onClick={() => {

        navigate(alarm._id);

      }}
    >
      <div className={styles.sub1}>
        <section>
          <span className={cx(styles.title, styles.inheritColor)}>{label}</span>
        </section>
        <section title={alarm.asset.name}>
          <span className={styles.textMedium}>{t('Main_OpenAlarms_MadeBy')}</span>
          <span className={cx(styles.title, styles.primary)}>{alarm.asset.name}</span>
        </section>
        <section>
          <span className={styles.textMedium}>
            {t('Alarm.Date_Created')}
          </span>
          <span className={styles.textDark}>
            <BetterMoment calendarOptions={{
              includeTime: true,
              boldTime: true,
            }}>
              {dateCreated}
            </BetterMoment>
          </span>
        </section>
        {customer._id !== alarm.customer._id && (
          <section>
            <span className={styles.textMedium}>{t('Main_OpenAlarms_Company')}</span>
            <span className={styles.textDark}>{alarm.customer.name}</span>
          </section>
        )}
      </div>
      <div className={styles.sub2}>
        {alarm.asset.properties?.image ? (
          <img src={alarm.asset.properties.image} alt={''} className={styles.circle} />
        ) : (
          <div className={styles.noImageContainer}>
            <Icon
              className={styles.noImage}
              path={mdiAccount}
            />
          </div>
        )}
      </div>
      <div className={styles.divider}/>
      <div className={styles.sub3}>
        <Icon path={mdiArrowRight}/>
      </div>
    </div>
  );

});
