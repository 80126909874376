import moment from 'moment';

import { OverviewFilters, AlarmFiltersState, PopulatedAlarm } from 'types';
import { advancedQs, createSlice } from 'lib';
import { defaultFilters } from './exports';

export * from './exports';

export const filtersToQuery = (filters: OverviewFilters): string => {

  if (filters === null) return '';

  return advancedQs(
    filters.externalId?.length && advancedQs(...filters.externalId.map((id) => `asset.externalId=${id}`)),
    filters.name?.length && advancedQs(...filters.name.map((name) => `asset.name=/${name}/i`)),
    filters.severity && filters.severity?.length !== 3 && advancedQs(...filters.severity.map((severity) => `severity=${severity}`)),
    filters.startDate && `createdAt>=${moment(filters.startDate).toISOString()}`,
    filters.endDate && `createdAt<=${moment(filters.endDate).toISOString()}`,
  );

};

export const evaluateFilter = (filters: OverviewFilters, alarm: PopulatedAlarm) => {

  if (filters === null) return true;

  if (filters.externalId?.length && !filters.externalId.includes(alarm.asset.externalId)) return false;
  if (filters.name?.length && !filters.name.some((name) => new RegExp(name, 'i').test(alarm.asset.name))) return false;
  if (filters.severity?.length && !filters.severity.includes(alarm.severity)) return false;
  if (filters.startDate && moment(alarm.createdAt).isBefore(filters.startDate)) return false;
  if (filters.endDate && moment(alarm.createdAt).isAfter(filters.endDate)) return false;

  return true;

};

const name = 'overview/alarmFilters';

export const alarmFilters = createSlice<AlarmFiltersState, typeof name>({
  name,
  initialState: {
    current: defaultFilters,
    source: null,
  },
});
