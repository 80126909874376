import { DccParticipant, UserContext } from 'types';
import { DccRawParticipant } from 'types/state/alarmDetails.dcc';

const uuidRegex = /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/;

const formatClient = (client: string): string => {

  client = client.replace(/^app_/i, '');

  // UUIDs are formatted with underscores instead of dashes
  const maybeUuid = client.replace(/_/g, '-');

  // Text if the client is a UUID, if so return the properly formatted UUID
  if (uuidRegex.test(maybeUuid)) return maybeUuid;

  return client;

};

export const parseConferenceParticipants = (
  userContext: UserContext,
  alarmCreator: string,
  participants: DccRawParticipant[],
): DccParticipant[] => {

  if (!participants) {

    return null;

  }

  return participants
    .filter((participant) => participant.status === 'connected')
    .map<DccParticipant>((rawParticipant) => {

      rawParticipant.client = formatClient(rawParticipant.client);

      const participant = rawParticipant as DccParticipant;

      // Participant is user if asset id matches or name matches against the friendly user name
      const isSelf = participant.userId === userContext.user._id;

      return {
        ...participant,
        isSelf,
        isAlarmCreator: participant.client === alarmCreator,
      };

    });

};
