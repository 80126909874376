import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from 'lib';
import { RS } from 'types';
import { ENABLE_REDUX_DEV_TOOLS } from 'config';
import { createReduxEnhancer } from '@sentry/react';
import { SentryEnhancerOptions } from '@sentry/react/dist/redux';
import { cloneDeep } from 'lodash';
import { details } from 'features/details';
import { logout } from 'features/identity/auth/exports';
import { overview } from './overview';
import { identity } from './identity';

const sentryEnhancer = createReduxEnhancer({
  stateTransformer: (originalState) => {

    const state = cloneDeep(originalState);

    // Remove sensitive data from state
    if (state.identity?.auth?.value) {

      state.identity.auth.value = null;

    }

    return state;

  },
  actionTransformer: (action) => {

    if (action.type.startsWith('identity/auth/login')) {

      return null;

    }

    return action;

  },
  configureScopeWithState(scope, state: RS) {

    const user = state?.identity?.auth?.value?.context?.user;
    if (user) {

      scope.setUser({
        id: user._id,
        email: user.properties.email,
        username: user.username,
      });

    }

  },
} as SentryEnhancerOptions<RS>);

const name = 'root';

const slice = createSlice<RS, typeof name>({
  name,
  childSlices: {
    identity,
    overview,
    details,
  },
  initialState: {
    customerController: new AbortController(),
  },
  extraReducers: (builder, initialState) => builder
    .addCase(logout, (state) => {

      state.identity.connectedCustomerController.abort();
      state.details = initialState.details;
      state.overview = initialState.overview;

    }),
});

export const store = configureStore({
  reducer: slice.reducer,
  preloadedState: slice.initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  devTools: ENABLE_REDUX_DEV_TOOLS ? {
    trace: true,
  } : false,
  enhancers: (defaultEnhancers) => defaultEnhancers.concat([sentryEnhancer]),
});
