import React, { FC, useContext } from 'react';
import {
  GetAnimatedOverlayOutput, ModalTitle, Overlay, useAnimatedOverlay,
} from 'components/molecules';
import { APRAction } from 'types';
import { ButtonArea, Chip } from 'components/atoms';
import { alarmContext } from '../../../alarmContext';
import { useLocale } from '../../../../../../../lib';
import { Shadow } from '../../../shadow';
import styles from './style.module.scss';

type AlarmProfessionalResponderActionProps = {
  overlayProps: GetAnimatedOverlayOutput;
  config: APRAction;
  onDismiss: () => void;
};

const AlarmProfessionalResponderAction: FC<AlarmProfessionalResponderActionProps> = ({ overlayProps, config, onDismiss }) => {

  const { t } = useLocale();

  return (
    <Overlay
      onDismiss={onDismiss}
      {...overlayProps({ modal: true, centered: true, className: styles.overlay })}
    >
      <ModalTitle
        text={t('Alarm_Detail_Response.APR.Overlay.Title')}
        onClose={onDismiss}
      />
      <div className={styles.confirmation}>
        <p>{t(`Alarm_Detail_Response.APR.Overlay.Confirmation.${config.type}`)}</p>
      </div>
      <ButtonArea>
        <Chip
          label={t('Alarm_Detail_Response.APR.Overlay.Confirm')}
          variant={'primary'}
          size={'large'}
          onClick={() => {

            config.action();
            onDismiss();

          }}
        />
        <Chip
          label={t('Alarm_Detail_Response.APR.Overlay.Cancel')}
          variant={'danger'}
          size={'large'}
          onClick={onDismiss}
        />
      </ButtonArea>
    </Overlay>
  );

};

export const AlarmProfessionalResponderOverlay = () => {

  const {
    onDismissAprAction: onDismiss,
    showAprAction: enabled,
    aprAction,
  } = useContext(alarmContext);

  const { shouldRender, overlayProps } = useAnimatedOverlay(enabled);

  return <>
    <Shadow enabled={enabled}/>
    {shouldRender && (
      <AlarmProfessionalResponderAction
        overlayProps={overlayProps}
        config={aprAction}
        onDismiss={onDismiss}
      />
    )}
  </>;

};
