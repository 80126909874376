import { AbortableThunkArg, AsyncPollableState, State } from 'types/utils';
import { SocketIo } from 'classes/socketIo.class';
import { WebSocketBuiltInEvents } from 'types/webSocket';
import { PopulatedAlarm } from 'types/xgac';

export enum DccStatus {
  NotCreated = 'notCreated',
  Created = 'created',
  Destroyed = 'destroyed',
}

export type FetchDccCodeArgs = AbortableThunkArg & {
  alarmCreator: string;
};

export type AssetHandler = (message: {
  event: string;
  participants?: DccRawParticipant[];
}) => void;

export type XGuardEvents = WebSocketBuiltInEvents & {
  asset: AssetHandler;
};

export type FetchDccArgs = AbortableThunkArg & {
  alarm: PopulatedAlarm;
  startMuted: boolean;
  ws: SocketIo<XGuardEvents>;
};

export type FetchDccReturn = {
  result: Dcc;
  conferenceStatus: DccStatus;
};

// Participant received from the websocket
export type DccRawParticipant = {
  callSid: CallSid;
  client: string;
  userId: string;
  status: 'connected' | unknown;
  muted: boolean;
  hold: boolean;
  startTime: string;
  endConferenceOnExit: boolean;
};

// Parse participant, containing some meta. Guaranteed to be connected
export type DccParticipant = Omit<DccRawParticipant, 'client' | 'status'> & {
  status: 'connected';
  client: string;
  isSelf: boolean;
  isAlarmCreator: boolean;
};

export type DccRaw = {
  code: number;
  conferenceSid: string;
  participants: DccRawParticipant[];
};

export type Dcc = Omit<DccRaw, 'participants'> & {
  participants: DccParticipant[];
};

export type FetchDccResult = {
  statusCode: number;
} | DccRaw;

export type DccState = AsyncPollableState<FetchDccReturn> & {
  connected: boolean;
  muted: MutedState;
  participants: ParticipantsState;
  recording: DccRecordingState;
};

export type SetMuteArgs = {
  controller: AbortController;
  value: boolean;
  conferenceSid: string;
};

export type MutedState = AsyncPollableState<boolean>;

export type CallSid = string;

export type MuteParticipantArgs = {
  callSid: CallSid;
  muted: boolean;
};

export type ParticipantsState = State & Record<CallSid, DccParticipant>;

export type FetchDccRecordingArgs = AbortableThunkArg & {
  alarmId: string;
};

export type FetchDccRecordingReturn = {
  url: string;
  firstJoinMs: number | null;
};

export type DccRecordingState = AsyncPollableState<FetchDccRecordingReturn>;
