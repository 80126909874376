import React, { FC, useEffect, useState } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { mdiPhoneHangup } from '@mdi/js';
import { useLocale } from 'lib';
import styles from '../style.module.scss';
import DCCLoader from '../loader';

const DCCDestroyed: FC = () => {

  const { t } = useLocale();
  const [renderLoader, setRenderLoader] = useState(true);

  useEffect(() => {

    const timeoutId = setTimeout(() => {

      setRenderLoader(false);

    }, 10 * 1000);

    return () => clearTimeout(timeoutId);

  }, []);

  if (renderLoader) {

    return <DCCLoader/>;

  }

  return (
    <div className={cx(styles.shortStatus, styles.destroyed)}>
      <Icon path={mdiPhoneHangup}/>
      <span>{t('Alarm_Detail_DCC.Destroyed')}</span>
    </div>
  );

};

export default DCCDestroyed;
