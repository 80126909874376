import React, {
  FC, ReactNode, useCallback, useState,
} from 'react';

import {
  ModalTitle, Overlay, ChoiceGrid, ModalGridProps,
} from 'components/molecules';
import { ProgressBar } from 'components/atoms';
import {
  mdiEmoticonHappyOutline,
  mdiEmoticonSadOutline,
  mdiAccountQuestion,
  mdiArrowRight,
  mdiThumbUp,
  mdiThumbDown,
  mdiVolumeOff,
  mdiVolumeMute,
  mdiEarHearingOff,
  mdiMapMarkerRadius,
  mdiMapMarkerOff,
  mdiHomeRemove,
  mdiClockAlert,
} from '@mdi/js';
import { useLocale, useStack } from 'lib';
import { GetAnimatedOverlayOutput } from 'components/molecules/overlay/useAnimatedOverlay';
import { mapValues } from 'lodash';
import { TranslationKey } from 'config/i18n';
import { SurveyState } from 'types';
import { TextArea } from './textArea';

type SurveyProps = {
  overlayProps: GetAnimatedOverlayOutput;
  onDismiss: () => void;
  onSubmit: (answers: SurveyState) => void;
};

const defaultSurveyState: SurveyState = {
  customerHappy: null,
  audioQualityOk: null,
  audioQualityFeedback: null,
  responseOk: null,
  responseFeedback: '',
  positionOk: null,
  positionFeedback: null,
  comment: '',
};

export type SurveyStep = keyof SurveyState;

export const Survey: FC<SurveyProps> = ({
  onDismiss,
  onSubmit,
  overlayProps,
}) => {

  const { t } = useLocale();

  const [survey, setSurvey] = useState<SurveyState>(defaultSurveyState);

  const {
    last: step,
    push: pushStep,
    pop: popStep,
  } = useStack<SurveyStep>('customerHappy');

  /**
   * Handle submission of an answer and move to the given step.
   * Supports an optional callback that receives the new survey state
   */
  const onAnswer = useCallback(
    (key: keyof SurveyState) => (value: unknown, newStep: SurveyStep) => {

      pushStep(newStep);

      const newState = { ...survey, [key]: value };

      setSurvey(newState);

      if (newStep === null) {

        onSubmit(newState);

      }

    },
    [survey, setSurvey, pushStep, onSubmit],
  );

  const curriedChoiceGrid = <T extends keyof SurveyState>(
    key: T,
    items: Record<SurveyState[T], [string, SurveyStep]>,
    modalGridProps: Omit<ModalGridProps, 'children'> = { mode: 'contain' },
  ): ReactNode => {

    if (step !== key) {

      return null;

    }

    return (
      <ChoiceGrid
        // uses-translation-asset: "Alarm_Detail_Acknowledge.Survey.*.Title"
        title={t(`Alarm_Detail_Acknowledge.Survey.${key}.Title`)}
        modalGridProps={modalGridProps}
        items={mapValues(items, ([iconPath, arg], choiceKey) => ({
          iconPath,
          arg,
          // uses-translation-asset: "Alarm_Detail_Acknowledge.Survey.Item.*"
          label: t(`Alarm_Detail_Acknowledge.Survey.Item.${choiceKey}` as TranslationKey),
        }))}
        onAnswer={onAnswer(key)}
      />
    );

  };

  const curriedTextArea = <T extends SurveyStep>(
    key: T,
    nextStep: T = null,
  ): ReactNode => {

    if (step !== key) {

      return null;

    }

    return (
      <TextArea
        title={t(`Alarm_Detail_Acknowledge.Survey.${key}.Title`)}
        // uses-translation-asset: "Alarm_Detail_Acknowledge.Survey.*.Placeholder"
        placeholder={t(`Alarm_Detail_Acknowledge.Survey.${key}.Placeholder` as TranslationKey)}
        // uses-translation-asset: "Alarm_Detail_Acknowledge.Survey.*.Label"
        label={t(`Alarm_Detail_Acknowledge.Survey.${key}.Label` as TranslationKey)}
        nextStep={nextStep}
        onAnswer={onAnswer(key)}
      />
    );

  };

  return (
    <Overlay
      onDismiss={onDismiss}
      {...overlayProps({ modal: true })}
    >
      <ModalTitle
        text={t('Alarm_Detail_Acknowledge.Survey.Title')}
        onClose={onDismiss}
        onBack={step !== 'customerHappy' ? popStep : null}
      />
      {curriedChoiceGrid('customerHappy', {
        yes: [mdiEmoticonHappyOutline, 'comment'],
        no: [mdiEmoticonSadOutline, 'audioQualityOk'],
        unknown: [mdiAccountQuestion, 'audioQualityOk'],
        notApplicable: [mdiArrowRight, 'comment'],
      })}
      {curriedChoiceGrid('audioQualityOk', {
        yes: [mdiThumbUp, 'responseOk'],
        no: [mdiThumbDown, 'audioQualityFeedback'],
        skipped: [mdiArrowRight, 'responseOk'],
      })}
      {curriedChoiceGrid('audioQualityFeedback', {
        noAudioConnection: [mdiVolumeOff, 'responseOk'],
        noSoundOnConnection: [mdiVolumeMute, 'responseOk'],
        noHumanResponse: [mdiEarHearingOff, 'responseOk'],
        other: [mdiArrowRight, 'responseOk'],
      }, {
        mode: 'stretch',
        borderAlignment: 'horizontal',
      })}
      {curriedChoiceGrid('responseOk', {
        yes: [mdiThumbUp, 'positionOk'],
        no: [mdiThumbDown, 'responseFeedback'],
        skipped: [mdiArrowRight, 'positionOk'],
      })}
      {curriedTextArea('responseFeedback', 'positionOk')}
      {curriedChoiceGrid('positionOk', {
        yes: [mdiThumbUp, 'comment'],
        no: [mdiThumbDown, 'positionFeedback'],
        skipped: [mdiArrowRight, 'comment'],
      })}
      {curriedChoiceGrid('positionFeedback', {
        notAccurate: [mdiMapMarkerRadius, 'comment'],
        notAvailable: [mdiMapMarkerOff, 'comment'],
        incorrectAddress: [mdiHomeRemove, 'comment'],
        notUpToDate: [mdiClockAlert, 'comment'],
        other: [mdiArrowRight, 'comment'],
      }, {
        mode: 'stretch',
      })}
      {curriedTextArea('comment')}
      <ProgressBar
        current={Object.keys(defaultSurveyState).indexOf(step)}
        total={Object.keys(defaultSurveyState).length - 1}
      />
    </Overlay>
  );

};
