import React, {
  FC, useState, KeyboardEvent, ChangeEvent,
} from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { ackedSelector } from 'features/details/ack/exports';
import { useSelector } from 'react-redux';
import { mdiArrowRight } from '@mdi/js';
import { useLocale } from 'lib';
import styles from './style.module.scss';

type ChatInputFieldProps = {
  onSubmit: (value: string) => void;
};

/**
 * Custom controlled input field specific to alarmDetails chat
 */
export const ChatInputField: FC<ChatInputFieldProps> = ({ onSubmit }) => {

  const { t } = useLocale();

  const [text, setText] = useState('');
  const acked = useSelector(ackedSelector);

  // Handle text submission. Resets content
  const handleSubmit = () => {

    if (text !== '') {

      onSubmit(text);

    }

    setText('');

  };

  // Update text based on content change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

    setText(e.target.value);

  };

  // Submit content if enter was pressed
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {

    if (e.key === 'Enter') {

      e.preventDefault();
      handleSubmit();

    }

  };

  const enabled = acked === false && onSubmit !== null;

  return (
    <div className={cx(
      styles.inputContainer,
      !enabled && styles.acknowledged,
    )}>
      {/* If the alarm was acknowledged, don't allow user input */}
      {enabled ? (
        <>
          <input
            type={'text'}
            value={text}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t('Alarm_Detail_Log_AddMessages')}
          />
          <div onClick={handleSubmit} className={styles.iconWrapper}>
            <Icon path={mdiArrowRight}/>
          </div>
        </>
      ) : (
        <span>
          {t('Alarm_Detail_Log_AddMessagesDisabled')}
        </span>
      )}
    </div>
  );

};
