import React, { memo, useCallback } from 'react';

import { childController, useLocale, useCustomerContext } from 'lib';
import { anyHiddenAlarmsSelector, fetchOpenAlarms, openAlarmsStateSelector } from 'features/overview/openAlarms';
import { fetchClosedAlarms, closedAlarmsStateSelector } from 'features/overview/closedAlarms';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilters, effectiveFiltersSelector } from 'features/overview/alarmFilters';
import { setFilter } from 'features/overview';
import { AlarmList } from './alarmList';
import { ClosedAlarm } from './closedAlarm';
import { OpenAlarm } from './openAlarm';
import styles from './style.module.scss';

export const AlarmLists = memo(() => {

  const { t } = useLocale();

  const dispatch = useDispatch();
  const effectiveFilters = useSelector(effectiveFiltersSelector);
  const anyHiddenAlarms = useSelector(anyHiddenAlarmsSelector);
  const { signal, customer } = useCustomerContext();

  // Paginate more open alarms
  const loadMoreOpenAlarms = useCallback(() => {

    dispatch(fetchOpenAlarms({
      controller: childController(signal),
      paginate: true,
      filters: effectiveFilters,
      customer,
    }));

  }, [dispatch, signal, customer, effectiveFilters]);

  // Paginate more closed alarms
  const loadMoreClosedAlarms = useCallback(() => {

    dispatch(fetchClosedAlarms({
      controller: childController(signal),
      paginate: true,
      filters: effectiveFilters,
      customer,
    }));

  }, [dispatch, signal, customer, effectiveFilters]);

  return (
    <>
      <AlarmList
        className={styles.openAlarms}
        stateSelector={openAlarmsStateSelector}
        item={OpenAlarm}
        title={t('Main_OpenAlarms')}
        subtitle={anyHiddenAlarms && (
          <div
            className={styles.hiddenAlarms}
            onClick={() => {

              dispatch(setFilter({
                source: 'external',
                filter: defaultFilters,
              }));

            }}
          >
            {t('Main_HiddenAlarms')}
          </div>
        )}
        noResults={(
          effectiveFilters
            ? t('Alarm_Overview_List.Open_Alarms.No_Results.With_Filters')
            : t('Alarm_Overview_List.Open_Alarms.No_Results.No_Filters')
        )}
        loadMore={loadMoreOpenAlarms}
      />
      <AlarmList
        className={styles.closedAlarms}
        stateSelector={closedAlarmsStateSelector}
        item={ClosedAlarm}
        title={t('Main_ClosedAlarms')}
        noResults={(
          effectiveFilters
            ? t('Alarm_Overview_List.Closed_Alarms.No_Results.With_Filters')
            : t('Alarm_Overview_List.Closed_Alarms.No_Results.No_Filters')
        )}
        loadMore={loadMoreClosedAlarms}
      />
    </>
  );

});
