import React, { FC, useRef } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import moment, { Moment } from 'moment';

import { Field, FieldRenderProps } from 'react-final-form';
import { mdiCalendar } from '@mdi/js';
import { useDelayedUnmount } from 'lib';
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { useDateTimePickerFieldState, Granularity } from './useDateTimePickerFieldState';
import { PickerOverlay } from './pickerOverlay';
import styles from './style.module.scss';

type DateTimePickerFieldProps = {
  placeholder?: string;
  disableFuture?: boolean;
  maxGranularity?: Granularity;
  transform?: (value: Moment) => Moment;
  calendarProps?: Partial<CalendarProps>;
};

const initialValue = moment.utc().startOf('day');

export const DateTimePickerControl: FC<DateTimePickerFieldProps & FieldRenderProps<string>> = ({
  input,
  placeholder,
  maxGranularity = 'minutes',
  disableFuture,
  transform,
  calendarProps = {} as CalendarProps,
}) => {

  const ref = useRef<HTMLDivElement>(null);

  const {
    value,
    isOpen,
    dismiss,
    toggleOpen,
    openField,
    onPickerChanged,
    domInputProps,
  } = useDateTimePickerFieldState(input, placeholder, maxGranularity);

  const invokeOnChange = (newValue: Moment, isDone: boolean) => {

    onPickerChanged(transform ? transform(newValue) : newValue, isDone);

  };

  const visible = useDelayedUnmount(isOpen, 100);

  return (
    <div ref={ref} className={cx(styles.field, styles.bubble, isOpen && styles.open)}>
      <input {...domInputProps} onClick={openField}/>
      <div className={styles.button} onClick={() => toggleOpen()}>
        <Icon className={styles.icon} path={mdiCalendar}/>
      </div>
      {visible && (
        <PickerOverlay
          calendarProps={calendarProps}
          granularity={maxGranularity}
          onDismiss={dismiss}
          value={value || initialValue}
          parentRef={ref}
          onChange={invokeOnChange}
          disableFuture={disableFuture}
        />
      )}
    </div>
  );

};

export const DateTimePickerField: FC<DateTimePickerFieldProps & { name: string }> = ({ name, ...rest }) => {

  return (
    <Field name={name}>
      {(renderProps) => (
        <DateTimePickerControl {...renderProps} {...rest}/>
      )}
    </Field>
  );

};
