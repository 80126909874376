import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { statusSelector, valueSelector } from 'features/details/map';
import { currentPositionSelector } from 'features/details';
import { useMultiFloorOverlays } from './useMultiFloorOverlays';
import { ImageOverlay } from './imageOverlay';

export const ImageOverlays: FC = () => {

  const status = useSelector(statusSelector);
  const currentPosition = useSelector(currentPositionSelector);
  const value = useSelector(valueSelector);

  const overlays = useMultiFloorOverlays(value, currentPosition);

  if (!status.fulfilled) {

    return null;

  }

  return (
    <>
      {(overlays.map((overlay) => (
        <ImageOverlay
          key={overlay._id}
          url={overlay.imageUrl}
          bounds={overlay.points}
        />
      )))}
    </>
  );

};
