import React, { FC } from 'react';
import colors from 'style/colors.module.scss';
import cx from 'clsx';
import { Icon } from '@mdi/react';
import { mdiAlert } from '@mdi/js';
import { useLocale } from 'lib';
import { SpinnerCircular } from 'spinners-react';
import styles from './style.module.scss';

type ErrorDisplayVariant = 'banner';

interface ErrorDisplayProps {
  message?: string;
  variant?: ErrorDisplayVariant;
  className?: string;
  isRetrying?: boolean;
}

export const ErrorDisplay: FC<ErrorDisplayProps> = ({
  message = 'Unknown Error',
  className = null,
  isRetrying,
}) => {

  const { t } = useLocale();

  return (
    <div
      className={cx(
        styles.errorContainer,
        className,
        isRetrying && styles.isRetrying,
      )}
    >
      <div className={styles.errorBorder}/>
      {isRetrying
        ? <SpinnerCircular
          thickness={150}
          color={colors.error}
          secondaryColor={colors.textLight}
        />
        : <Icon path={mdiAlert}/>
      }
      <div className={styles.message}>
        <div className={styles.title}>
          {t(isRetrying ? 'ErrorDisplay.Title.Retrying' : 'ErrorDisplay.Title.Error')}
        </div>
        <div className={styles.description}>{message}</div>
      </div>
    </div>
  );

};
