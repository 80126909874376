import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { Box } from 'react-html-email';
import { Text } from './text';

type SectionProps = {
  sectionTitle: string;
};

export const Section: FC<SectionProps> = ({ sectionTitle, children }) => {

  return (
    <Box style={{
      width: '100%',
      margin: '20px 0 0',
    }}>
      <Box bgcolor={colors.primary} style={{
        width: '100%',
      }}>
        <Text padding={'8px 16px'} color={'white'}>
          {sectionTitle}
        </Text>
      </Box>
      {children}
    </Box>
  );

};
