import React, {
  forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useState,
} from 'react';
import { OverlayContext } from '../overlay/overlayContext';

export type OverlayProviderRef = {
  isBlocked: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type OverlayProviderProps = PropsWithChildren<{}>;

export const OverlayContextProvider = forwardRef<OverlayProviderRef, OverlayProviderProps>(({ children }, ref) => {

  // Count getter/setter of children overlays
  const [overlayCount, setOverlayCount] = useState(0);

  const incrementCount = useCallback(() => {

    setOverlayCount((count) => count + 1);

    return () => setOverlayCount((count) => count - 1);

  }, [setOverlayCount]);

  useImperativeHandle(ref, () => ({
    isBlocked: overlayCount > 0,
  }), [overlayCount]);

  return (
    <OverlayContext.Provider value={{ overlayCount, incrementCount }}>
      {children}
    </OverlayContext.Provider>
  );

});
