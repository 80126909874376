import React, { FC } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { mdiAccount } from '@mdi/js';
import { Text } from 'components/atoms';
import styles from './style.module.scss';

export type ModalGridItemIconSize = 'large' | 'medium';

export type ModalGridItemProps = {
  iconPath: string;
  label: string;
  subtitle?: string;
  onClick?: () => void;
  iconSize?: ModalGridItemIconSize;
  selected?: boolean;
};

export const ModalGridItem: FC<ModalGridItemProps> = ({
  iconPath,
  label,
  subtitle,
  onClick,
  iconSize = 'large',
  selected = false,
}) => {

  return (
    <div
      className={cx(
        styles.modalGridItemWrapper,
        styles[iconSize],
        selected && styles.selected,
      )}
      onClick={onClick}
    >
      <div className={cx(
        styles.content,
        onClick && styles.clickable,
      )}>
        <Icon path={iconPath ?? mdiAccount}/>
        <Text variant={'h6'} textAlign={'center'}>
          {label}
        </Text>
        {subtitle && (
          <Text variant={'contentSpan'}>{subtitle}</Text>
        )}
      </div>
    </div>
  );

};
