import React, {
  forwardRef, PropsWithChildren, useImperativeHandle, useRef,
} from 'react';
import styles from './style.module.scss';
import { OverlayContextProvider } from '../../../molecules/overlayProvider';

// eslint-disable-next-line @typescript-eslint/ban-types
export type AlarmContentProps = PropsWithChildren<{}>;

export const AlarmContent = forwardRef<HTMLDivElement, AlarmContentProps>((props, ref) => {

  const divRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => divRef.current);

  return (
    <div ref={divRef} className={styles.alarmContent}>
      <OverlayContextProvider>
        {props.children}
      </OverlayContextProvider>
    </div>
  );

});
