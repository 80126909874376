import React, { FC, ReactNode } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type SubsectionLayoutProps = {
  left: ReactNode;
  right: ReactNode;
  onClick: () => void;
};

/**
 * Layout for a subsection.
 * This is abstracted out, because every subsection may have a different implementation, but will always the same layout
 */
export const SubsectionLayout: FC<SubsectionLayoutProps> = ({
  left,
  right,
  onClick,
}) => {

  return (
    <div
      className={cx(
        styles.subsectionContainer,
        onClick && styles.clickable,
      )}
      onClick={onClick}
    >
      <div className={styles.subsectionLayout}>
        <div className={styles.left}>
          {left}
        </div>
        <div className={styles.right}>
          {right}
        </div>
      </div>
    </div>
  );

};
