import React, { FC } from 'react';
import header1 from 'assets/images/header_1.png';
import header2 from 'assets/images/header_2.png';
import logoWhite from 'assets/images/logo_white.png';

import { Outlet } from 'react-router-dom';
import { Footer } from 'components/organisms';
import { useAuthenticatedRedirect } from 'lib';
import { Tokens } from './tokens';
import { LoginBox } from './loginBox';
import styles from './style.module.scss';
import { ErrorBoundary } from '../../../molecules';

export * from './authCallback';

export const Login: FC = () => {

  return (
    <div className={styles.container}>
      <ErrorBoundary>
        <LoginBox/>
      </ErrorBoundary>
      <ErrorBoundary>
        <Tokens/>
      </ErrorBoundary>
    </div>
  );

};

export const LoginPage = () => {

  const redirectNode = useAuthenticatedRedirect();

  if (redirectNode) return redirectNode;

  return (
    <div className={styles.loginBody}>
      <div className={styles.header}>
        <img src={header1} alt='header' className={styles.person} />
        <img src={logoWhite} alt='logo' className={styles.logo} />
        <img src={header2} alt='header' className={styles.emergencyTeam} />
      </div>
      <Outlet/>
      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  );

};
