import { useMemo } from 'react';

import { useLocale } from 'lib/hooks/useLocale';

const regex = /^Meld(\s)?code(\s)?(:)?/i;

// Localises and formats the contact code of a contact
export const useFormattedContactCode = (reportCode: string, prefix = true): string => {

  const { t } = useLocale();

  return useMemo(() => {

    let code = reportCode;

    if (!code) return null;

    const match = regex.exec(code);

    if (match) {

      code = code.replace(match[0], '').trim();

    }

    return prefix ? t('Report_Code', { code }) : code;

  }, [reportCode, prefix, t]);

};
