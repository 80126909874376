import { useRef, useEffect } from 'react';

// Returns the previous value that was passed into this hook
export const usePrevious = <T>(value: T, initialValue?: T | null): T => {

  const ref = useRef<T>(typeof initialValue === 'undefined' ? value : initialValue);

  useEffect(() => {

    ref.current = value;

  }, [value]);

  return ref.current;

};
