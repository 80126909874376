import React, { FC, useEffect, useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';

import { useLocale, useTimer } from 'lib';
import { FetchDccRecordingReturn } from 'types';
import './patch.scss';
import styles from './style.module.scss';

type RecordingProps = {
  recording: FetchDccRecordingReturn;
};

type AudioEvent = Event & { target: HTMLAudioElement };

const Recording: FC<RecordingProps> = ({ recording }) => {

  const { t } = useLocale();
  const audioPlayerRef = useRef<AudioPlayer>(null);
  const recordingLength: number | null = recording.firstJoinMs ? recording.firstJoinMs / 1000 : null;
  const time = useTimer(0, 100);

  useEffect(() => {

    if (!recordingLength) return;

    // Check current time every 100ms, and pause the audio if we reached the end
    const audio = audioPlayerRef.current.audio.current;

    if (audio.currentTime >= recordingLength) {

      audio.currentTime = recordingLength;
      audio.pause();

    }

  }, [audioPlayerRef, recordingLength, time]);

  return (
    <div className={styles.recording}>
      <span className={styles.title}>
        {t('Alarm_Detail_DCC.Recording.Title')}
      </span>
      <span className={styles.sub}>
        {t('Alarm_Detail_DCC.Recording.Instruction')}
      </span>
      <AudioPlayer
        layout={'horizontal-reverse'}
        ref={audioPlayerRef}
        className={styles.audio}
        src={recording.url}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        onPlay={(event: AudioEvent) => {

          if (!recordingLength) {

            return;

          }

          // If we play again when we reached the end of the recording length, reset to start
          if (event.target.currentTime >= recordingLength) {

            event.target.currentTime = 0;

          }

        }}
        {...(recordingLength ? {
          mse: {
            onSeek: undefined,
            srcDuration: recordingLength,
          },
        } : {})}
      />
    </div>
  );

};

export default Recording;
