import React, { FC } from 'react';
import styles from './style.module.scss';

export const FormGroup: FC = ({ children }) => {

  return (
    <div className={styles.formGroup}>
      {children}
    </div>
  );

};
