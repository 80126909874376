import { createContext, useContext } from 'react';

export type ContentContext = {
  contentRootElement: HTMLDivElement;
};

export const contentContext = createContext<ContentContext>({
  contentRootElement: null,
});

export const useContentContext = (): ContentContext => {

  return useContext(contentContext);

};
