import {
  CustomerNode, FusionAuthRedirectSearch, JWTAuthPair,
  LoginArgs, LoginResult, UserContext,
} from 'types';
import {
  qs, authApi, api, createThunk, getUserPreferences,
} from 'lib';
import { XGAC_FUSION_AUTH_CLIENT_ID } from 'config';
import { setCustomer } from 'features/identity/preferences/exports';
import { find } from 'lodash';

export type FusionAuthLoginResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_id: string;
  token_type: string;
  userId: string;
  expired_at: string;
};

export type AuthRefreshResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

const selectCustomer = (context: UserContext, preferredCustomerId?: string): CustomerNode => {

  const customers = context.customerTreeFlat;

  if (customers.length === 0) {

    throw new Error('No customer connected to this account');

  }

  // Get used customers
  const usedCustomers = getUserPreferences(context.user._id)?.usedCustomers ?? [];

  // If we haven't provided a preferred customer, we'll use the last used customer
  if (!preferredCustomerId) {

    const sortedUsedCustomers = usedCustomers.sort((a, b) => {

      const getMsSinceEpoch = (date: string | undefined) => {

        if (!date) return 0;

        return new Date(date).getTime();

      };

      return getMsSinceEpoch(b.lastUsedAt) - getMsSinceEpoch(a.lastUsedAt);

    });

    preferredCustomerId = sortedUsedCustomers[0]?._id;

  }

  if (preferredCustomerId) {

    const customer = find(customers, { _id: preferredCustomerId });

    if (customer) return customer;

  }

  return customers[0];

};

export const login = createThunk<LoginResult, LoginArgs>({
  name: 'identity/auth/login',
  handler: async (args, { dispatch }) => {

    const signal = args.controller.signal;

    const getContext = async ({ accessToken }: JWTAuthPair): Promise<UserContext> => {

      const { data: context } = await api.get<UserContext>('/context', {
        signal,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch(setCustomer({
        node: selectCustomer(context, args.preferredCustomerId),
        userId: context.user._id,
      }));

      return context;

    };

    const refreshLogin = async (refreshToken: string): Promise<JWTAuthPair> => {

      const { data: refreshResponse } = await authApi.post<AuthRefreshResponse>('/auth/refresh', {
        refreshToken,
        clientId: XGAC_FUSION_AUTH_CLIENT_ID,
      }, {
        signal,
      });

      return {
        accessToken: refreshResponse.accessToken,
        refreshToken: refreshResponse.refreshToken,
      };

    };

    const fusionAuthRedirectLogin = async (search: FusionAuthRedirectSearch): Promise<JWTAuthPair> => {

      const path = `/auth/callback/${XGAC_FUSION_AUTH_CLIENT_ID}?${qs(search)}`;
      const { data: loginResponse } = await authApi.get<FusionAuthLoginResponse>(path, {
        signal,
      });

      return {
        accessToken: loginResponse.access_token,
        refreshToken: loginResponse.refresh_token,
      };

    };

    const getJwtAuthPair = (): Promise<JWTAuthPair> => {

      if (args.type === 'fusionAuthRedirectLogin') return fusionAuthRedirectLogin(args.search);

      if (args.type === 'storedToken') return refreshLogin(args.token.refreshToken);

      if (args.type === 'refreshToken') return refreshLogin(args.refreshToken);

      throw new Error('Invalid login type');

    };

    const jwtAuthPair = await getJwtAuthPair();
    const context = await getContext(jwtAuthPair);

    return {
      login: jwtAuthPair,
      context,
    };

  },
});
