import axios from 'axios';
import { DEVICE_REGISTRATION_API_BASE_URL } from 'config';
import { attachAuthorizationInterceptor } from './authorizationInterceptor';

export const deviceRegistrationApi = axios.create({
  baseURL: DEVICE_REGISTRATION_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

attachAuthorizationInterceptor(deviceRegistrationApi);
