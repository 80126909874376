import React, { FC } from 'react';
import { Text, Tail } from 'components/atoms';
import styles from './style.module.scss';

type LabelProps = {
  text: string;
};

export const Label: FC<LabelProps> = (props) => {

  const {
    text,
  } = props;

  return (
    <div className={styles.label}>
      <Tail alignment={'bottom'}/>
      <Text variant={'boldSpan'} theme={'dark'} className={styles.labelText}>
        {text}
      </Text>
    </div>
  );

};
