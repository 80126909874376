import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { qs } from 'lib';
import {
  Box, A, Item, Image,
} from 'react-html-email';
import { GOOGLE_MAPS_KEY } from 'config';
import { LonLat } from 'types';
import { Section } from './section';
import { Text } from './text';

type AlarmPositionProps = {
  coordinates: LonLat;
  address: string;
};

export const AlarmPosition: FC<AlarmPositionProps> = ({
  coordinates: [longitude, latitude],
  address,
}) => {

  const link = `http://maps.google.com/maps?q=${latitude}%2C${longitude}`;

  const imgSrc = `https://maps.googleapis.com/maps/api/staticmap?${qs({
    center: `${latitude},${longitude}`,
    zoom: 15,
    scale: 1,
    size: '560x200',
    maptype: 'roadmap',
    key: GOOGLE_MAPS_KEY,
    format: 'png',
    visual_refresh: true,
    markers: `size:large%7C${latitude},${longitude}`,
  })}`;

  return (
    <Section sectionTitle={'Alarm Position'}>
      <A
        href={link}
        style={{
          width: '100%',
          display: 'block',
        }}
      >
        <Box width={'100%'}>
          <Item>
            <Image
              height={200}
              width={560}
              src={imgSrc}
              alt={'Alarm position'}
            />
          </Item>
          <Text
            padding={'8px'}
            textAlign={'center'}
            backgroundColor={colors.backgroundCultured}
          >
            {address}
          </Text>
          <Text
            padding={'8px'}
            textAlign={'center'}
            backgroundColor={colors.green}
            color={colors.text}
          >
           Open via Google Maps
          </Text>
        </Box>
      </A>
    </Section>
  );

};
