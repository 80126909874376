import React, { FC } from 'react';

import { Field } from 'react-final-form';
import { CheckboxControl, CheckboxSize } from '../checkboxControl';

type CheckboxFieldProps = {
  name: string;
  label: string;
  value?: string | number;
  size?: CheckboxSize;
};

export const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  name,
  value,
  size = 'medium' as CheckboxSize,
}) => {

  return (
    <Field
      type={'checkbox'}
      name={name}
      value={value}
    >
      {({ input }) => (
        <CheckboxControl
          size={size}
          input={input}
          label={label}
        />
      )}
    </Field>
  );

};
