import {
  OverviewFilters, AlarmFiltersSource, AlarmsState, PopulatedAlarm,
} from 'types';
import { createAction, createSelector } from '@reduxjs/toolkit';
import { AlarmRef } from '@x-guard/xgac-types/xgac';
import { connectedCustomerSelector } from 'features/identity/exports';

type SetFilter = {
  source: AlarmFiltersSource;
  filter: OverviewFilters;
};

export const getOffset = (state: AlarmsState, filters: OverviewFilters): number => {

  const value = filters ? state.filtered.value : state.value;

  return value.length;

};

// Move an alarm from the open branch to the closed branch with the given alarm id
export const addOpenAlarm = createAction<PopulatedAlarm>('overview/addOpenAlarm');
export const updateAlarm = createAction<PopulatedAlarm>('overview/updateAlarm');
export const closeAlarm = createAction<AlarmRef>('overview/closeAlarm');
export const setFilter = createAction<SetFilter>('overview/setFilter');
export const alarmMaxAgeDaysSelector = createSelector(
  connectedCustomerSelector,
  (customer) => {

    // Elia custom config
    if (customer._id === '7ee1d515-9026-488c-b278-209e831f5b4f') {

      return 30;

    }

    return 60;

  },
);
