import { useParams } from 'react-router-dom';

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const useAlarmId = (): string | null => {

  const { alarmId } = useParams<{ alarmId: string }>();

  return alarmId.match(regexExp) ? alarmId : null;

};
