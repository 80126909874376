import {
  childController,
  createSlice,
  pause,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected, deviceRegistrationApi, qs, isAbortError,
} from 'lib';
import {
  AssetHandler,
  AsyncStatus,
  DccRaw,
  DccState,
  DccStatus,
  FetchDccArgs,
  FetchDccCodeArgs,
  FetchDccReturn,
  ParticipantsState, RS, UserContext,
} from 'types';
import {
  createAction, createAsyncThunk, Draft,
} from '@reduxjs/toolkit';
import { userContextSelector } from 'features/identity/auth';
import muted, { mutedSelector } from './muted';
import {
  participants,
  normalizeParticipants,
  onUpdateMute,
  onUpdateParticipants,
} from './participants';
import recording, { fetchDccRecording } from './recording';

import { parseConferenceParticipants } from './parseConferenceParticipants';

const name = 'details/dcc';

export * from './exports';

export const conferenceEnd = createAction(`${name}/conferenceEnd`);

const fetchDccWithRetry = async (
  alarmCreator: string,
  getUserContext: () => UserContext,
  getMuted: () => boolean,
  signal: AbortSignal,
): Promise<FetchDccReturn> => {

  const fetch = async (attempt) => {

    if (attempt >= 3) return null;

    await pause(250);

    let dcc: DccRaw = null;

    try {

      const query = qs({
        joinMuted: getMuted(),
      });

      const { data, status } = await deviceRegistrationApi.get<DccRaw>(`/v1/conference/find/${alarmCreator}?${query}`, {
        signal,
      });

      if (status === 404) return fetch(attempt + 1);

      dcc = data as DccRaw;

    } catch (e) {

      if (isAbortError(e)) throw e;

      return fetch(attempt + 1);

    }

    return {
      result: {
        ...dcc,
        participants: parseConferenceParticipants(getUserContext(), alarmCreator, dcc.participants),
      },
      conferenceStatus: DccStatus.Created,
    };

  };

  return fetch(0);

};

/**
 * Fetches the dcc room after it was just created. This is dispatched upon receiving a
 */
export const fetchNewDcc = createAsyncThunk<FetchDccReturn, FetchDccCodeArgs, { state: RS }>(
  `${name}/code`,
  async ({ controller: { signal }, alarmCreator }, { getState }) => {

    // Wait 1 second, this is because of a possible twilio race condition
    await pause(1000);

    const result = await fetchDccWithRetry(
      alarmCreator,
      () => userContextSelector(getState()),
      () => mutedSelector(getState()),
      signal,
    );

    if (result === null) {

      throw new Error('Failed to fetch DCC');

    }

    return result;

  },
);

/**
 * Fetches the dcc on page load and attaches dcc events
 */
export const fetchDcc = createAsyncThunk<FetchDccReturn, FetchDccArgs, { state: RS }>(
  name,
  async ({
    controller: { signal },
    alarm,
    startMuted,
    ws,
  }, { dispatch, getState }) => {

    const alarmCreator = alarm.asset._id;
    const alarmId = alarm._id;

    dispatch(fetchDccRecording({
      alarmId,
      controller: childController(signal),
    }));

    // Fetch the call
    const result = await fetchDccWithRetry(
      alarmCreator,
      () => userContextSelector(getState()),
      () => startMuted,
      signal,
    );

    const assetHandler: AssetHandler = (message) => {

      const event: string = message.event;

      if (event === 'conference/create') {

        dispatch(fetchNewDcc({
          controller: childController(signal),
          alarmCreator,
        }));

      }

      if (event === 'conference/event/conference-end') {

        dispatch(conferenceEnd());
        dispatch(fetchDccRecording({
          controller: childController(signal),
          alarmId: alarm._id,
        }));
        return;

      }

      const parts = parseConferenceParticipants(
        userContextSelector(getState()),
        alarmCreator,
        message.participants,
      );

      if (event === 'conference/event/participant-join' || event === 'conference/event/participant-leave') {

        dispatch(onUpdateParticipants(parts));
        return;

      }

      if (event === 'conference/event/participant-mute' || event === 'conference/event/participant-unmute') {

        dispatch(onUpdateMute(parts));

      }

    };

    ws.subscribeProxy(`asset/${alarmCreator}`, 'asset');
    ws.on('asset', assetHandler);

    signal.addEventListener('abort', () => {

      console.error('cleaning up');

      ws.unsubscribe(`asset/${alarmCreator}`);
      ws.off('asset', assetHandler);

    });

    return result ?? {
      result: null,
      conferenceStatus: DccStatus.NotCreated,
    };

  },
);

/**
 * State slice containing dynamic conference call state
 */
export const dcc = createSlice<DccState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    connected: false,
  },
  childSlices: {
    muted,
    participants,
    recording,
  },
  extraReducers: (builder) => builder
    .addCase(fetchDcc.pending, (state, action) => {

      // Set state to pending and update the muted state
      withPollablePending(state, action);
      state.muted.value = action.meta.arg.startMuted;

    })
    .addCase(fetchDcc.fulfilled, (state, action) => {

      withPollableFulfilled(state, action);

      state.participants = action.payload.result === null
        ? {} as Draft<ParticipantsState>
        : normalizeParticipants(action.payload.result.participants);

    })
    .addCase(fetchDcc.rejected, withPollableRejected)
    .addCase(fetchNewDcc.pending, (state) => {

      state.participants = {} as Draft<ParticipantsState>; // Reset participants
      state.status = AsyncStatus.Fulfilled;
      state.realStatus = AsyncStatus.Pending;
      state.value.conferenceStatus = DccStatus.Created; // Set the room to created to show the disconnected view immediately

      if (state.value.result) {

        state.value.result.code = null;

      }

    })
    .addCase(fetchNewDcc.fulfilled, (state, action) => {

      state.status = AsyncStatus.Fulfilled;
      state.realStatus = AsyncStatus.Fulfilled;
      state.value = action.payload;
      state.participants = normalizeParticipants(action.payload.result.participants);

    })
    .addCase(fetchNewDcc.rejected, withPollableRejected)
    .addCase(conferenceEnd, (state) => {

      state.value.conferenceStatus = DccStatus.Destroyed;

    }),
});
