import React, { FC } from 'react';

import type { AnyDisplayableMessageProps } from 'types';
import { Message } from './message';
import { usePresentationValues, PresentationValues } from './usePresentationValues';

type ControlledMessageProps = AnyDisplayableMessageProps & {
  presentationValuesOverrides?: Partial<PresentationValues>;
};

/**
 * Display a single message
 */
export const ControlledMessage: FC<ControlledMessageProps> = (props) => {

  // Get data and options from props
  const {
    content,
    side,
    presentationValuesOverrides = {},
  } = props;

  // Get memoized values relating to presentation
  const presentationValues = usePresentationValues(content, side);

  return (
    <Message
      {...props}
      presentationValues={{
        ...presentationValues,
        ...presentationValuesOverrides,
      }}
    />
  );

};
