import React, {
  FC, MutableRefObject, useState, useLayoutEffect,
} from 'react';
import { APP_VERSION } from 'config';

import { useLocale } from 'lib';
import {
  FilterableDropdownControl, ChipArrayControl, Overlay, LanguageArray,
} from 'components/molecules';
import { useSelector } from 'react-redux';
import { multipleCustomersExistsSelector } from 'features/identity/auth/exports';
import { useCustomers } from './useCustomers';
import styles from './style.module.scss';

type MenuProps = {
  dismiss: () => void;
  settingsButton: MutableRefObject<HTMLDivElement>;
  initiallyOpen: boolean;
};

/**
 * Overlay menu to manage preferences
 */
export const Menu: FC<MenuProps> = ({
  dismiss,
  settingsButton,
  initiallyOpen,
}) => {

  const { bcp, t } = useLocale();
  const { dropdownProps, usedCustomersProps } = useCustomers(dismiss, initiallyOpen);
  const displayDropdown = useSelector(multipleCustomersExistsSelector);
  const [menuOriginStyle, setMenuOriginStyle] = useState({ });
  const [tailStyle, setTailStyle] = useState({ });

  // Measure the settings button before rendering the menu, and derive styles
  useLayoutEffect(() => {

    setMenuOriginStyle({
      top: settingsButton.current.offsetTop + settingsButton.current.clientHeight,
      left: settingsButton.current.offsetLeft,
    });
    setTailStyle({
      left: settingsButton.current.clientWidth / 2,
    });

  }, [settingsButton, bcp]);

  return (
    <>
      <div className={styles.shadow}/>
      <Overlay className={styles.menuOrigin} style={menuOriginStyle} onDismiss={dismiss}>
        <div className={styles.menu}>
          <div className={styles.tail} style={tailStyle}/>
          <h6>{t('Menu_Linked')}</h6>
          {displayDropdown ? (
            <>
              <span>{t('Menu_Linked_Sub')}</span>
              <FilterableDropdownControl
                {...dropdownProps}
                resetOnFocus={true}
                placeholder={t('Menu_Linked_Placeholder')}
              />
              {usedCustomersProps.values.length > 0 && (
                <>
                  <hr/>
                  <h6>{t('Menu_LinkedLast')}</h6>
                  <span>{t('Menu_LinkedLast_Sub')}</span>
                  <ChipArrayControl {...usedCustomersProps}/>
                </>
              )}
            </>
          ) : (
            <div className={styles.customer}>
              {dropdownProps.value.name}
            </div>
          )}
          <hr/>
          <h6>{t('Menu_Language')}</h6>
          <LanguageArray/>
          <hr/>
          <h6 className={styles.version}>{t('AppVersion', { semVer: APP_VERSION })}</h6>
        </div>
      </Overlay>
    </>
  );

};
