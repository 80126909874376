import { useMeasure } from 'lib';

const breakpoint = 720;

export type ScrollContainerAlignment = 'singleColumn' | 'doubleColumn';

export const useResponsiveScrollContainer = (): [(element: HTMLDivElement) => void, ScrollContainerAlignment] => {

  const [result, setElement] = useMeasure();

  return [
    setElement,
    (result?.width < breakpoint ?? false) ? 'singleColumn' : 'doubleColumn',
  ];

};
