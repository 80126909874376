import React, {
  FC, MutableRefObject, useRef, useState,
} from 'react';
import cx from 'clsx';
import { Moment } from 'moment';
import { Calendar, ClockView } from '@material-ui/pickers';
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { Overlay } from '../overlay';
import styles from './style.module.scss';

import { useDateTimePickerAlignment } from './useDateTimePickerAlignment';
import { AmPmView } from './amPmView';
import { GranularitySelector } from './granularitySelector';

import { Granularity } from './useDateTimePickerFieldState';

type PickerOverlayProps = {
  onDismiss: () => void;
  parentRef: MutableRefObject<HTMLDivElement>;
  value: Moment;
  onChange: (moment: Moment, done: boolean) => void;
  granularity: Granularity;
  calendarProps: Partial<CalendarProps>;
  disableFuture?: boolean;
};

export const PickerOverlay: FC<PickerOverlayProps> = ({
  onDismiss,
  parentRef,
  value,
  onChange,
  granularity,
  disableFuture = true,
  calendarProps,
}) => {

  const overlayRef = useRef<HTMLDivElement>(null);
  const alignment = useDateTimePickerAlignment(overlayRef);

  const [currentGranularity, setCurrentGranularity] = useState<Granularity>('days');

  const next = (nextGranularity: Granularity, timeout = 150) => (moment: Moment) => {

    const hasReachedMaxGranularity = currentGranularity === granularity;

    onChange(moment, hasReachedMaxGranularity);

    if (!hasReachedMaxGranularity) {

      if (timeout) {

        setTimeout(() => {

          setCurrentGranularity(nextGranularity);

        }, timeout);

      } else {

        setCurrentGranularity(nextGranularity);

      }

    }

  };

  return (
    <Overlay
      ref={overlayRef}
      onDismiss={onDismiss}
      className={cx(styles.overlay, styles[alignment], styles[currentGranularity])}
      customRef={parentRef}
    >
      {granularity !== 'days' && (
        <GranularitySelector
          granularity={currentGranularity}
          setGranularity={setCurrentGranularity}
        />
      )}
      {currentGranularity === 'days' ? (
        <Calendar
          {...calendarProps}
          allowKeyboardControl={false}
          disableFuture={disableFuture}
          date={value}
          onChange={next('hours')}
        />
      ) : (
        <>
          {currentGranularity === 'hours' && (
            <AmPmView
              date={value}
              onChange={(newValue) => onChange(newValue, false)}
            />
          )}
          <ClockView
            ampm={true}
            type={currentGranularity}
            date={value}
            onHourChange={next('minutes')}
            onMinutesChange={next('seconds')}
            onSecondsChange={next(null)}
          />
        </>
      )}
    </Overlay>
  );

};
