import { useEffect } from 'react';

import { RS } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { isEqual, pickBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { qs } from '../helpers/queryString';
import { useSearch } from './useSearch';
import { useQuery } from './useQuery';
import { usePrevious } from './usePrevious';

type QueryTransformer<TValue> = {
  toQuery: (value: TValue) => string;
  fromQuery: (query: string | undefined) => TValue;
};

// Transforms an object to a base64 json representation and back for use in storing state in the URL.
// If a default value is provided, only changed properties will be transformed into the string.
export const getBase64JsonTransformer = <T extends object>(defaultValue?: T): QueryTransformer<T> => ({
  toQuery: (value) => {

    let output: Partial<T> = value;

    // create a new object consisting of only the changed properties compared to the default value
    if (defaultValue) {

      output = pickBy(value, (propValue, propName) => !isEqual(propValue, defaultValue[propName])) as Partial<T>;

    }

    return Buffer.from(JSON.stringify(output)).toString('base64');

  },
  fromQuery: (query) => {

    if (query === undefined) {

      return null;

    }

    let parsed: T = JSON.parse(Buffer.from(query, 'base64').toString());

    if (defaultValue) {

      parsed = {
        ...defaultValue,
        ...parsed,
      };

    }

    return parsed;

  },
});

export type QuerySyncConfig<TValue> = {
  key: string;
  selector: ((state: RS) => TValue);
  actionCreator: ((value: TValue) => PayloadAction<unknown>);
  defaultValue: TValue;
  transformer: QueryTransformer<TValue>;
};

type UseQuerySync = <TValue extends object>(args: QuerySyncConfig<TValue>) => void;

export const useQuerySync: UseQuerySync = ({
  key,
  selector,
  actionCreator,
  defaultValue,
  transformer,
}) => {

  const search = useSearch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const valueFromQuery = useQuery(key, transformer.fromQuery);
  const valueFromStore = useSelector(selector);
  const previousValueFromQuery = usePrevious(valueFromQuery, null);
  const previousValueFromStore = usePrevious(valueFromStore, null);

  // If values changed, consolidate based on the source that changed
  if (!isEqual(valueFromQuery, valueFromStore)) {

    if (!isEqual(previousValueFromQuery, valueFromQuery) && valueFromQuery) {

      dispatch(actionCreator(valueFromQuery));

    } else if (previousValueFromStore !== valueFromStore && valueFromStore) {

      navigate({
        search: `?${qs({
          ...search,
          [key]: isEqual(valueFromStore, defaultValue) ? undefined : transformer.toQuery(valueFromStore),
        })}`,
      });

    }

  }

  // Clear query param key on unmount
  useEffect(() => {

    return () => {

      navigate({
        search: `?${qs({
          ...search,
          [key]: undefined,
        })}`,
      });

    };

    // don't take search and history as a dep, as we only want to clear the query key when we are no longer using it

  }, []); // eslint-disable-line

};
