import { currentFiltersSelector, isDefaultFiltersSelector } from 'features/overview/alarmFilters';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchAlarmOverview } from 'features/overview';
import {
  childController, CustomerContext, useWebSocketContext,
} from 'lib';
import { fetchOpenAlarms } from 'features/overview/openAlarms';
import { fetchClosedAlarms } from 'features/overview/closedAlarms';

// Manage the fetching of the alarm overview
export const useAlarmOverview = ({ signal, customer }: CustomerContext): void => {

  const dispatch = useDispatch();
  const isDefaultFilters = useSelector(isDefaultFiltersSelector);
  const currentFilters = useSelector(currentFiltersSelector);
  const { retryIndex } = useWebSocketContext();

  useEffect(() => {

    dispatch(fetchAlarmOverview({
      customer,
      controller: childController(signal),
      filters: null,
    }));

  }, [dispatch, customer, signal, retryIndex]);

  // Dispatch when the filters or when customer changes
  useEffect(() => {

    if (!isDefaultFilters) {

      const getArgs = () => ({
        controller: childController(signal),
        paginate: false,
        filters: currentFilters,
        customer,
      });

      dispatch(fetchOpenAlarms(getArgs()));
      dispatch(fetchClosedAlarms(getArgs()));

    }
    // we don't care about the previousFilters or the previous customer. (as this would cause a double effect call)
    // eslint-disable-next-line
  
  }, [dispatch, customer, signal, currentFilters, isDefaultFilters, retryIndex]);

};
