import { createSlice } from 'lib';
import {
  AlarmResponseState, RS,
} from 'types';
import { memoize } from 'lodash';
import { createAction, createSelector } from '@reduxjs/toolkit';
import { AlarmProfessionalResponderStatus, Responder } from '@x-guard/xgac-types/xgac';
import { staticResponse } from './static';
import { dynamicResponse } from './dynamic';
import { professionalResponse } from './professional';

export const name = 'details/response';

export const setChecked = createAction<{ responder: Responder; checked: boolean }>('details/response/setChecked');

export const getIdentifier = (contact: Responder): string => {

  return contact.info._id;

};

export const response = createSlice<AlarmResponseState, typeof name>({
  name,
  childSlices: {
    dynamic: dynamicResponse,
    static: staticResponse,
    professional: professionalResponse,
  },
  initialState: {
    shared: {},
  },
  extraReducers: (builder) => builder
    .addCase(setChecked, (state, action) => {

      const { responder, checked } = action.payload;

      const id = getIdentifier(responder);

      state.shared[id] = {
        checked,
      };

    }),
});

export const getCheckedSelector = memoize((contact: Responder) => {

  return createSelector(
    (state: RS) => state.details.response.shared[getIdentifier(contact)]?.checked ?? null,
    (state: RS) => {

      const value = state.details.helpers.value;

      if (!value) return null;

      return value.find((item) => item.asset?._id === contact.info._id)?.confirmedHelping ?? null;

    },
    (state: RS) => {

      const nokStatuses = [AlarmProfessionalResponderStatus.Rejected, AlarmProfessionalResponderStatus.Canceled];

      const responder = state.details.response.professional.value?.find((item) => item.staticResponder._id === contact.info._id);

      if (!responder) return null;

      return nokStatuses.includes(responder.status) ? false : null;

    },
    (checked, responding, aprStatus) => checked ?? responding ?? aprStatus,
  );

});
