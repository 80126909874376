import { useLocale } from 'lib';
import { useMemo } from 'react';
import { BCPLang } from 'types';

import GB from 'assets/icons/gb.svg';
import NL from 'assets/icons/nl.svg';
import FRGB from 'assets/icons/fr-bg.svg';

type CountryAssetMap = Record<BCPLang, [string, string]>;

export const useCountryAssets = (): CountryAssetMap => {

  const { t } = useLocale();

  return useMemo(() => ({
    'en-GB': [GB, t('Languages.English')],
    'nl-NL': [NL, t('Languages.Dutch')],
    'fr-FR': [FRGB, t('Languages.French')],
  }), [t]);

};
