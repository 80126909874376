import { setBlockNotifications } from 'features/identity/preferences';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useBlockNotifications = () => {

  const dispatch = useDispatch();

  // Prevent audio and notifications from being played
  useEffect(() => {

    dispatch(setBlockNotifications(true));

    return () => {

      dispatch(setBlockNotifications(false));

    };

  }, [dispatch]);

};
