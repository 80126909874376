import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import {
  statusSelector,
  confirmedHelpingSelector,
  errorSelector,
  isRetryingSelector,
} from 'features/details/helpers';
import { ErrorDisplay } from 'components/atoms';
import { WithAsync } from 'components/molecules';
import { useLocale } from 'lib';
import { HelpersList } from './helpersList';
import styles from './style.module.scss';

/**
 * Helpers block.
 *
 * @version 1.0.0
 */
export const Helpers: FC = () => {

  const { t } = useLocale();

  const status = useSelector(statusSelector);
  const result = useSelector(confirmedHelpingSelector);
  const error = useSelector(errorSelector);
  const isRetrying = useSelector(isRetryingSelector);

  // Only need to render if there are any helpers
  return (
    <div className={styles.helpersBlock}>
      <div className={styles.blockTitleContainer}>
        {t('Helpers_WhoHelp')}
        {status.fulfilled && (
          <span className={styles.blockCount}>{result.length}</span>
        )}
      </div>
      <WithAsync
        status={status}
        error={() => (
          <ErrorDisplay
            isRetrying={isRetrying}
            {...error}
          />
        )}
      >
        {() => <HelpersList helpers={result}/>}
      </WithAsync>
    </div>
  );

};
