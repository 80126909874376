import { createAlarmsSlice } from '../withAlarmsPagination';

export const {
  stateSelector: openAlarmsStateSelector,
  valueSelector: openAlarmsSelector,
  statusSelector: openAlarmsStatusSelector,
  highestSeveritySelector,
  anyHiddenAlarmsSelector,
  fetchAlarms: fetchOpenAlarms,
  slice: openAlarms,
} = createAlarmsSlice('open');
