import { AsyncStatus, DccStatus, RS } from 'types';
import { participantsSelector } from 'features/details/dcc/participants';
import { createSelector, SerializedError } from '@reduxjs/toolkit';

export const statusSelector = (state: RS): AsyncStatus => state.details.dcc.status;
export const errorSelector = (state: RS): SerializedError => state.details.dcc.error;
export const conferenceSidSelector = (state: RS): string => state.details.dcc.value?.result?.conferenceSid ?? null;
export const codeSelector = (state: RS): number => state.details.dcc.value?.result?.code ?? null;
export const mutedSelector = (state: RS): boolean => state.details.dcc.muted.value;
export const signalSelector = (state: RS): AbortSignal => state.details.dcc.controller.signal;
export const conferenceStatusSelector = (state: RS): DccStatus => state.details.dcc.value?.conferenceStatus ?? null;
export const connectedSelector = createSelector(
  participantsSelector,
  (participants) => participants.some((participant) => participant.isSelf),
);
export const anyParticipantsSelector = createSelector(
  participantsSelector,
  (participants) => participants.length > 0,
);
