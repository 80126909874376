import { AxiosInstance } from 'axios';
import { accessTokenSelector } from 'features/identity/auth/exports';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const attachAuthorizationInterceptor = (api: AxiosInstance): void => {

  api.interceptors.request.use(async (config) => {

    const { store } = await import('../../features');

    const accessToken = accessTokenSelector(store.getState());

    if (accessToken) {

      config.headers.Authorization = `Bearer ${accessToken}`;

    }

    return config;

  });

  createAuthRefreshInterceptor(api, async (failedRequest) => {

    const { store } = await import('../../features');
    const { refreshOnce } = await import('../../features/identity/auth/refresh');

    const { accessToken } = await refreshOnce(store);

    failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;

  });

};
