import {
  AlarmEvent, AlarmEventType, AlarmProfessionalResponderStatus, AlarmSeverity,
} from '@x-guard/xgac-types/xgac';
import type {
  Page, PaginatedAsyncState, PaginatedThunkArg, SurveyState,
} from 'types';

// Input arguments for fetching messages of an alarm
export type FetchMessagesArgs = PaginatedThunkArg & {
  alarmId: string;
  offset?: number;
};

export type FetchMessagesResult = Page<AnyMessage>;

export type Message<
  TMessageType extends AlarmEventType,
  TActionType extends string = string,
  TMeta = unknown,
> = AlarmEvent & {
  type: TMessageType;
  action: TActionType;
  meta: TMeta;
};

export type UserMessage = Message<AlarmEventType.User, 'ACTION_CHAT'>;

export type HiddenMessage = Message<AlarmEventType.Hidden>;

export type SystemMessage = Message<AlarmEventType.System, string, {
  translation: string | null;
}>;

type ActionMessage<TActionType extends string, TMeta = unknown> = Message<AlarmEventType.Action, TActionType, TMeta>;

// Utility type for creating an action message type for every type in the union 'T'
// eslint-disable-next-line
type ActionCallMessage<T extends string> = ActionMessage<T, {
  number: string;
  name: string;
  targetAssetId?: string;
}>;

export type AlarmActionLevelIncreased = ActionMessage<'ACTION_ALARM_LEVEL_INCREASED', {
  from: AlarmSeverity;
  to: AlarmSeverity;
}>;

export type AlarmActionAcknowledgeMessage = ActionMessage<'ACTION_ALARM_ACKNOWLEDGE', {
  systemAck: boolean;
  comment: string;
  survey?: SurveyState;
}>;

export type AlarmProfessionalResponderMessages = ActionMessage<
  | 'ACTION_ALARM_PROFESSIONAL_RESPONDER_CANCEL_REQUESTED'
  | 'ACTION_ALARM_PROFESSIONAL_RESPONDER_CANCEL_FAILED'
  | `ACTION_ALARM_PROFESSIONAL_RESPONDER_${Uppercase<AlarmProfessionalResponderStatus>}`, {
  _id: string;
  name: string;
}>;

export type AlarmActions =
  | ActionMessage<'ACTION_ALARM_REPUBLISHED'>
  | ActionMessage<'ACTION_ALARM_MERGED'>
  | AlarmProfessionalResponderMessages
  | AlarmActionAcknowledgeMessage
  | AlarmActionLevelIncreased;

export type LogableActions = ActionCallMessage<
  | 'ACTION_CALL'
  | 'ACTION_CALL_RESULT_RESPONDING'
  | 'ACTION_CALL_RESULT_NOT_REACHABLE'
  | 'ACTION_CALL_RESULT_NO_RESPONSE'
  | 'ACTION_CALL_RESULT_CANCELLED'
  | 'ACTION_SMS'
  | 'ACTION_EMAIL'
  | 'ACTION_PROTOCOL_HELP'
  | 'ACTION_PROTOCOL_VERIFICATION'
>;

export type ActionCallInitiatedMessage = ActionMessage<'ACTION_CALL_INITIATED', {
  number: string;
  name: string;
}>;

export type ActionNotificationMessage = ActionMessage<'ACTION_NOTIFICATION', {
  name: string;
}>;

export type ActionSmsMessage = ActionMessage<'ACTION_SMS', {
  name: string;
}>;

export type ActionResponseConfirmationMessage = ActionMessage<'ACTION_RESPONSE_CONFIRMATION', {
  value: boolean;
}>;

export type ActionManualNotifyMessage = ActionMessage<'ACTION_MANUAL_NOTIFY', {
  name: string;
}>;

export type AlarmGroupCallMessages = ActionMessage<
  | 'ACTION_ALARM_CALL_LEAVE'
  | 'ACTION_ALARM_CALL_JOIN'
  | 'ACTION_GROUP_CALL_LEAVE'
  | 'ACTION_GROUP_CALL_JOIN'
  | 'ACTION_ALARM_CALL_INITIATED'
  | 'ACTION_GROUP_CALL_INITIATED'
>;

export type AnyActionMessage =
  | AlarmActions
  | LogableActions
  | ActionCallInitiatedMessage
  | ActionNotificationMessage
  | ActionSmsMessage
  | ActionResponseConfirmationMessage
  | ActionManualNotifyMessage
  | AlarmGroupCallMessages;

export type AnyMessage =
  | UserMessage
  | HiddenMessage
  | SystemMessage
  | AnyActionMessage;

export type AnyDisplayableMessage =
  | UserMessage
  | SystemMessage
  | AnyActionMessage;

export enum MessageImageMode {
  None = 'imageNone',
  Display = 'imageDisplay',
  Margin = 'imageMargin',
}

export type MessageSide = 'left' | 'right' | 'center';

export type AnyDisplayableMessageProps = {
  content: AnyDisplayableMessage;
  imageMode?: MessageImageMode;
  /**
   * Whether this message was the first one posted in a given day.
   * This is used to render a spacer that contains the day and year of the message chain
   */
  isFirstInDay?: boolean;
  joinWithTop?: boolean;
  joinWithBottom?: boolean;
  bottomMargin?: boolean;
  side: MessageSide;
};

export type ChatState = PaginatedAsyncState<AnyMessage>;
