import io, { Socket } from 'socket.io-client';
import EventEmitter from 'events';
import type TypedEmitter from 'typed-emitter'; // Use this instead of 'tsee' to reduce production bundle size
import { logger } from 'classes/logger';

import { WebSocketBuiltInEvents, Connection } from 'types';

interface IncomingMessage {
  objectName: string;
  data: string | object;
}

// Object containing a mapping from a websocket topic path, to it's corresponding event name
interface EventProxy {
  topicPath: string; // The name of a websocket event
  eventName: string; // Name of a user defined event
}

// ts-ignore compiler flags are justified here, as for some reason the .emit method argument signatures do not work.
/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * WebSocket Class.
 * Handles websocket events properly.
 *
 * @augments EventEmitter
 * @version 1.0.0
 */
export class SocketIo<Events extends WebSocketBuiltInEvents = WebSocketBuiltInEvents> extends (EventEmitter as { new<E>(): TypedEmitter<E> })<Events> {

  private proxies: Record<string, keyof Omit<Events, keyof WebSocketBuiltInEvents>>;

  private pendingProxies: EventProxy[];

  private authenticated: boolean;

  private socket: Socket;

  private state: Connection;

  private disposed: boolean;

  /**
   * Constructor.
   *
   * @class
   * @version 1.0.0
   */
  public constructor(signal: AbortSignal) {

    super();

    this.disposed = false;

    // Adds a listener to the abort signal to dispose of the websocket
    signal.addEventListener('abort', () => {

      this.disposed = true;
      this.disconnect();

    });

    logger.info('📲 socketIo', 'Function: constructor');

    // init
    this.proxies = {};

    // pending
    this.pendingProxies = [];
    this.authenticated = false;
    this.state = Connection.Disconnected;

  }

  /**
   * Connect to a websocket url.
   *
   * @param url - Connection URL.
   * @version 1.0.0
   */
  public async connect(url: string): Promise<string | undefined> {

    // Don't allow using the websocket after it has been disposed
    if (this.disposed === true) {

      logger.error('Cannot connect to a disposed websocket, please instantiate a new websocket instead');
      return undefined;

    }

    if (this.state !== Connection.Disconnected) {

      return undefined;

    }

    this.state = Connection.Connecting;

    logger.info('📲 socketIo', 'Function: connect to :', url);

    // create socket instance
    this.socket = io(url, {
      transports: ['websocket'],
      rememberUpgrade: true,
    });

    this.socket.on('connect', this.onConnect.bind(this));
    this.socket.on('subscribed', this.onSubscribe.bind(this));
    this.socket.on('unsubscribed', this.onUnsubscribe.bind(this));
    this.socket.on('message', this.onMessage.bind(this));
    this.socket.on('disconnect', this.onDisconnect.bind(this));
    this.socket.on('error', this.onError.bind(this));
    this.socket.on('authenticated', this.onAuthenticated.bind(this));
    this.socket.on('authentication_error', this.onAuthenticatedError.bind(this));
    this.socket.on('connect_error', this.onConnectError.bind(this));
    this.socket.on('connect_timeout', this.onConnectTimeout.bind(this));
    this.socket.on('reconnect', this.onReconnect.bind(this));
    this.socket.on('reconnect_attempt', this.onReconnectAttempt.bind(this));
    this.socket.on('reconnecting', this.onReconnecting.bind(this));
    this.socket.on('reconnect_error', this.onReconnectError.bind(this));
    this.socket.on('reconnect_failed', this.onReconnectFailed.bind(this));

    return new Promise((resolve, reject) => {

      let cleanUp: () => void = null;

      const connectedHandler = (message: string | undefined) => {

        cleanUp();
        resolve(message);

      };

      const errorHandler = (message: string | undefined) => {

        cleanUp();
        reject(new Error(message));

      };

      const timeoutId = setTimeout(() => {

        errorHandler('Timed out');

      }, 5000);

      cleanUp = () => {

        this.off('connect', connectedHandler);
        this.off('connectError', errorHandler);
        this.off('error', errorHandler);
        clearTimeout(timeoutId);

      };

      this.on('connect', connectedHandler);
      this.on('connectError', errorHandler);
      this.on('error', errorHandler);

    });

  }

  /**
   * Subscribe a callback to a given topic
   *
   * @param path - Topic id
   * @param eventName - event name to proxy to. Must be a key of the defined events for the websocket
   * @param [force=false] - Whether or not to force a new subscribe, even when already made.
   */
  public subscribeProxy<Proxy extends keyof Omit<Events, keyof WebSocketBuiltInEvents>>(path: string, eventName: Proxy, force = false): void {

    logger.info('📲 socketIo', '- Function: Subscribe', path);

    // check connection
    if (this.state !== Connection.Connected || this.authenticated === false) {

      const idx = this.pendingProxies.findIndex((cd) => (cd.topicPath === path));

      // Make sure listeners are not added twice
      if (idx >= 0 && this.pendingProxies[idx].eventName === eventName) {

        return;

      }

      logger.info('📲 socketIo', '- Function: Subscribe', '- Error: Not connected, put in pending array that will be called in onConnect');

      // add to pending
      this.pendingProxies.push({ topicPath: path, eventName: eventName as string });

      return;

    }

    // check if we need to subscribe
    const needSubscribe = (force === true || typeof this.proxies[path] === 'undefined');

    logger.info('📲 socketIo', '- Function: Subscribe', 'check', needSubscribe);

    // check if not exist already
    if (needSubscribe === true) {

      this.proxies[path] = eventName;
      this.socket.emit('subscribe', path);

    }

  }

  /**
   * Unsubscribe from a given topic
   *
   * @param path - Object passed with unsubscribe.
   * @version 1.0.0
   */
  public unsubscribe(path: string): void {

    logger.info('📲 socketIo', 'Function: unsubscribe', path);

    const callIndex = this.pendingProxies.findIndex((cd) => (cd.topicPath === path));

    // check if exist
    if (callIndex >= 0) {

      // remove from pending
      this.pendingProxies.splice(callIndex, 1);

    }

    // delete callback
    delete this.proxies[path];

    // check connection
    if (this.state !== Connection.Connected) {

      logger.info('📲 socketIo', '- Function: Unsubscribe', '- Error: Not connected');
      return;

    }

    this.socket.emit('unsubscribe', path);

  }

  /**
   * Disconnect the websocket.
   *
   * @version 1.0.0
   */
  public disconnect(): void {

    logger.info('📲 socketIo', '- Function: disconnect');

    // check if not connected yet but trying to connect
    // socket doesn't emit onDisconnect when connecting, so we have to manually disconnect ourselves
    if (this.state === Connection.Connecting) {

      this.state = Connection.Disconnected;

    }

    try {

      this.socket.disconnect();

    } catch (e) {

      logger.info('📲 socketIo', '- Function: disconnect - socket was already destroyed');

    }

  }

  /**
   * Event: connect.
   *
   * @param message - Message passed with connect.
   * @version 1.0.0
   */
  private onConnect(message: string): void {

    logger.info('📲 socketIo', '- Event: onConnect', message);

    this.state = Connection.Connected;

    // Emit signature is broken
    // @ts-ignore
    this.emit('connect', message);

  }

  /**
   * Event: disconnect.
   *
   * @param {*} message - Message passed with disconnect.
   * @version 1.0.0
   */
  private onDisconnect(message: string): void {

    logger.info('📲 socketIo', '- Event: onDisconnect', message);

    this.state = Connection.Disconnected;

  }

  /**
   * Event: subscribe.
   *
   * @param path - Message passed with subscribe.
   * @version 1.0.0
   */
  private onSubscribe(path: string): void {

    logger.info('📲 socketIo', '- Event: onSubscribe', path);

  }

  /**
   * Event: unsubscribe.
   *
   * @param {*} message - Message passed with unsubscribe.
   * @version 1.0.0
   */
  private onUnsubscribe(message: string): void {

    logger.info('📲 socketIo', '- Event: onUnsubscribe', message);

  }

  /**
   * Event: message.
   *
   * @param message - The data received from the WebSocket.
   * @version 1.0.0
   */
  private onMessage(message: IncomingMessage): void {

    if (this.disposed) return;

    logger.debug('📲 socketIo', 'Event: onMessage', message);

    // Emit signature is broken.
    // @ts-ignore
    this.emit('message', message);

    const proxy = this.proxies[message.objectName] as keyof Events;
    if (typeof proxy === 'string') {

      // We cannot assert that '.data' is of type Events[Proxy] because we don't know what the proxy is.
      // @ts-ignore
      this.emit<typeof proxy>(proxy, message.data);

    }

  }

  /**
   * On error event.
   *
   * @param {*} error - Error received from the websocket.
   * @version 1.0.0
   */
  private onError(error: string): void {

    // Emit signature is broken.
    // @ts-ignore
    this.emit('error', error);

    logger.warn('📲 socketIo', 'Event: onError', error);

  }

  /**
   * On Authenticated.
   *
   * @param event - Authenticated received from the websocket.
   * @version 1.0.0
   */
  private onAuthenticated(event: string): void {

    logger.info(['📲 socketIo', 'Event: onAuthenticated', event]);

    this.authenticated = true;

    if (this.pendingProxies.length >= 1) {

      for (const sub of this.pendingProxies) {

        this.subscribeProxy(sub.topicPath, sub.eventName as keyof Omit<Events, keyof WebSocketBuiltInEvents>);

      }

      // clear
      this.pendingProxies = [];

      // else this is probably a authenticated from server restart etc.

    } else {

      // go through obj
      for (const id of Object.keys(this.proxies)) {

        logger.info('📲 socketIo', '- Function: onAuthenticated subscribe again to', id);

        // force new subscription
        this.subscribeProxy(id, this.proxies[id], true);

      }

    }

  }

  /**
   * onAuthenticatedError.
   *
   * @param event - AuthenticatedError received from the websocket.
   * @version 1.0.0
   */
  private onAuthenticatedError(event: string): void {

    logger.warn('📲 socketIo', 'Event: onAuthenticatedError', event);

    this.authenticated = false;

  }

  /**
   * Sends a payload to a given payload
   */
  private sendMessage(path: string, payload: object): void {

    logger.info('📲 socketIo', '- Event: sendMessage', path, payload);

    this.socket.emit(path, payload);

  }

  /**
   * Event: onConnectError.
   *
   * @param message - Message passed with onConnectError.
   * @version 1.0.0
   */
  private onConnectError(message: string): void {

    logger.warn('📲 socketIo', '- Event: onConnectError', message);

    // Emit signature is broken.
    // @ts-ignore
    this.emit('connectError', message);

  }

  /**
   * Event: onConnectTimeout.
   *
   * @param {*} message - Message passed with onConnectTimeout.
   * @version 1.0.0
   */
  private onConnectTimeout(message: string): void {

    logger.info('📲 socketIo', '- Event: onConnectTimeout', message);

  }

  /**
   * Event: onReconnect.
   *
   * @param {*} message - Message passed with onReconnect.
   * @version 1.0.0
   */
  private onReconnect(message: string): void {

    logger.info('📲 socketIo', '- Event: onReconnect', message);

  }

  /**
   * Event: onReconnectAttempt.
   *
   * @param {*} message - Message passed with onReconnectAttempt.
   * @version 1.0.0
   */
  private onReconnectAttempt(message: string): void {

    logger.info('📲 socketIo', '- Event: onReconnectAttempt', message);

  }

  /**
   * Event: onReconnecting.
   *
   * @param {*} message - Message passed with onReconnecting.
   * @version 1.0.0
   */
  public onReconnecting(message: string): void {

    logger.info('📲 socketIo', '- Event: onReconnecting', message);

  }

  /**
   * Event: onReconnectError.
   *
   * @param {*} message - Message passed with onReconnectError.
   * @version 1.0.0
   */
  private onReconnectError(message: string): void {

    logger.warn('📲 socketIo', '- Event: onReconnectError', message);

  }

  /**
   * Event: onReconnectFailed.
   *
   * @param {*} message - Message passed with onReconnectFailed.
   * @version 1.0.0
   */
  private onReconnectFailed(message: string): void {

    logger.info('📲 socketIo', '- Event: onReconnectFailed', message);

  }

}
