import redAlarmSound from 'assets/audio/redAlarm.mp3';
import orangeAlarmSound from 'assets/audio/orangeAlarm.mp3';
import greenAlarmSound from 'assets/audio/greenAlarm.mp3';
import { logger } from 'classes/logger';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { highestSeveritySelector } from 'features/overview/openAlarms';
import { mutedSelector, blockNotificationsSelector } from 'features/identity/preferences';
import { pause } from 'lib';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';

const alarmSoundMap = {
  [AlarmSeverity.Red]: redAlarmSound,
  [AlarmSeverity.Orange]: orangeAlarmSound,
  [AlarmSeverity.Green]: greenAlarmSound,
};

const loop = async (src: string, signal: AbortSignal) => {

  const audio = new Audio(src);

  signal.addEventListener('abort', () => audio.pause());

  if (typeof audio.loop !== 'undefined') {

    audio.loop = true;
    await audio.play();
    return;

  }

  const phase = async () => {

    audio.currentTime = 0;

    await audio.play();
    await new Promise((resolve) => {

      audio.addEventListener('pause', () => resolve(undefined));
      audio.addEventListener('ended', () => resolve(undefined));

    });

  };

  while (!signal.aborted) {

    try {

      await phase();

    } catch (e) {

      if (e.name === 'NotAllowedError') {

        logger.warn('Cannot play alarm sound effect');
        await pause(15 * 1000);

      } else {

        logger.error(e);
        break;

      }

    }

  }

};

/**
 * Hooks into global state to know when to play a looping alarm sound effect
 */
export const useAlarmSoundEffect = (): void => {

  const [windowFocused, setWindowFocused] = useState<boolean>(document.hasFocus());
  const muted = useSelector(mutedSelector);
  const highestAlarmSeverity = useSelector(highestSeveritySelector);
  const blockNotifications = useSelector(blockNotificationsSelector);

  useEffect(() => {

    const handler = () => setWindowFocused(true);

    window.addEventListener('click', handler);

    return () => {

      window.removeEventListener('click', handler);

    };

  }, []);

  useEffect(() => {

    if (windowFocused && highestAlarmSeverity !== null && !muted && !blockNotifications) {

      const controller = new AbortController();

      loop(alarmSoundMap[highestAlarmSeverity], controller.signal);

      return () => {

        controller.abort();

      };

    }

    return undefined;

  }, [windowFocused, highestAlarmSeverity, muted, blockNotifications]);

};
