import { IdentityState } from 'types';
import { createSlice, getSession, setSession } from 'lib';
import { preferences, setCustomer } from './preferences';
import { auth } from './auth';

export * from './exports';

const name = 'identity';

export const identity = createSlice<IdentityState, typeof name>({
  name,
  initialState: {
    connectedCustomer: getSession('connectedCustomer'),
    connectedCustomerController: new AbortController(),
  },
  childSlices: {
    preferences,
    auth,
  },
  extraReducers: (builder) => builder
    .addCase(setCustomer, (state, action) => {

      setSession('connectedCustomer', action.payload.node);

      state.connectedCustomer = action.payload.node;
      state.connectedCustomerController?.abort();
      state.connectedCustomerController = new AbortController();

    }),
});
