import { getDistance } from 'geolib';
import { PointGeometry } from '@x-guard/xgac-types/xgac';

export const isPosition = (pos: unknown): pos is PointGeometry => {

  return typeof pos === 'object'
    && Array.isArray((pos as PointGeometry)?.coordinates)
    && (pos as PointGeometry)?.coordinates.length === 2;

};

export const hasPosition = (obj: object): obj is { position: PointGeometry } => {

  const pos = (obj as { position: PointGeometry })?.position;

  return isPosition(pos);

};

export const areSamePosition = (pos1: PointGeometry, pos2: PointGeometry): boolean => {

  return getDistance(pos1.coordinates, pos2.coordinates) < 1;

};

export const latLng = (pos: PointGeometry) => {

  return [...pos.coordinates].reverse() as [lat: number, lon: number];

};
