import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { useLocale } from 'lib';
import { storedTokensSelector } from 'features/identity/auth';
import { isStoredToken } from 'lib/helpers/storedTokens';
import { Token } from './token';
import styles from './style.module.scss';

export const Tokens: FC = () => {

  const { t } = useLocale();

  const tokens = useSelector(storedTokensSelector)?.filter(isStoredToken);

  if (tokens === null || tokens.length === 0) {

    return null;

  }

  return (
    <div className={styles.sub2}>
      <span className={styles.usersText}>
        {t('Login_SavedUsers')}
      </span>
      <div className={styles.users}>
        {tokens.map((user) => (
          <Token
            key={user.user._id}
            storedToken={user}
            isOnlyToken={tokens.length === 1}
          />
        ))}
      </div>
    </div>
  );

};
