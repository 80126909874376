import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { contentContext, ContentContext } from 'lib';
import styles from './style.module.scss';

export const Content: FC = ({ children }) => {

  const ref = useRef<HTMLDivElement>();
  const [value, setValue] = useState<ContentContext>(null);

  useEffect(() => {

    if (ref.current) {

      setValue({
        contentRootElement: ref.current,
      });

    }

  }, [ref]);

  return (
    <contentContext.Provider value={value}>
      <div ref={ref} className={styles.content}>
        {children}
      </div>
    </contentContext.Provider>
  );

};
