import React, { memo, useEffect } from 'react';

import moment from 'moment';
import { Icon } from '@mdi/react';
import cx from 'clsx';

import { useLocale, useCustomerId } from 'lib';
import { Loader } from 'components/atoms';
import { mdiClose, mdiArrowRight } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { authStateSelector, removeStoredToken, getInitialState } from 'features/identity/auth';
import { login } from 'features/identity/auth/login';
import { AuthState, StoredToken } from 'types';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import { useLoginHRef } from '../useLoginHRef';

type StoredTokenProps = {
  storedToken: StoredToken;
  isOnlyToken: boolean;
};

const useTokenLoginState = (storedToken: StoredToken): AuthState & { blocked: boolean } => {

  const state = useSelector(authStateSelector);
  const active = state.type === 'storedToken' && state.token.user._id === storedToken.user._id;
  const stateForToken = active ? state : getInitialState();

  return {
    ...stateForToken,
    blocked: state.status.pending,
  };

};

export const Token = memo<StoredTokenProps>(({ storedToken, isOnlyToken }) => {

  const { t } = useLocale();

  const dispatch = useDispatch();
  const preferredCustomerId = useCustomerId();
  const loginHref = useLoginHRef();
  const { realStatus, blocked } = useTokenLoginState(storedToken);
  const loggedIn = storedToken.loggedIn ? moment(storedToken.loggedIn) : null;

  useEffect(() => {

    if (!realStatus.rejected) return;

    toast.error(t('Login_SavedUsers_TokenExpired'));
    dispatch(removeStoredToken(storedToken));

    if (isOnlyToken) {

      window.location.replace(loginHref);

    }

  }, [realStatus.rejected]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatchRemove = () => {

    // Alert is fine in this case
    // eslint-disable-next-line no-alert
    if (window.confirm(t('Login_SavedUsers_ConfirmRemoveToken'))) {

      dispatch(removeStoredToken(storedToken));

    }

  };

  const dispatchLogin = blocked ? null : () => {

    dispatch(login({
      controller: new AbortController(),
      preferredCustomerId,
      type: 'storedToken',
      token: storedToken,
    }));

  };

  const loggedInText = loggedIn
    ? t('Login_SavedUsers_LastLoggedIn', {
      date: loggedIn.format('DD-MM-YYYY'),
      time: loggedIn.format('HH:mm'),
    })
    : t('Login_SavedUsers_NeverLoggedIn');

  return (
    <div className={styles.user}>
      <div className={cx(styles.removeButton, styles.clickable)} onClick={dispatchRemove}>
        <Icon path={mdiClose}/>
      </div>
      <div className={styles.loginArea}>
        <div className={styles.userSub2} onClick={dispatchLogin}>
          <span className={styles.userHeader}>
            {storedToken.user.name}
          </span>
          <span className={styles.subText}>
            {loggedInText}
          </span>
        </div>
        <div
          className={cx(styles.loginButton, !blocked && styles.clickable)}
          onClick={dispatchLogin}
        >
          {realStatus.pending ? (
            <Loader variant={'small'}/>
          ) : (
            <Icon path={mdiArrowRight}/>
          )}
        </div>
      </div>
    </div>
  );

});
