import { MutableRefObject } from 'react';

export const getChildrenHeight = (ref: MutableRefObject<HTMLElement>): number => {

  let total = 0;

  for (const child of Array.from(ref.current.childNodes)) {

    total += ((child as HTMLElement).scrollHeight || 0);

  }

  return total;

};
