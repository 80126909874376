import React, { FC } from 'react';
import styles from './style.module.scss';
import { useLoginHRef } from '../useLoginHRef';

export const Form: FC = () => {

  const href = useLoginHRef();

  return (
    <a href={href} className={styles.loginButton}>
      Login
    </a>
  );

};
