import React, { FC } from 'react';

import cx from 'clsx';

import { Chip, ChipProps, ChipSize } from '../chip';
import styles from './style.module.scss';

export type SideProps = Exclude<ChipProps, 'size'>;

export type SplitChipButtonProps = {
  left: SideProps;
  right: SideProps;
  size?: ChipSize;
  className?: string;
};

export const SplitChipButton: FC<SplitChipButtonProps> = ({
  left,
  right,
  size = 'large',
  className,
}) => {

  return (
    <div className={cx(
      styles.splitChipButton,
      styles[size],
      className,
    )}>
      <Chip
        {...left}
        size={size}
        className={cx(styles.left, left.className)}
      />
      <Chip
        {...right}
        size={size}
        className={cx(styles.right, right.className)}
      />
    </div>
  );

};
