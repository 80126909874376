import React, {
  CSSProperties, forwardRef, MutableRefObject,
  PropsWithChildren, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef,
} from 'react';

import cx from 'clsx';
import { OverlayContext } from './overlayContext';
import { useOutsideAlerter } from '../../../lib';
import { OverlayContextProvider, OverlayProviderRef } from '../overlayProvider';

type OverlayProps = PropsWithChildren<{
  onDismiss: () => void;
  className?: string;
  style?: CSSProperties;
  customRef?: MutableRefObject<HTMLDivElement>;
}>;

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(({
  customRef,
  onDismiss,
  className,
  style,
  children,
}, ref) => {

  const overlayRef = useRef<HTMLDivElement>(null);
  const overlayProviderRef = useRef<OverlayProviderRef>(null);

  // Add support for providing a custom ref. This supports the use case of when an overlay should be dismissed when the
  // user clicks outside a component higher or lower in the hierarchy
  const selectedRef = useMemo(() => customRef ?? overlayRef, [customRef]);

  const { incrementCount } = useContext(OverlayContext);

  useEffect(() => incrementCount(), [incrementCount]);

  const handler = useCallback(() => {

    // An overlay is deemed to be blocked when there are any children overlays
    // Child overlays should handle the dismissal event, so ignore
    if (overlayProviderRef.current?.isBlocked || onDismiss === null) {

      return false;

    }

    onDismiss();
    return true;

  }, [onDismiss]);

  useOutsideAlerter(selectedRef, handler);

  useImperativeHandle(ref, () => selectedRef.current);

  return (
    <OverlayContextProvider ref={overlayProviderRef}>
      <div
        ref={overlayRef}
        className={cx(className)}
        style={style}
      >
        {children}
      </div>
    </OverlayContextProvider>
  );

});

export * from './useDraggableOverlay';
export * from './useAnimatedOverlay';
