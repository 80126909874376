import React, { FC } from 'react';

import { ModalGridItem } from 'components/molecules';
import { useLocale } from 'lib';
import { mdiMessageText } from '@mdi/js';
import type { ContactOptionModalItemProps } from '..';

export const SmsMessageOption: FC<ContactOptionModalItemProps> = (props) => {

  const {
    onClick,
  } = props;

  const { t } = useLocale();

  return (
    <ModalGridItem
      iconPath={mdiMessageText}
      label={t('Alarm_Detail_Contact.Sms_Message.Title')}
      subtitle={t('Alarm_Detail_Contact.Sms_Message.Subtitle')}
      onClick={onClick}
    />
  );

};
