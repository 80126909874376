import { useCallback } from 'react';

import { LatLngBounds, LatLngExpression, Map } from 'leaflet';
import { LonLat } from 'types';

const getCorners = (points: LonLat[]): [southWest: LatLngExpression, northEast: LatLngExpression] => {

  let mostNorthern: number = null;
  let mostEastern: number = null;
  let mostSouthern: number = null;
  let mostWestern: number = null;

  for (const [lon, lat] of points) {

    if (mostNorthern === null || lat > mostNorthern) mostNorthern = lat;

    if (mostEastern === null || lon > mostEastern) mostEastern = lon;

    if (mostSouthern === null || lat < mostSouthern) mostSouthern = lat;

    if (mostWestern === null || lon < mostWestern) mostWestern = lon;

  }

  return [
    [mostSouthern, mostWestern],
    [mostNorthern, mostEastern],
  ];

};

export const useFitBounds = (map: Map): ((points: LonLat[]) => void) => {

  return useCallback((points) => {

    // Get north west and north east corners of the points
    const [southWest, northEast] = getCorners(points);

    map.fitBounds(new LatLngBounds(southWest, northEast).pad(0.2), {
      maxZoom: 19,
    });

  }, [map]);

};
