import React, { FC, useCallback, useMemo } from 'react';

import moment from 'moment';

import { LoadState, UserMessage } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import {
  alarmDetailsSelector, alarmIdSelector, signalSelector,
} from 'features/details';
import {
  appendMessage,
  countSelector,
  fetchAlarmEvents,
  isRetryingSelector,
  stateSelector,
} from 'features/details/chat';
import { v4 } from 'uuid';
import {
  api, childController, useLocale, usePaginatedState,
} from 'lib';
import { ErrorDisplay, WithAsync } from 'components';
import { userContextSelector } from 'features/identity/auth/exports';
import { AlarmEventType, ShareConfigType } from '@x-guard/xgac-types/xgac';
import { ackedSelector } from 'features/details/ack/exports';
import { getMessagesSelector } from './getMessagesSelector';
import { Messages } from './messages';
import styles from './style.module.scss';

export const Chat: FC = () => {

  const { t } = useLocale();

  const signal = useSelector(signalSelector);
  const alarmId = useSelector(alarmIdSelector);
  const userContext = useSelector(userContextSelector);
  const acked = useSelector(ackedSelector);
  const state = useSelector(stateSelector);
  const count = useSelector(countSelector);
  const alarmDetails = useSelector(alarmDetailsSelector);

  // Handle fetching new items
  const dispatch = useDispatch();

  const sendMessage = useMemo(() => {

    return (text: string) => {

      const alarmCreate = {
        meta: null,
        text,
        action: 'ACTION_CHAT' as const,
        type: AlarmEventType.User as const,
      };

      api.post(`/alarms/${alarmId}/alarm-events`, alarmCreate);

      // Manually create a message, and dispatch, for better responsiveness
      const message: UserMessage = {
        ...alarmCreate,
        _id: v4(),
        customer: alarmDetails.customer,
        share: {
          type: ShareConfigType.Private,
          extra: [],
        },
        alarm: {
          _ref: 'Alarm',
          _id: alarmId,
        },
        createdAt: moment.utc().toISOString(),
        updatedAt: null,
        user: userContext.user,
      };

      dispatch(appendMessage(message));

    };

  }, [dispatch, userContext, alarmId, alarmDetails]);

  const fetchNextMessages = useCallback(() => {

    dispatch(fetchAlarmEvents({
      alarmId,
      // Coerce id into a number, as when paginating, we know for sure the oldest message is a real id,
      // and not a generated one
      controller: childController(signal),
      paginate: true,
    }));

  }, [alarmId, dispatch, signal]);

  // Get selector
  const messagesSelector = useMemo(() => getMessagesSelector(userContext, acked), [userContext, acked]);

  // Get state and pagination props
  const paginatedState = usePaginatedState(state, fetchNextMessages, messagesSelector);
  const isRetrying = useSelector(isRetryingSelector);

  if (paginatedState.status.idle) {

    return null;

  }

  if (state.status.loadState === LoadState.Settled && state.value.length === 0 && acked) {

    paginatedState.meta.centerContent = true;

  }

  return (
    <div className={styles.chatBlock}>
      <div className={styles.blockTitleContainer}>
        {t('Alarm_Detail_Log_Title')}
        {state.status.fulfilled && (
          <span className={styles.blockCount}>{count}</span>
        )}
      </div>
      <WithAsync
        status={paginatedState.status}
        error={() => (
          <ErrorDisplay
            isRetrying={isRetrying}
            {...paginatedState.error}
          />
        )}
      >
        {() => (
          <Messages
            {...paginatedState}
            sendMessage={sendMessage}
            userContext={userContext}
          />
        )}
      </WithAsync>
    </div>
  );

};
