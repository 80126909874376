import React, { FC, useMemo } from 'react';

import moment from 'moment';

import { DccParticipant } from 'types';
import { mdiAlert, mdiHeadset } from '@mdi/js';
import { useLocale } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { updateMute } from 'features/details/dcc/participants';
import { alarmCreatorSelector } from 'features/details';
import Item, { ItemProps } from '../item';

type ParticipantProps = {
  participant: DccParticipant;
};

const Participant: FC<ParticipantProps> = (props) => {

  const {
    participant,
  } = props;

  const { t } = useLocale();
  const dispatch = useDispatch();
  const alarmCreator = useSelector(alarmCreatorSelector);

  const itemProps: ItemProps = useMemo(() => {

    const muted = participant.muted;

    const result: ItemProps = {
      icon: mdiHeadset,
      name: participant.client.toString(),
      timer: {
        initialTimeMs: moment.utc().valueOf() - moment.utc(participant.startTime).valueOf(),
      },
      muted,
      toggleMuted: () => {

        dispatch(updateMute({
          callSid: participant.callSid,
          muted: !muted,
        }));

      },
    };

    if (participant.isSelf) {

      result.label = t('Alarm_Detail_DCC.Connected.Self');

    }

    if (participant.isAlarmCreator) {

      result.label = t('Alarm_Detail_DCC.Connected.Alarm_Maker');
      result.name = alarmCreator.name;

      if (alarmCreator.properties.image) {

        result.icon = null;
        result.image = alarmCreator.properties.image;

      } else {

        result.icon = mdiAlert;

      }

    }

    return result;

  }, [t, dispatch, participant, alarmCreator]);

  return (
    <Item {...itemProps}/>
  );

};

export default Participant;
