import React, { FC } from 'react';

import cx from 'clsx';

import { Chip, ChipProps } from 'components/atoms';
import styles from './style.module.scss';

export type GetChipProps<T> = (value: T) => ChipProps & { key?: string | number };

export type ChipArrayProps<T> = {
  values: T[];
  getChipProps?: GetChipProps<T>;
  className?: string;
};

export const ChipArrayControl: FC<ChipArrayProps<unknown>> = ({
  values,
  getChipProps,
  className,
}) => {

  return (
    <div className={cx(styles.chipArray, className)}>
      {values.map((value) => {

        const chipProps = getChipProps?.(value) ?? {};

        return (
          <Chip
            key={value.toString()}
            label={value.toString()}
            {...chipProps}
          />
        );

      })}
    </div>
  );

};
