import { createContext } from 'react';
import { noop } from 'lib';

type OverlayContextType = {
  overlayCount: number;
  incrementCount: () => () => void;
};

// Context object that tracks the amount of overlays
export const OverlayContext = createContext<OverlayContextType>({
  overlayCount: 0,
  incrementCount: () => noop,
});
