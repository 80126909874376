import React, { FC } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type ButtonAreaProps = {
  className?: string;
};

export const ButtonArea: FC<ButtonAreaProps> = ({
  className,
  children,
}) => {

  return (
    <div className={cx(styles.buttonArea, className)}>
      {children}
    </div>
  );

};
