import {
  OverviewFilters,
  AlarmsState,
  AsyncStatus,
  FetchAlarmsArgs,
  FetchAlarmsResult,
  PaginatedAsyncState,
  Page,
  PaginatedThunkArg,
  RS,
  S,
  SliceWithInitialState,
  OverviewAlarm,
} from 'types';
import {
  AsyncThunk, createSelector, Draft,
} from '@reduxjs/toolkit';
import {
  advancedQs,
  api,
  createSlice,
  createThunk, defaultRetryConfig,
  paginationDefaults,
  withPaginatedFulfilled,
  withPaginatedPending,
  withPollableRejected,
} from 'lib';
import { AlarmRef, AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { maxBy, reject, uniqBy } from 'lodash';
import { setCustomer } from 'features/identity/preferences/exports';
import { alarmMaxAgeDaysSelector, getOffset } from 'features/overview/exports';
import { defaultFilters, filtersToQuery } from 'features/overview/alarmFilters';
// import { alarmIdSelector, fetchAlarmDetails } from 'features/details';
import moment from 'moment';

export type FetchFilterableAlarmArgs = PaginatedThunkArg & {
  filters: OverviewFilters;
  refresh?: boolean;
};

// Selects the destination for a given action
export const selectStateBranch = (
  state: Draft<AlarmsState>,
  action: { meta: { arg: FetchFilterableAlarmArgs } },
): Draft<PaginatedAsyncState<OverviewAlarm>> => {

  // If there are no filters, return the state
  if (action.meta.arg.filters === null) {

    return state;

  }

  // Otherwise return the filtered state branch
  return state.filtered;

};

// Type shorthand for an alarms fetch phase handler
type WithAlarms<TPhase extends 'pending' | 'fulfilled' | 'rejected'> = <
  TValue extends OverviewAlarm,
  TResult extends Page<TValue> = Page<TValue>,
>(
  state: Draft<AlarmsState>,
  action: ReturnType<AsyncThunk<TResult, FetchFilterableAlarmArgs, { state: RS }>[TPhase]>
) => void;

export const withAlarmsPending: WithAlarms<'pending'> = (state, action) => {

  const branch = selectStateBranch(state, action);

  withPaginatedPending(branch, action);

  if (action.meta.arg.refresh) {

    branch.status = AsyncStatus.Fulfilled;

  }

};

export const withAlarmsFulfilled: WithAlarms<'fulfilled'> = (state, action) => {

  const branch = selectStateBranch(state, action);

  withPaginatedFulfilled(branch, action);

  if (action.meta.arg.refresh) {

    branch.value = action.payload.result;

  }

  branch.value = uniqBy(branch.value, '_id');

  // If this request is filtered, remove the hidden alarms if they were now made visible with these filters
  if (action.meta.arg.filters) {

    const ids = action.payload.result.map((alarm) => ({
      _id: alarm._id,
    }));

    state.hiddenFilteredAlarms = reject<AlarmRef>(state.hiddenFilteredAlarms, ...ids);

  } else {

    state.hiddenFilteredAlarms = [];

  }

};

export const withAlarmsRejected: WithAlarms<'rejected'> = (state, action) => {

  withPollableRejected(selectStateBranch(state, action), action);

};

type Selectors = {
  stateSelector: S<AlarmsState>;
  valueSelector: S<OverviewAlarm[]>;
  statusSelector: S<AsyncStatus>;
  highestSeveritySelector: S<AlarmSeverity>;
  anyHiddenAlarmsSelector: S<boolean>;
};

export const createAlarmsSlice = <TType extends 'open' | 'closed'>(type: TType): Selectors & {
  fetchAlarms: AsyncThunk<FetchAlarmsResult, FetchAlarmsArgs, { state: RS }>;
  slice: SliceWithInitialState<AlarmsState, {}, `overview/${TType}Alarms`>; // eslint-disable-line
} => {

  const branchName: `${TType}Alarms` = `${type}Alarms`;

  const selectors: Selectors = {
    stateSelector: (state) => state.overview[branchName],
    valueSelector: (state) => state.overview[branchName].value,
    statusSelector: (state) => state.overview[branchName].status,
    highestSeveritySelector: createSelector(
      (state): RS => state.overview[branchName].value,
      (alarms): AlarmSeverity => (alarms.length > 0
        ? maxBy<OverviewAlarm>(alarms, (alarm) => {

          const priorityMap = {
            [AlarmSeverity.Red]: 0,
            [AlarmSeverity.Orange]: -1,
            [AlarmSeverity.Green]: -2,
          };

          return priorityMap[alarm.severity];

        }).severity
        : null),
    ),
    anyHiddenAlarmsSelector: (state) => state.overview[branchName].hiddenFilteredAlarms.length > 0,
  };

  const name: `overview/${TType}Alarms` = `overview/${branchName}`;

  const fetchAlarms = createThunk<FetchAlarmsResult, FetchAlarmsArgs>({
    name: `${name}/fetch`,
    retryConfig: defaultRetryConfig,
    handler: async ({
      paginate,
      controller: { signal },
      filters,
      customer,
    }, { getState }) => {

      const maxAge = alarmMaxAgeDaysSelector(getState());

      const defaultStartDate = moment()
        .utc()
        .startOf('day')
        .subtract(maxAge, 'days')
        .toDate();

      const { data } = await api.get<FetchAlarmsResult>(`/alarms?${advancedQs(
        {
          $offset: paginate ? getOffset(selectors.stateSelector(getState()), filters) : undefined,
          $sort: '-createdAt',
          $max: 100,
          $omit: 'meta',
          'ack.value': type !== 'open',
          ...(customer.isAlarmCenter ? {
            'alarmCenter._id': customer._id,
          } : {
            'customer._id': customer._id,
          }),
        },
        filtersToQuery({
          ...defaultFilters,
          startDate: defaultStartDate.toISOString(),
          ...(filters ?? {}),
        }),
      )}`, { signal });

      return data;

    },
  });

  const slice = createSlice<AlarmsState, `overview/${TType}Alarms`, {}, AlarmsState>({ // eslint-disable-line
    name,
    initialState: {
      ...paginationDefaults,
      hiddenFilteredAlarms: [],
      filtered: {
        ...paginationDefaults,
      },
    },
    extraReducers: (builder) => builder
      .addCase(setCustomer, (state) => {

        state.hiddenFilteredAlarms = [];

      })
      .addCase(fetchAlarms.pending, withAlarmsPending)
      .addCase(fetchAlarms.fulfilled, withAlarmsFulfilled)
      .addCase(fetchAlarms.rejected, withAlarmsRejected),

  });

  return {
    ...selectors,
    fetchAlarms,
    slice,
  };

};
