import {
  DccParticipant, MuteParticipantArgs, ParticipantsState, RS,
} from 'types';
import { createSlice, deviceRegistrationApi } from 'lib';
import { createAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

const name = 'details/dcc/participants';

export const onUpdateMute = createAction<DccParticipant[]>(`${name}/onUpdateMute`);
export const onUpdateParticipants = createAction<DccParticipant[]>(`${name}/onUpdateParticipants`);

export const updateMute = createAsyncThunk<void, MuteParticipantArgs, { state: RS }>(
  name,
  async ({ callSid, muted }) => {

    await deviceRegistrationApi.post('/v1/conference/participant/mute', {
      callSid,
      muted,
    });

  },
);

export const normalizeParticipants = (participants: DccParticipant[]): ParticipantsState => {

  const result = {} as ParticipantsState;

  for (const participant of participants) {

    result[participant.callSid] = participant;

  }

  return result;

};

/**
 * Stores a normalized version of current participants, keyed by callSid.
 * This store is actively updated with the x-guard websocket, while the value.result branch of the dcc state isn't
 */
export const participants = createSlice<ParticipantsState, typeof name>({
  name,
  initialState: {} as ParticipantsState,
  extraReducers: (builder) => builder
    .addCase(updateMute.pending, (state, action) => {

      const { callSid, muted } = action.meta.arg;

      state[callSid].muted = muted;

    })
    .addCase(onUpdateParticipants, (state, action) => {

      return normalizeParticipants(action.payload);

    })
    .addCase(onUpdateMute, (state, action) => {

      for (const newParticipant of action.payload) {

        const participant = state[newParticipant.callSid];

        if (participant.callSid === newParticipant.callSid) {

          participant.muted = newParticipant.muted;

        }

      }

    }),
});

export const participantsSelector = createSelector(
  (state: RS) => state.details.dcc.participants,
  (parts) => Object.values<DccParticipant>(parts),
);

export const otherParticipantsSelector = createSelector(
  participantsSelector,
  (parts) => parts.filter((participant) => !participant.isAlarmCreator),
);

export const alarmCreatorParticipantSelector = createSelector(
  participantsSelector,
  (parts) => parts.find((participant) => participant.isAlarmCreator),
);

export const selfParticipantSelector = createSelector(
  participantsSelector,
  (parts) => parts.find((participant) => participant.isSelf),
);
