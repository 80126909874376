import { XGAC_AUTH_API_BASE_URL } from 'config';
import axios from 'axios';

export const authApi = axios.create({
  baseURL: XGAC_AUTH_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
