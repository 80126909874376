import React, { memo, useCallback } from 'react';

import { Icon } from '@mdi/react';

import { useLocale } from 'lib';
import { ToggleButtonControl } from 'components';
import { mdiVolumeHigh, mdiVolumeMute } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import {
  selfParticipantSelector,
  updateMute,
} from 'features/details/dcc/participants';
import styles from './style.module.scss';

const Connected = memo(() => {

  const { t } = useLocale();
  const dispatch = useDispatch();

  const selfParticipant = useSelector(selfParticipantSelector);
  const muted = selfParticipant.muted;
  const toggleMuted = useCallback(() => {

    dispatch(updateMute({
      callSid: selfParticipant.callSid,
      muted: !muted,
    }));

  }, [dispatch, muted, selfParticipant]);

  return (
    <div className={styles.title}>
      <span className={styles.titleText}>{t('Alarm_Detail_DCC.Connected.Title')}</span>
      <div className={styles.microphone}>
        <span>{t('Alarm_Detail_DCC.Connected.Microphone.Label')}</span>
        <ToggleButtonControl
          size={'medium'}
          variants={{
            active: 'warning200',
            inactive: 'green250-muted',
          }}
          left={{
            label: (
              <div className={styles.label}>
                <span>{t('Alarm_Detail_DCC.Connected.Microphone.Muted')}</span>
                <Icon path={mdiVolumeMute}/>
              </div>
            ),
          }}
          right={{
            label: (
              <div className={styles.label}>
                <span>{t('Alarm_Detail_DCC.Connected.Microphone.Enabled')}</span>
                <Icon path={mdiVolumeHigh}/>
              </div>
            ),
          }}
          input={{
            toggled: muted,
            onChange: toggleMuted,
          }}
        />
      </div>
    </div>
  );

});

export default Connected;
