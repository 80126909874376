import React, {
  ChangeEvent, FC, useCallback, useState,
} from 'react';

import cx from 'clsx';

import { Field } from 'react-final-form';
import { useLocale } from 'lib';
import { TranslationFunc } from 'config/i18n';
import { InputControl, InputControlContentType } from '../inputControl';
import { ChipArrayControl } from '../chipArrayControl';
import styles from './style.module.scss';

type ChipInputFieldContentType = InputControlContentType;

type ChipInputFieldContentTypeKey = keyof ChipInputFieldContentType;

const toContentType = <T extends ChipInputFieldContentTypeKey>(
  text: string,
  contentType: T,
): ChipInputFieldContentType[T] => {

  switch (contentType) {

  case 'text':
    return text as ChipInputFieldContentType[T];
  case 'number':
    return parseInt(text, 10) as ChipInputFieldContentType[T];
  default:
    throw new Error(`Unknown content type: ${contentType}`);

  }

};

const validate = <T extends ChipInputFieldContentTypeKey>(
  content: ChipInputFieldContentType[T],
  values: ChipInputFieldContentType[T][],
  contentType: T,
  t: TranslationFunc,
): string | null => {

  if (values.includes(content)) {

    return t('Error_Validation_DuplicateValue');

  }

  if (contentType === 'number' && Number.isNaN(content as number)) {

    return t('Error_Validation_NotAValidNumber');

  }

  return null;

};

type ChipInputFieldProps = {
  name: string;
  className?: string;
  placeholder?: string;
  contentType?: ChipInputFieldContentTypeKey;
};

export const ChipInputField: FC<ChipInputFieldProps> = ({
  name,
  contentType = 'text' as ChipInputFieldContentTypeKey,
  placeholder = name,
  className = '',
}) => {

  const [preSubmitTouched, setPreSubmitTouched] = useState<boolean>();
  const [preSubmitError, setPreSubmitError] = useState(null);
  const [text, setText] = useState('');
  const { t } = useLocale();

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {

    setPreSubmitError(null);
    setText(e.target.value);

  }, []);

  const handleSubmit = useCallback((value, handleChange) => (content) => {

    if (content.length === 0) return;

    const converted = toContentType(content, contentType);
    const currentError = validate(converted, value, contentType, t);
    setPreSubmitTouched(true);
    setPreSubmitError(currentError);

    if (currentError === null) {

      setText('');
      handleChange([...value, converted]);

    }

  }, [contentType, t]);

  return (
    <Field name={name} type={contentType}>
      {({ input, meta }) => (
        <div className={cx(className)}>
          <InputControl
            input={{
              ...input,
              value: text,
              onChange,
            }}
            meta={{
              ...meta,
              touched: preSubmitTouched || meta.touched,
              error: preSubmitError ?? meta.error,
            }}
            placeholder={placeholder}
            submit={handleSubmit(input.value, input.onChange)}
            buttonType={'add'}
          />
          {input.value.length > 0 && (
            <ChipArrayControl
              className={styles.chipArray}
              getChipProps={(chipValue) => ({
                label: chipValue.toString(),
                variant: 'dark',
                onDelete: () => input.onChange(input.value.filter((value) => value !== chipValue)),
              })}
              values={input.value}
            />
          )}
        </div>
      )}
    </Field>
  );

};
