import {
  InputHTMLAttributes, useCallback, useEffect, useRef, useState,
} from 'react';

import moment, { Moment } from 'moment';

import { FieldInputProps } from 'react-final-form';
import { useToggle } from 'lib';
import { useDateTimePickerFormatter } from './useDateTimePickerFormatter';

export type Granularity = 'days' | 'hours' | 'minutes' | 'seconds';

type DateTimePickerFieldState = {
  value: Moment;
  domInputProps: Partial<InputHTMLAttributes<HTMLInputElement>>;
  isOpen: boolean;
  dismiss: () => void;
  openField: () => void;
  toggleOpen: (value?: boolean) => void;
  onPickerChanged: (value: Moment, isDone: boolean) => void;
};

export const useDateTimePickerFieldState = (
  { value: inputValue, onChange: inputOnChange }: FieldInputProps<string>,
  placeholder: string,
  maxGranularity: Granularity,
): DateTimePickerFieldState => {

  const {
    formatter,
    shortFormat,
    longFormat,
  } = useDateTimePickerFormatter();

  const focusRef = useRef<boolean>(false);

  const onChange = useCallback((value: Moment) => {

    inputOnChange(moment(value)?.toISOString() ?? null);

  }, [inputOnChange]);

  const value = inputValue ? moment.utc(inputValue) : null;
  const [isOpen, toggleOpen] = useToggle(false);
  const [text, setText] = useState(() => formatter(value));

  const setFormattedText = useCallback((newValue: Moment) => {

    setText(formatter(newValue));

  }, [formatter]);

  useEffect(() => {

    if (!focusRef.current) {

      setFormattedText(value);

    }

  }, [value, setFormattedText, focusRef]);

  return {
    value,
    isOpen,
    toggleOpen,
    dismiss: useCallback(() => {

      toggleOpen(false);

    }, [toggleOpen]),
    openField: useCallback(() => {

      toggleOpen(true);

    }, [toggleOpen]),
    onPickerChanged: useCallback((newValue, isDone) => {

      // Set values
      setFormattedText(newValue);
      onChange(newValue);

      // If we have a selected a date with a maximum date granularity (default is 'minutes'), dismiss
      if (isDone) {

        toggleOpen(false);

      }

    }, [setFormattedText, onChange, toggleOpen]),
    domInputProps: {
      value: text,
      placeholder: placeholder ?? (maxGranularity === 'days' ? shortFormat : longFormat),
      onFocus: useCallback(() => {

        focusRef.current = true;

      }, [focusRef]),
      onBlur: useCallback(() => {

        focusRef.current = false;

      }, [focusRef]),
      onChange: useCallback((e) => {

        const newValue = e.target.value;

        setText(newValue);

        if (newValue === '') {

          onChange(null);
          return;

        }

        const asMoment = moment.utc(newValue);

        if (asMoment.isValid() && !asMoment.isSame(value)) {

          onChange(asMoment);

        }

      }, [onChange, value]),
    },
  };

};
