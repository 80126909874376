import React, { FC } from 'react';

import { Icon } from '@mdi/react';
import moment from 'moment';
import cx from 'clsx';

import { mdiArrowRight } from '@mdi/js';
import { BetterMoment, BetterMarkdown } from 'components/molecules';
import { useLocale, useSeverityAssets } from 'lib';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PopulatedAlarm } from 'types';
import { connectedCustomerSelector } from 'features/identity';
import styles from './style.module.scss';

type ClosedAlarmProps = {
  alarm: PopulatedAlarm;
};

export const ClosedAlarm: FC<ClosedAlarmProps> = ({ alarm }) => {

  const { t } = useLocale();
  const { label, path, className } = useSeverityAssets(alarm);
  const navigate = useNavigate();
  const currentCustomer = useSelector(connectedCustomerSelector);

  if (!alarm.asset || !alarm.customer) return null;

  const dateCreated = moment.utc(alarm.createdAt).local();
  const omitCustomer = alarm.customer._id === currentCustomer._id;

  // Trim start and end of names, as they break markdown rendering
  const alarmCreator = alarm.asset.name.trim();
  const customer = alarm.customer.name.trim();

  const madeBy = omitCustomer
    ? t('Main_By.No_Customer', { alarmCreator })
    : t('Main_By.Customer', { alarmCreator, customer });

  const madeByTitle = omitCustomer ? alarm.asset.name : `${alarm.asset.name} - ${alarm.customer.name}`;

  return (
    <div
      className={cx(styles.closedAlarm, styles.clickable, className)}
      onClick={() => {

        navigate(alarm._id);

      }}
    >
      <div className={styles.sub1}>
        <Icon className={styles.inheritColor} path={path}/>
      </div>
      <div className={styles.sub2}>
        <span className={styles.inheritColor}>{label}</span>
        <span className={styles.text} title={madeByTitle}>
          <BetterMarkdown>
            {madeBy}
          </BetterMarkdown>
        </span>
        <span className={styles.text}>
          <BetterMoment calendarOptions={{
            includeTime: true,
            boldTime: true,
          }}>
            {dateCreated}
          </BetterMoment>
        </span>
      </div>
      <div className={styles.divider}/>
      <div className={styles.sub3}>
        <Icon path={mdiArrowRight}/>
      </div>
    </div>
  );

};
