import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'lib/hooks/useLocale';
import {
  DispatchLogOperatorAction, LogableActions, LogOperatorActionArgs, S,
} from 'types';
import { TranslationKey } from 'config/i18n';

type CallActions = LogableActions['action'];

type SelectMeta<T extends CallActions> = Extract<LogableActions, { action: T }>['meta'];

type LogAction = <T extends CallActions>(action: T, extraMeta?: Partial<SelectMeta<T>>) => void;

const logActionToTranslationKey: Record<CallActions, TranslationKey> = {
  ACTION_CALL: 'Alarm_Detail_Log_Action_Call',
  ACTION_CALL_RESULT_RESPONDING: 'Alarm_Detail_Log_Action_Call_Result_Responding',
  ACTION_CALL_RESULT_NOT_REACHABLE: 'Alarm_Detail_Log_Action_Call_Result_Not_Reachable',
  ACTION_CALL_RESULT_NO_RESPONSE: 'Alarm_Detail_Log_Action_Call_Result_No_Response',
  ACTION_CALL_RESULT_CANCELLED: 'Alarm_Detail_Log_Action_Call_Result_Cancelled',
  ACTION_SMS: 'Alarm_Detail_Log_Action_Sms',
  ACTION_EMAIL: 'Alarm_Detail_Log_Action_Email',
  ACTION_PROTOCOL_HELP: 'Alarm_Detail_Log_Action_Protocol_Help',
  ACTION_PROTOCOL_VERIFICATION: 'Alarm_Detail_Log_Action_Protocol_Verification',
};

export const useLogOperatorAction = (
  actionCreator: DispatchLogOperatorAction,
  ackedSelector: S<boolean>,
  baseMeta: object = {},
): LogAction => {

  const { t } = useLocale();

  const dispatch = useDispatch();
  const acked = useSelector(ackedSelector);

  return useCallback<LogAction>((action, extraMeta = {}) => {

    // If the alarm is closed, don't log
    if (acked !== false) {

      return;

    }

    const meta = {
      ...baseMeta,
      ...extraMeta,
    };

    dispatch(actionCreator({
      action,
      text: t(logActionToTranslationKey[action], meta),
      meta,
    } as LogOperatorActionArgs<typeof action>));

  }, [actionCreator, baseMeta, t, dispatch, acked]);

};
