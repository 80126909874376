import {
  createSlice,
  asyncPollableDefaults,
  withPollablePending,
  withPollableFulfilled,
  withPollableRejected,
  deviceRegistrationApi, createThunk, defaultRetryConfig, withPollableRetrying,
} from 'lib';
import {
  DccRecordingState, FetchDccRecordingArgs, FetchDccRecordingReturn, RS,
} from 'types';

const name = 'details/dcc/recording';

export const fetchDccRecording = createThunk<FetchDccRecordingReturn, FetchDccRecordingArgs>({
  name,
  retryConfig: {
    ...defaultRetryConfig,
    shouldRetry: (args) => defaultRetryConfig.shouldRetry(args) && args.retryIndex < 10,
  },
  handler: async ({ alarmId, controller: { signal } }): Promise<FetchDccRecordingReturn> => {

    const { data } = await deviceRegistrationApi.get<FetchDccRecordingReturn>(`/v1/conference/find/audio/${alarmId}`, {
      signal,
    });

    return data;

  },
});

export default createSlice<DccRecordingState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
  },
  extraReducers: (builder) => builder
    .addCase(fetchDccRecording.pending, withPollablePending)
    .addCase(fetchDccRecording.fulfilled, withPollableFulfilled)
    .addCase(fetchDccRecording.retrying, withPollableRetrying)
    .addCase(fetchDccRecording.rejected, withPollableRejected),
});

export const dccRecordingSrcSelector = (state: RS) => state.details.dcc.recording.value;
