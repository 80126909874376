import React, {
  FC, Children, ReactElement, CSSProperties,
} from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

export type ModalGridProps = {
  children: ReactElement | ReadonlyArray<ReactElement>;
  mode: 'stretch' | 'contain';
  columnLength?: number;
  maxWidth?: CSSProperties['maxWidth'];
  borderAlignment?: 'vertical' | 'horizontal';
};

export const ModalGrid: FC<ModalGridProps> = ({
  children,
  mode,
  columnLength = 2,
  maxWidth = '144px',
  borderAlignment = mode === 'stretch'
    ? 'vertical'
    : 'horizontal',
}) => {

  const gridProps: {
    className: string;
    style: CSSProperties;
  } = {
    className: cx(
      styles.grid,
      styles[mode],
      styles[borderAlignment],
    ),
    style: null,
  };

  if (mode === 'stretch') {

    gridProps.style = {
      gridTemplateColumns: `repeat(${columnLength}, 1fr)`,
    };

  }

  /**
   * Gets the props for a child of a given index
   * This is mainly to apply styling that used to be set with a css selector, but that implementation doesn't scale
   * well with different grid templates
   */
  const getChildProps = (i: number) => {

    const childCount = Children.toArray(children).length;

    // Whether to apply a right border
    const getApplyVertical = (newColumnLength: number) => {

      return (i % newColumnLength) !== newColumnLength - 1;

    };

    if (mode === 'stretch') {

      // This calculates whether or not the current item should have a bottom border
      const getApplyHorizontal = () => {

        let rem = childCount % columnLength;

        if (rem === 0) {

          rem = columnLength;

        }

        return i < (childCount - rem);

      };

      return {
        className: cx(
          styles.item,
          getApplyVertical(columnLength) && styles.applyVertical,
          getApplyHorizontal() && styles.applyHorizontal,
        ),
      };

    }
    return {
      className: cx(
        styles.item,
        getApplyVertical(childCount) && styles.applyVertical,
      ),
      style: {
        maxWidth,
      },
    };

  };

  return (
    <div {...gridProps}>
      {Children.map<ReactElement, ReactElement>(children ?? null, (child, i) => {

        return (
          <div key={child.key ?? i} {...getChildProps(i)}>
            {<child.type {...child.props} />}
          </div>
        );

      })}
    </div>
  );

};
