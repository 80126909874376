import React, {
  FC, ReactNode, useEffect, useMemo, useRef,
} from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';
import './slideTo';

import { useMap } from 'react-leaflet';
import { PointGeometry } from '@x-guard/xgac-types/xgac';
import { areSamePosition, latLng } from 'lib';
import { Marker, DivIcon, LatLngExpression } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { mdiMapMarker, mdiBullseye } from '@mdi/js';
import styles from './style.module.scss';
import type { OMS } from '..';

export type MarkerVariant = 'alarmPosition' | 'currentPosition' | 'quickResponse' | 'nationalQuickResponse';

type CustomMarkerProps = {
  position: PointGeometry;
  variant: MarkerVariant;
  oms?: OMS;
  label?: ReactNode;
};

export interface SlidableMarker extends Marker {
  slideTo: (position: LatLngExpression, options?: {
    duration?: number;
    keepAtCenter?: boolean;
  }) => void;
}

export const CustomMarker: FC<CustomMarkerProps> = ({
  position,
  variant,
  label,
  oms,
}) => {

  const ref = useRef<SlidableMarker>();
  const prevPosition = useRef<PointGeometry>(position);
  const map = useMap();

  // Create icon
  const icon = useMemo(() => {

    const iconPath = variant === 'alarmPosition'
      ? mdiBullseye
      : mdiMapMarker;

    return new DivIcon({
      html: renderToString(
        <>
          {label && (
            <div className={styles.labelAnchor}>
              {label}
            </div>
          )}
          <Icon path={iconPath}/>
        </>,
      ),
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      className: cx(
        styles[variant],
        styles.icon,
      ),
    });

  }, [label, variant]);

  // Slide once the position changes
  useEffect(() => {

    if (ref.current && !areSamePosition(prevPosition.current, position)) {

      prevPosition.current = position;

      ref.current.slideTo(latLng(position), {
        duration: 300,
        keepAtCenter: false,
      });

    }

  }, [prevPosition, ref, position]);

  // Create marker
  useEffect(() => {

    const marker = new Marker(latLng(position), {
      icon,
    }).addTo(map);

    oms?.addMarker(marker);

    ref.current = marker as SlidableMarker;

    return () => {

      oms?.removeMarker(marker);
      marker.remove();

    };
    // Map has a stable identity, position updates are handled by another hook, and icon updates are not supported

  }, [oms]); // eslint-disable-line

  return null;

};
