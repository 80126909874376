import { UnionToArray } from '@x-guard/xgac-types/utils';
import type {
  AlarmPreferences, BCPLang, CustomerNode, LoginResult, AnyStoredToken, UsedCustomer, AbsolutePos,
} from 'types';

type SessionStorage = {
  token: string;
  auth: LoginResult;
  connectedCustomer: CustomerNode;
};

export type UserPreferences = {
  usedCustomers?: UsedCustomer[];
};

export type UserPreferencesMap = Record<string, UserPreferences>;

export type LocalStorage = {
  locale: BCPLang;
  tokens: AnyStoredToken[];
  userPreferences: UserPreferencesMap;
  alarmPreferences: Record<string, AlarmPreferences>;
  mapResizeRatio: number;
  contactOptionsOverlayOffset: AbsolutePos;
};

const sessionKeys: UnionToArray<keyof LocalStorage> = [
  'locale',
  'userPreferences',
  'tokens',
  'alarmPreferences',
  'mapResizeRatio',
  'contactOptionsOverlayOffset',
];

export const setSession = <TKey extends keyof SessionStorage>(key: TKey, value: SessionStorage[TKey]): SessionStorage[TKey] => {

  const json = value ? JSON.stringify(value) : null;

  sessionStorage.setItem(key, json);

  return value;

};

export const getSession = <TKey extends keyof SessionStorage>(key: TKey): SessionStorage[TKey] => {

  const item = sessionStorage.getItem(key);

  return item ? JSON.parse(item) : null;

};

export const setLocal = <TKey extends keyof LocalStorage>(key: TKey, value: LocalStorage[TKey]): LocalStorage[TKey] => {

  const json = value ? JSON.stringify(value) : null;

  localStorage.setItem(key, json);

  return value;

};

export const getLocal = <TKey extends keyof LocalStorage>(key: TKey, defaultValue?: LocalStorage[TKey]): LocalStorage[TKey] => {

  const item = localStorage.getItem(key);

  if (item) {

    try {

      const parsed = JSON.parse(item);

      if (parsed !== null) {

        return parsed;

      }

    } catch (e) {

      console.error(`Error parsing JSON for localStorage key: ${key}`);

    }

  }

  setLocal(key, defaultValue);

  return defaultValue ?? null;

};

export const setLocalStorageObject = (obj: Partial<LocalStorage>) => {

  Object.entries(obj).forEach(([key, value]) => {

    setLocal(key as keyof LocalStorage, value);

  });

};

export const getLocalStorageObject = (): LocalStorage => {

  return sessionKeys.reduce((acc, key) => ({ ...acc, [key]: getLocal(key) }), {}) as LocalStorage;

};

export const getUserPreferences = (userId: string) => {

  const record = getLocal('userPreferences', {});

  return record[userId] ?? {};

};

export const setUserPreferences = (userId: string, preferences: Partial<UserPreferences>) => {

  return setLocal('userPreferences', {
    ...getLocal('userPreferences', {}),
    [userId]: {
      ...getUserPreferences(userId),
      ...preferences,
    },
  });

};
