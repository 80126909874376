import {
  OverviewState,
  FetchAlarmOverviewArgs,
  RS, FetchAlarmsArgs, AlarmsState,
} from 'types';
import {
  childController,
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
} from 'lib';
import { createAsyncThunk, Draft } from '@reduxjs/toolkit';
import { cloneDeep, reject, uniqBy } from 'lodash';
import {
  setFilter, addOpenAlarm, updateAlarm,
} from './exports';
import { closedAlarms, fetchClosedAlarms } from './closedAlarms';
import { openAlarms, fetchOpenAlarms } from './openAlarms';
import { alarmFilters, evaluateFilter, getIsDefault } from './alarmFilters';

export * from './exports';

const name = 'overview';

export const fetchAlarmOverview = createAsyncThunk<void, FetchAlarmOverviewArgs, { state: RS }>(
  name,
  async ({
    controller: { signal },
    filters,
    customer,
  }, { dispatch }) => {

    const getArgs = (): FetchAlarmsArgs => ({
      controller: childController(signal),
      paginate: false,
      filters,
      customer,
    });

    dispatch(fetchOpenAlarms(getArgs()));
    dispatch(fetchClosedAlarms(getArgs()));

  },
);

export const overview = createSlice<OverviewState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
  },
  childSlices: {
    alarmFilters,
    openAlarms,
    closedAlarms,
  },
  extraReducers: (builder) => builder
    .addCase(fetchAlarmOverview.pending, withPollablePending)
    .addCase(fetchAlarmOverview.fulfilled, withPollableFulfilled)
    .addCase(fetchAlarmOverview.rejected, withPollableRejected)
    .addCase(addOpenAlarm, (state, { payload }) => {

      const prevLength = state.openAlarms.value.length;
      state.openAlarms.value = uniqBy([payload, ...state.openAlarms.value], '_id');
      state.openAlarms.total += (state.openAlarms.value.length - prevLength);

      if (evaluateFilter(state.alarmFilters.current, payload)) {

        const prevFilteredLength = state.openAlarms.filtered.value.length;
        state.openAlarms.filtered.value = uniqBy([payload, ...state.openAlarms.filtered.value], '_id');
        state.openAlarms.filtered.total += (state.openAlarms.filtered.value.length - prevFilteredLength);

      }

    })
    .addCase(updateAlarm, (state, { payload }) => {

      const updateSlice = (slice: Draft<AlarmsState>, acked: boolean) => {

        const current = slice.value.find((alarm) => alarm._id === payload._id);

        // early return if the alarm existing in state is already the same as the payload
        if (current && current.ack.value === payload.ack.value) return;

        const prevLength = slice.value.length;
        const prevFilteredLength = slice.filtered.value.length;
        const filtered = evaluateFilter(state.alarmFilters.current, payload);

        if (payload.ack.value === acked) {

          slice.value = [payload, ...slice.value];

          if (filtered) {

            slice.filtered.value = [payload, ...slice.filtered.value];

          }

        } else {

          slice.value = reject(slice.value, { _id: payload._id });

          if (filtered) {

            slice.filtered.value = reject(slice.filtered.value, { _id: payload._id });

          }

        }

        slice.total += (slice.value.length - prevLength);
        slice.filtered.total += (slice.filtered.value.length - prevFilteredLength);

      };

      updateSlice(state.openAlarms, false);
      updateSlice(state.closedAlarms, true);

    })
    .addCase(setFilter, (state, action) => {

      // store the source and a cloned version of the values
      state.alarmFilters.source = action.payload.source;

      // Clone once again, as the previous sorting we did while comparing mutated the copy
      state.alarmFilters.current = cloneDeep(action.payload.filter);

      // If the filter is set to default, this automatically means all hidden alarms are now visible
      if (getIsDefault(action.payload.filter)) {

        state.openAlarms.hiddenFilteredAlarms = [];

      }

    }),
});
