import { toast } from 'react-toastify';
import { t } from 'config/i18n';

import { AckState } from 'types/state/alarmDetails.ack';
import { UserContext } from 'types';
import {
  api,
  asyncPollableDefaults,
  createSlice,
  createThunk,
  noop,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
} from '../../../lib';

export type AcknowledgeArgs = {
  controller: AbortController;
  userContext: UserContext;
  alarmId: string;
  comment: string;
};

export const acknowledge = createThunk<void, AcknowledgeArgs>({
  name: 'details/ack/acknowledge',
  handler: async ({ comment, alarmId }) => {

    const sendRequest = () => {

      return api.post(`/alarms/${alarmId}/acknowledge`, {
        comment,
        systemAck: false,
      });

    };

    await toast.promise(sendRequest(), {
      pending: t('Alarm_Detail_Acknowledge.Toast.Pending'),
      success: t('Alarm_Detail_Acknowledge.Toast.Success'),
      error: t('Alarm_Detail_Acknowledge.Toast.Error'),
    });

  },
});

const name = 'details/ack';

export const ack = createSlice<AckState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
  },
  extraReducers: (builder) => builder
    .addCase(acknowledge.pending, (state, action) => {

      const { comment, userContext } = action.meta.arg;

      withPollablePending(state, action);

      state.value = {
        needed: state.value.needed,
        value: true,
        comment,
        ackedAt: new Date().toISOString(),
        ackedBy: {
          _id: userContext.user._id,
          _ref: 'User',
          name: userContext.user.name,
        },
      };

    })
    .addCase(acknowledge.fulfilled, (state, action) => {

      withPollableFulfilled(state, action, noop);

    })
    .addCase(acknowledge.rejected, withPollableRejected),
});
