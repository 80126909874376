import { createContext, useContext } from 'react';
import { UsedCustomer } from 'types';

export type CustomerContext = {
  customer: UsedCustomer;
  signal: AbortSignal;
};

export const customerContext = createContext<CustomerContext>({
  customer: null,
  signal: null,
});

export const useCustomerContext = (): CustomerContext => {

  return useContext(customerContext);

};
