import { pickBy } from 'lodash';
import { stringify } from 'qs';

/**
 * Utility function using the built-in URLSearchParams API to create a query string from an object.
 * Doesn't use the query-string package as it's deprecated, and URLSearchParams can be used anywhere
 */
// It's too much work to add a type to 'input'
// eslint-disable-next-line
export const qs = (input: any): string => {
  const filteredInput = pickBy(input, (prop) => typeof prop !== 'undefined');

  return stringify(filteredInput);

};
