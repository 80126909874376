import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { connectedCustomerSelector } from '../../features/identity';

export const useCustomerId = (): string => {

  const [search, setSearch] = useSearchParams();
  const customerId = search.get('customerId');
  const selectedCustomer = useSelector(connectedCustomerSelector);

  useEffect(() => {

    if (selectedCustomer && customerId && selectedCustomer._id === customerId) {

      search.delete('customerId');
      setSearch(search);

    }

  }, [selectedCustomer, customerId, search, setSearch]);

  return customerId;

};
