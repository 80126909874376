import { useEffect, useState } from 'react';

export const useDelayedUnmount = (isMounted: boolean, delayTime: number): boolean => {

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {

    let timeoutId: NodeJS.Timeout;

    if (isMounted && !shouldRender) {

      setShouldRender(true);

    }

    if (!isMounted && shouldRender) {

      timeoutId = setTimeout(() => setShouldRender(false), delayTime);

    }

    return () => clearTimeout(timeoutId);

  }, [isMounted, delayTime, shouldRender]);

  return shouldRender;

};
