import { getSession, useCustomerId } from 'lib';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { login } from '../../features/identity/auth/login';
import { statusSelector } from '../../features/identity/auth';
import { store } from '../../features';

export const useAutoLogin = () => {

  const [search, setSearch] = useSearchParams();
  const refreshToken = search.get('refreshToken');
  const preferredCustomerId = useCustomerId();
  const authSession = getSession('auth');
  const dispatch = useDispatch();

  const canLogin = () => {

    return !statusSelector(store.getState()).pending;

  };

  useEffect(() => {

    if (refreshToken && canLogin()) {

      dispatch(login({
        controller: new AbortController(),
        type: 'refreshToken',
        preferredCustomerId,
        refreshToken,
      }));

      search.delete('refreshToken');
      setSearch(search);

    }

  }, [refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (authSession && canLogin()) {

      dispatch(login({
        controller: new AbortController(),
        type: 'refreshToken',
        preferredCustomerId,
        refreshToken: authSession.login?.refreshToken,
      }));

    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

};
