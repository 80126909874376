import { useCallback, useState } from 'react';

export const useToggle = (defaultValue = false): [boolean, (newValue?: boolean) => void] => {

  const [value, setValue] = useState<boolean>(defaultValue);

  return [
    value,
    useCallback((newValue: boolean | null = null) => {

      setValue(typeof newValue === 'boolean' ? newValue : !value);

    }, [value]),
  ];

};
