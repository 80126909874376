import { createTheme } from '@material-ui/core/styles';
import colors from 'style/colors.module.scss';
import variables from 'style/variables.module.scss';

/**
 * Map styles to a theme to material ui can them
 */
export default createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: variables.fontFamily,
  },
});
