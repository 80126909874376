import {
  brand, ChildSliceMap, SliceWithInitialState, State,
} from 'types';
import {
  ActionReducerMapBuilder, AnyAction, SliceCaseReducers, ValidateSliceCaseReducers, createSlice as createReduxSlice,
} from '@reduxjs/toolkit';
import { logger } from 'classes/logger';

// Better keyof
type Keyof<T> = keyof Pick<T, {
  [K in keyof T]: T[K] extends never ? never : K
}[keyof T]>;

export type InitialState<TState extends State, TName extends string> = Omit<TState, Keyof<ChildSliceMap<TState, TName>> | typeof brand>;

/**
 * Higher order function that returns an action matches with a given base name, and key of the child reducer
 */
const getChildMatcher = (baseName: string, childKey: string) => {

  const searchString = baseName === 'root' ? childKey : `${baseName}/${childKey}`;

  return (action: AnyAction): boolean => {

    return (action.type as string).startsWith(searchString);

  };

};

export const combineChildren = <TState extends State, TName extends string>(
  name: TName,
  childSlices: ChildSliceMap<TState, TName>,
  mergeInitialState?: Omit<TState, keyof ChildSliceMap<TState, TName>>,
): {
  initialState: TState;
  registerChildren: (builder: ActionReducerMapBuilder<TState>) => ActionReducerMapBuilder<TState>;
} => {

  const initialState = mergeInitialState ?? {};

  for (const childKey of Object.keys(childSlices ?? {})) {

    initialState[childKey] = childSlices[childKey].initialState;

  }

  return {
    initialState: initialState as TState,
    registerChildren: (builder) => {

      // This matches actions that start with the name of the store + the key of the child.
      // This makes sure no unnecessary reducer calls are performed
      for (const childKey of Object.keys(childSlices ?? {})) {

        builder.addMatcher(getChildMatcher(name, childKey), (state, action) => {

          try {

            state[childKey] = childSlices[childKey].reducer(state[childKey], action);

          } catch (e) {

            logger.error(`Catched reducer error at ${name}/${childKey}`, {
              error: e,
              state: state[childKey],
              action,
            });

          }

        });

      }

      // Allows for reducer calls to be cascaded down the reducer tree if the action type starts with 'shared'
      builder.addMatcher((action) => action.type.startsWith('shared'), (state, action) => {

        for (const childKey of Object.keys(childSlices ?? {})) {

          state[childKey] = childSlices[childKey].reducer(state[childKey], action);

        }

      });

      return builder;

    },
  };

};

/**
 * Creates a slice which has a property which references the default state.
 * Doesn't support a default case
 */
export const createSlice = <
  TState extends State,
  TName extends string,
  TCR extends SliceCaseReducers<TState> = {}, // eslint-disable-line
  TInitialState extends InitialState<TState, TName> = InitialState<TState, TName>,
  >(options: {
  name: TName;
  initialState?: TInitialState; // Allow
  reducers?: ValidateSliceCaseReducers<TState, TCR>;
  childSlices?: ChildSliceMap<TState, TName>;
  extraReducers?: (builder: ActionReducerMapBuilder<TState>, initialState: TState) => void;
  extraMatchers?: (builder: ActionReducerMapBuilder<TState>) => void;
}): SliceWithInitialState<TState, TCR, TName> => {

  let initialState = (options.initialState ?? {}) as TState;
  let registerChildren: (builder: ActionReducerMapBuilder<TState>) => ActionReducerMapBuilder<TState> = null;

  if (options.childSlices) {

    ({ initialState, registerChildren } = combineChildren<TState, TName>(
      options.name,
      options.childSlices,
      options.initialState,
    ));

  }

  const slice = createReduxSlice<TState, TCR, TName>({
    reducers: options.reducers ?? {} as ValidateSliceCaseReducers<TState, TCR>,
    name: options.name,
    initialState,
    extraReducers: (builder) => {

      options.extraReducers?.(builder, initialState);
      registerChildren?.(builder);

    },
  });

  (slice as SliceWithInitialState<TState, TCR, TName>).initialState = initialState;

  return slice as SliceWithInitialState<TState, TCR, TName>;

};
