import i18n from 'i18n-js';

import { Paths } from '@x-guard/xgac-types/utils';
import en from './locales/en';
import nl from './locales/nl';
import fr from './locales/fr';

i18n.fallbacks = true;
i18n.translations = { en, nl, fr };

// Get all the translation keys
export type TranslationKey = Paths<typeof en, string | {
  other: string;
  one?: string;
  zero?: string;
}>;

export type TranslationFunc = <T extends TranslationKey>(searchString: T, option?: object) => string;

// global translation
// This can be manually imported in ts files, which provides static typechecking again translation keys
export const t: TranslationFunc = (searchString, options = {}) => {

  return i18n.t(searchString, options);

};
