/**
 * @deprecated Use {@link AsyncStatus} instead, as it separates settled state into success and error, and has better
 * ergonomics, in the form of utility methods
 */
import { SerializedError, Slice } from '@reduxjs/toolkit';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

export enum LoadState {
  Idle = 'idle', // No action
  Settling = 'settling', // Usually represents a promise that is currently running
  Settled = 'settled', // Resolved or rejected promised
}

export class AsyncStatus {

  public static Idle = new AsyncStatus('idle');

  public static Pending = new AsyncStatus('pending');

  public static Fulfilled = new AsyncStatus('fulfilled');

  public static Rejected = new AsyncStatus('rejected');

  public code: string;

  private constructor(code: string) {

    this.code = code;

  }

  public get idle(): boolean {

    return this === AsyncStatus.Idle;

  }

  public get pending(): boolean {

    return this === AsyncStatus.Pending;

  }

  public get fulfilled(): boolean {

    return this === AsyncStatus.Fulfilled;

  }

  public get rejected(): boolean {

    return this === AsyncStatus.Rejected;

  }

  public get loadState(): LoadState {

    switch (this.code) {

    case 'idle':
      return LoadState.Idle;
    case 'pending':
      return LoadState.Settling;
    default:
      return LoadState.Settled;

    }

  }

}

export interface AsyncState<T> extends State {
  status: AsyncStatus;
  value: T;
  error: SerializedError;
}

// In pollable state, the 'status' property is pending only on the first request. That's the status variable that will
// be used to display state by views.
export interface AsyncPollableState<T> extends AsyncState<T> {
  isRetrying: boolean;
  realStatus: AsyncStatus;
  controller: AbortController;
}

export type Page<T> = {
  result: T[];
  total: number;
  max: number;
};

export type AbortableThunkArg = { controller: AbortController };

export type PaginatedThunkArg = { paginate: boolean } & AbortableThunkArg;

export interface PaginatedAsyncState<T> extends AsyncPollableState<T[]> {
  total: number;
}

// Marker interface for all State types, emulates nominal typing (C, or Java like) using a symbol that is never passed,
export const brand = Symbol('brand');
export type State = {
  [brand]: never;
};

// Gets all slices of a given state
export type ChildSliceMap<TState extends State, TName extends string> = { [TKey in keyof TState]?:
  TState[TKey] extends State
    ? TKey extends string
      ? TName extends 'root'
      // If base name is the top level root, allow for the child to just use the key as its name
      // this is to allow shorter names like 'details' instead of 'root/alarmsDetails'
        ? SliceWithInitialState<TState[TKey], {}, `${TKey}`> // eslint-disable-line
        : SliceWithInitialState<TState[TKey], {}, `${TName}/${TKey}`> // eslint-disable-line
      : never
    : never
};

export interface SliceWithInitialState<
  TState,
  TCaseReducers extends SliceCaseReducers<TState>,
  TName extends string,
  > extends Slice<TState, TCaseReducers, TName> {
  initialState: TState;
}

export interface PaginationMeta {
  loadedWithNoContent: boolean;
  hasMoreItems: boolean;
}

export type AbsolutePos = { top: number; left: number };
