import React, { FC, useMemo } from 'react';
import { connectedCustomerSelector, connectedCustomerControllerSelector } from 'features/identity/exports';
import { customerContext, CustomerContext } from 'lib';
import { useSelector } from 'react-redux';
import { useAlarmDetailsSubscription } from './useAlarmDetailsSubscription';
import { useAlarmOverview } from './useAlarmOverview';
import { useAlarmOverviewSubscription } from './useAlarmOverviewSubscription';

export const ProvideCustomerContext: FC = ({ children }) => {

  const customer = useSelector(connectedCustomerSelector);
  const customerController = useSelector(connectedCustomerControllerSelector);

  const customerContextValue = useMemo<CustomerContext>(() => ({
    customer,
    signal: customerController.signal,
  }), [customer, customerController]);

  useAlarmOverview(customerContextValue);
  useAlarmOverviewSubscription(customerContextValue);
  useAlarmDetailsSubscription();

  return (
    <customerContext.Provider value={customerContextValue}>
      {children}
    </customerContext.Provider>
  );

};
