import { useMemo } from 'react';

import moment, { Moment } from 'moment';

import { useTimer } from './useTimer';

export type FormatCalendar = {
  sameMinute?: string;
  lastDay?: string;
  sameDay?: string;
  nextDay?: string;
  lastWeek?: string;
  nextWeek?: string;
  sameElse: string;
};

type FormatCalendarKey = keyof FormatCalendar;

// Maps a calendar format key to a function that checks if the given moment applies to the range of the format key:
type DateValidators = Record<FormatCalendarKey, (moment: Moment) => boolean>;

// Produces an object that contains a function for checking if the given moment matches against the datetime-range of
// a calendar format
const dateValidators = (now: Moment): DateValidators => ({
  sameMinute: (m) => m.isSame(now, 'm'),
  lastDay: (m) => m.add(1, 'd').isSame(now, 'd'),
  sameDay: (m) => m.isSame(now, 'd'),
  nextDay: (m) => m.subtract(1, 'd').isSame(now, 'd'),
  lastWeek: (m) => m.weeks() === now.weeks() && m.isBefore(now),
  nextWeek: (m) => m.weeks() === now.weeks() && m.isAfter(now),
  sameElse: () => true, // Base case
});

const produceFormat = (calendar: FormatCalendar, value: Moment): string | null => {

  if (calendar === null) return null;

  // Get validator functions
  const validators = dateValidators(moment());

  // Loop through every format key. If the value passes the calendar check, return the format
  for (const key of Object.keys(calendar) as FormatCalendarKey[]) {

    if (validators[key](moment(value)) ?? false) {

      return calendar[key] as string;

    }

  }

  return null;

};

/**
 * Gets the format for a given calendar. Self-updates every minute, only re-renders when the produced format changed
 */
export const useCalendarFormat = (calendar: FormatCalendar, value: Moment): string | null => {

  const time = useTimer(0, 60000);

  return useMemo(() => {

    if (calendar === null) return null;

    return produceFormat(calendar, value);

    // Time is not used in the hook, but it is used to trigger a re-render every minute

  }, [calendar, value, time]); // eslint-disable-line react-hooks/exhaustive-deps

};
