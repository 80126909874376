import i18n from 'i18n-js';

import {
  PreferencesState, SelectedLocale, supportedBCP47Languages, UsedCustomer,
} from 'types';
import {
  getLocal, setLocal, BCPtoISOLanguage, createSlice, includes, setUserPreferences,
} from 'lib';
import { reject } from 'lodash';
import {
  setSelectedLocale, setMute, removeUsedCustomer, setBlockNotifications, setCustomer,
} from './exports';

const localeSideEffect = ({ iso }: SelectedLocale) => {

  i18n.locale = iso;

};

const getInitialLocale = (): SelectedLocale => {

  // eslint-disable-next-line
  const lang = getLocal('locale', navigator.language || navigator['userLanguage']);
  const bcpLang = includes(supportedBCP47Languages, lang) ? lang : 'en-GB';

  const selectedLocale: SelectedLocale = {
    bcp: bcpLang,
    iso: BCPtoISOLanguage(bcpLang),
  };

  localeSideEffect(selectedLocale);

  return selectedLocale;

};

const name = 'identity/preferences';

export const preferences = createSlice<PreferencesState, typeof name>({
  name,
  initialState: {
    selectedLocale: getInitialLocale(),
    muted: false, // Start muted in development,
    blockNotifications: false,
    userPreferences: getLocal('userPreferences', {}),
  },
  extraReducers: (builder) => builder
    .addCase(setSelectedLocale, (state, action) => {

      const bcpLang = action.payload;
      const locale = {
        bcp: bcpLang,
        iso: BCPtoISOLanguage(bcpLang),
      };

      localeSideEffect(locale);

      setLocal('locale', bcpLang);

      state.selectedLocale = locale;

    })
    .addCase(setMute, (state, action) => {

      state.muted = action.payload;

    })
    .addCase(setBlockNotifications, (state, action) => {

      state.blockNotifications = action.payload;

    })
    .addCase(setCustomer, (state, { payload }) => {

      const usedCustomersWithoutCurrent = reject(
        state.userPreferences[payload.userId]?.usedCustomers ?? [],
        (customer) => customer._id === payload.node._id,
      );

      const selectedCustomer: UsedCustomer = {
        ...payload.node,
        lastUsedAt: new Date().toISOString(),
      };

      // Push the saved customer, and if the new length is longer than 10, remove the first element
      if (usedCustomersWithoutCurrent.push(selectedCustomer) > 10) {

        usedCustomersWithoutCurrent.shift();

      }

      state.userPreferences = setUserPreferences(payload.userId, {
        usedCustomers: usedCustomersWithoutCurrent,
      });

    })
    .addCase(removeUsedCustomer, (state, { payload }) => {

      state.userPreferences = setUserPreferences(payload.userId, {
        usedCustomers: reject(
          state.userPreferences[payload.userId]?.usedCustomers ?? [],
          { _id: payload.node._id },
        ),
      });

    }),
});

export * from './exports';
