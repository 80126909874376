import React, { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { alarmCreatorSelector, alarmPositionSelector, currentPositionSelector } from 'features/details';
import { useFormattedContactCode } from 'lib';
import { Responder } from '@x-guard/xgac-types/xgac';
import type { FormState } from '.';
import styles from './style.module.scss';

type UseSmsBuilder = (formValues: FormState, contact: Responder) => {
  component: FC;
  getSmsBody: () => string;
};

export const useSmsBuilder: UseSmsBuilder = (formValues, contact) => {

  const currentPosition = useSelector(currentPositionSelector);
  const alarmPosition = useSelector(alarmPositionSelector);
  const name = useSelector(alarmCreatorSelector).name;
  const formattedContactCode = useFormattedContactCode(contact.info.contactCode, false);

  return useMemo(() => {

    let output = formValues.message;

    if (formValues.name) {

      output += `\n\n[Name]\n${name}`;

    }

    if (formValues.includeContactCode) {

      output += `\n\n[Contact code]\n${formattedContactCode}`;

    }

    if (formValues.currentPosition) {

      output += `\n\n[Current position]\n${currentPosition.properties.address.formattedAddress}`;

    }

    if (formValues.alarmPosition) {

      output += `\n\n[Alarm position]\n${alarmPosition.properties.address.formattedAddress}`;

    }

    // Trim start and add newlines to add spacing for the timestamp tellu appends to the message
    output = output.trimStart();
    output += '\n\n';

    return {
      component: () => (
        <pre className={styles.smsContainer}>
          {output}
        </pre>
      ),
      getSmsBody: () => output,
    };

  }, [formValues, name, currentPosition, alarmPosition, formattedContactCode]);

};
