import { UserContext } from 'types';

/**
 * Gets the display name for the current user. This simple expression is exported to a function because the format is
 * subject to change, and this allows for easy changes later on
 */
export const getDisplayName = (userData: UserContext): string => {

  return userData.user.name;

};
