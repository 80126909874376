import { useEffect, useState } from 'react';

/**
 * Timer that triggers a rerender every time after a set interval
 * returns the time passed
 */
export const useTimer = (initialTimeMs: number, interval = 1000): number => {

  // Ref containing that amount of milliseconds that have passed
  const [time, setTime] = useState(initialTimeMs);

  // Create a timer with a set interval that updates the amount of time passed
  useEffect(() => {

    const triggerIn = interval - (time % interval);

    const timeoutId = setTimeout(() => {

      setTime(time + triggerIn);

    }, triggerIn);

    return () => {

      clearTimeout(timeoutId);

    };

  }, [time, setTime, interval]);

  return time;

};
