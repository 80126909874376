export const supportedBCP47Languages = ['en-GB', 'nl-NL', 'fr-FR'] as const;
// eslint-disable-next-line
export const supportedISO639_1Languages = ['en', 'nl', 'fr'] as const;

export * from './utils';
export * from './webSocket';
export * from './state';
export * from './xgac';

export type LonLat = [longitude: number, latitude: number];

export type ISOLang = typeof supportedISO639_1Languages[number];
export type BCPLang = typeof supportedBCP47Languages[number];
