import lodash from 'lodash';
import {
  useState, useRef, Ref, CSSProperties, RefObject, useEffect,
} from 'react';
import { AbsolutePos } from 'types';

export type UseDraggableOverlayProps = {
  containerRef: RefObject<HTMLDivElement>;
  offsetPersistence?: {
    get: () => AbsolutePos | null;
    set: (offset: AbsolutePos) => void;
  };
};

export type UseDraggableOverlayOutput = {
  ref: Ref<HTMLDivElement>;
  style: CSSProperties;
};

const getUnscaledDimensions = (element: HTMLElement) => {

  const rect = element.getBoundingClientRect();

  const scaleX = rect.width / element.offsetWidth;
  const scaleY = rect.height / element.offsetHeight;

  return {
    width: rect.width / scaleX,
    height: rect.height / scaleY,
  };

};

export const useDraggableOverlay = ({
  containerRef,
  offsetPersistence,
}: UseDraggableOverlayProps): UseDraggableOverlayOutput => {

  const [overlay, overlayRef] = useState<HTMLDivElement>(null);
  const offsetRef = useRef<AbsolutePos>(offsetPersistence?.get() ?? {
    top: 0,
    left: 0,
  });

  const clampedOffset = useRef<AbsolutePos | null>(null);

  const [style, setStyle] = useState<CSSProperties>({
    top: `${offsetRef.current.top}px`,
    left: `${offsetRef.current.left}px`,
    cursor: 'move',
  });

  const computeStyle = (force = false) => {

    const container = containerRef.current;
    const offset = offsetRef.current;

    if (!container || !offset || !overlay) return;

    requestAnimationFrame(() => {

      const overlayRect = getUnscaledDimensions(overlay);

      const clamp = (value: number, size: number) => Math.max(Math.min(value, size), -size);

      const halfHeight = (container.clientHeight - overlayRect.height) / 2;
      const halfWidth = (container.clientWidth - overlayRect.width) / 2;

      const newClampedOffset = {
        top: clamp(offset.top, halfHeight),
        left: clamp(offset.left, halfWidth),
      };

      if (force || !lodash.isEqual(clampedOffset.current, newClampedOffset)) {

        setStyle({
          top: `${halfHeight - newClampedOffset.top}px`,
          left: `${halfWidth - newClampedOffset.left}px`,
          cursor: 'move',
        });

      }

      clampedOffset.current = newClampedOffset;

    });

  };

  useEffect(() => {

    if (!containerRef.current || !overlay) return undefined;

    computeStyle();

    let mousePos = { x: 0, y: 0 };
    let isDragging = false;

    const handleMouseMove = (e: MouseEvent) => {

      if (!isDragging) return;

      offsetRef.current = {
        top: offsetRef.current.top - (e.clientY - mousePos.y),
        left: offsetRef.current.left - (e.clientX - mousePos.x),
      };

      mousePos = {
        x: e.clientX,
        y: e.clientY,
      };

      computeStyle();

    };

    const handleMouseDown = (e: MouseEvent) => {

      isDragging = true;

      mousePos = {
        x: e.clientX,
        y: e.clientY,
      };

      computeStyle();

    };

    const handleMouseUp = () => {

      offsetPersistence?.set(clampedOffset.current);

      isDragging = false;

    };

    overlay.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {

      overlay.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);

    };

  }, [containerRef, overlay]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!overlay) return undefined;

    const observer = new ResizeObserver(() => {

      computeStyle(true);

    });

    observer.observe(overlay);

    return () => observer.disconnect();

  }, [overlay]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ref: (instance) => {

      overlayRef(instance);
      computeStyle();

    },
    style,
  };

};
