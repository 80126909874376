import cx from 'clsx';

import { useDelayedUnmount } from 'lib';
import styles from './style.module.scss';

export type GetAnimatedOverlayOutput = (options: {
  modal?: boolean;
  className?: string;
  centered?: boolean;
}) => OutputOverlayProps;

export type OutputOverlayProps = {
  className: string;
};

/**
 * Supplies the properties necessary to render an overlay that animates in-and-out
 */
export const useAnimatedOverlay = (active: boolean): {
  shouldRender: boolean;
  overlayProps: GetAnimatedOverlayOutput;
} => {

  return {
    shouldRender: useDelayedUnmount(active, 250),
    overlayProps: ({ modal, className, centered = true }) => {

      return {
        className: cx(
          centered ? styles.animatableCentered : styles.animatableNonCentered,
          active ? styles.animateIn : styles.animateOut,
          modal && styles.modalTransformLess,
          className,
        ),
      };

    },
  };

};
