import { alarmIdSelector, fetchAlarmDetails } from 'features/details';
import { childController, useCustomerContext, useWebSocketContext } from 'lib';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFetchAlarmDetails = (alarmId: string | null) => {

  const dispatch = useDispatch();
  const previousAlarmId = useSelector(alarmIdSelector);
  const { signal, customer } = useCustomerContext();
  const { retryIndex, ws } = useWebSocketContext();

  useEffect(() => {

    // Request new alarm details if previous alarm id is different from the current one
    if (alarmId && previousAlarmId !== alarmId && ws) {

      // Dispatch
      dispatch(fetchAlarmDetails({
        alarmId,
        customerId: customer._id,
        ws,
        controller: childController(signal),
      }));

    }

  }, [dispatch, previousAlarmId, alarmId, signal, retryIndex, ws, customer]);

};
