import React, { FC, useEffect, useMemo } from 'react';

import {
  CheckboxField, ChipInputField, DateTimePickerField,
} from 'components/molecules';
import { FormGroup } from 'components/atoms';
import { OverviewFilters } from 'types';
import { AutoSave, useLocale } from 'lib';
import { FormRenderProps } from 'react-final-form';
import { currentFiltersSelector, filtersSourceSelector } from 'features/overview/alarmFilters';
import { useSelector } from 'react-redux';
import { alarmMaxAgeDaysSelector } from 'features/overview';
import moment from 'moment';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';
import styles from './style.module.scss';

type FormProps = FormRenderProps<OverviewFilters>;

export const Form: FC<FormProps> = ({ form }) => {

  const { t } = useLocale();

  const filters = useSelector(currentFiltersSelector);
  const source = useSelector(filtersSourceSelector);
  const alarmMaxAge = useSelector(alarmMaxAgeDaysSelector);

  // Effect that keeps the form in sync with updates outside the form submissions
  useEffect(() => {

    // If the filters were modified from outside the form, copy over the state to this one
    if (source === 'external') {

      form.mutators.setValues(filters);

    }
    // Form has an unstable identity, so it's omitted to prevent infinite render loops

  }, [filters]); // eslint-disable-line

  const minDate = useMemo(() => {

    return moment()
      .startOf('day')
      .subtract(alarmMaxAge, 'days');

  }, [alarmMaxAge]);

  return (
    <div className={styles.formContainer}>
      <span>{t('Main_Filter_External')}</span>
      <FormGroup>
        <ChipInputField
          contentType={'text'}
          name={'externalId'}
          placeholder={t('Main_Filter_External_Placeholder')}
        />
      </FormGroup>
      <span>{t('Main_Filter_Name')}</span>
      <FormGroup>
        <ChipInputField
          contentType={'text'}
          name={'name'}
          placeholder={t('Main_Filter_Name_Placeholder')}
        />
      </FormGroup>
      <span>{t('Main_Filter_Alarm')}</span>
      <FormGroup>
        <CheckboxField
          name={'severity'}
          value={AlarmSeverity.Red}
          label={t('Main_Filter_Alarm_Red')}
        />
        <CheckboxField
          name={'severity'}
          value={AlarmSeverity.Orange}
          label={t('Main_Filter_Alarm_Orange')}
        />
        <CheckboxField
          name={'severity'}
          value={AlarmSeverity.Green}
          label={t('Main_Filter_Alarm_Green')}
        />
      </FormGroup>
      <span>{t('Main_Filter_Time.Start')}</span>
      <FormGroup>
        <DateTimePickerField
          calendarProps={{ minDate }}
          transform={(value) => value.startOf('day')}
          name={'startDate'}
          maxGranularity={'days'}
        />
      </FormGroup>
      <span>{t('Main_Filter_Time.End')}</span>
      <FormGroup>
        <DateTimePickerField
          transform={(value) => value.endOf('day')}
          name={'endDate'}
          maxGranularity={'days'}
        />
      </FormGroup>
      <AutoSave
        save={form.submit}
        wait={500}
      />
    </div>
  );

};
