import {
  MutableRefObject, useEffect, useMemo, useState,
} from 'react';
import { debounce } from 'lodash';

// Input options and callbacks
export const useScrollObserver = (
  ref: MutableRefObject<HTMLElement>,
  scrollHandler: (ev: Event, e: HTMLElement) => void,
) => {

  // Attach the event handler to the scrollable container
  useEffect(() => {

    const current = ref.current;

    const handler = (ev) => {

      scrollHandler(ev, ref.current);

    };

    if (ref.current) {

      ref.current.addEventListener('scroll', handler);

    }

    return () => current?.removeEventListener('scroll', handler);

  }, [ref, scrollHandler]);

};

/**
 * Observes a scrollable container and triggers callbacks
 */
export const useIsAtDefaultScroll = (ref: MutableRefObject<HTMLElement>, {
  debounceTime = 100,
} = {}) => {

  const [isAtDefaultScroll, setIsAtDefaultScroll] = useState(true);

  // Memoizes a debounced handler for scroll events
  const scrollHandler = useMemo(() => debounce(() => {

    const e = ref.current;
    const isAtDefaultScrollNow = e.scrollTop === 0;
    if (isAtDefaultScrollNow !== isAtDefaultScroll) {

      setIsAtDefaultScroll(isAtDefaultScrollNow);

    }

  }, debounceTime), [isAtDefaultScroll, ref, debounceTime]);

  useScrollObserver(ref, scrollHandler);

  return isAtDefaultScroll;

};
