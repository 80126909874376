import React, {
  FC, useCallback, useRef, useState,
} from 'react';

import { authenticatedSelector } from 'features/identity/auth';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'components/molecules';
import { headerContext, HeaderLocation } from './location/headerContext';
import { UnauthorizedHeader } from './unauthorized';
import { AuthorizedHeader } from './authorized';

export * from './location';

type HeaderProps = {
  hide: boolean;
};

export const Header: FC<HeaderProps> = ({ hide }) => {

  const authenticated = useSelector(authenticatedSelector);

  return authenticated
    ? <AuthorizedHeader hide={hide}/>
    : <UnauthorizedHeader hide={hide}/>;

};

type HeaderProviderProps = {
  hide: boolean;
};

export const HeaderProvider: FC<HeaderProviderProps> = ({ hide, children }) => {

  const ref = useRef([]);
  const [output, setOutput] = useState<HeaderLocation[]>(ref.current);

  const pushLocation = useCallback((location: HeaderLocation) => {

    ref.current = [...ref.current, location];
    setOutput(ref.current);

    return () => {

      ref.current = ref.current.filter((loc) => loc !== location);
      setOutput(ref.current);

    };

  }, [ref]);

  return (
    <headerContext.Provider value={{
      path: output,
      pushLocation,
    }}>
      <ErrorBoundary>
        <Header hide={hide}/>
      </ErrorBoundary>
      {children}
    </headerContext.Provider>
  );

};
