import React, { FC } from 'react';

import cx from 'clsx';

import { range } from 'lodash';
import styles from './style.module.scss';

type LoaderVariant = 'small' | 'medium';

type LoaderTheme = 'light' | 'dark';

type LoaderProps = {
  className?: string;
  variant?: LoaderVariant;
  theme?: LoaderTheme;
};

export const Loader: FC<LoaderProps> = ({
  className,
  variant = 'medium',
  theme = 'dark',
}) => {

  return (
    <div className={cx(
      styles.ldsGrid,
      styles[variant],
      styles[theme],
      className,
    )}>
      {range(variant === 'medium' ? 9 : 3).map((idx) => (
        <div key={idx}/>
      ))}
    </div>
  );

};
