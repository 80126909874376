import React, { FC } from 'react';

import { getDistance } from 'geolib';
import { useSelector } from 'react-redux';
import { currentPositionSelector } from 'features/details';
import { useDistanceLabel } from 'lib';
import { PointGeometry } from '@x-guard/xgac-types/xgac';
import { Label } from '../label';

type DistanceLabelProps = {
  position: PointGeometry;
};

type DisplayDistanceLabelProps = {
  position: PointGeometry;
  currentPosition: PointGeometry;
};

const DisplayDistanceLabel: FC<DisplayDistanceLabelProps> = ({ position, currentPosition }) => {

  const distance = getDistance(position.coordinates, currentPosition.coordinates);
  const label = useDistanceLabel(distance);

  return (
    <Label text={label}/>
  );

};

export const DistanceLabel: FC<DistanceLabelProps> = ({ position }) => {

  const currentPosition = useSelector(currentPositionSelector);

  return (position && currentPosition) ? (
    <DisplayDistanceLabel
      position={position}
      currentPosition={currentPosition}
    />
  ) : null;

};
