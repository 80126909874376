import React, { FC } from 'react';
import { Field } from 'react-final-form';
import styles from './style.module.scss';

type InputAreaFieldProps = {
  name: string;
  placeholder: string;
};

export const TextAreaField: FC<InputAreaFieldProps> = ({
  name,
  placeholder,
}) => {

  return (
    <Field name={name} type={'textarea'}>
      {({ input }) => (
        <textarea
          className={styles.textarea}
          placeholder={placeholder}
          {...input}
        />
      )}
    </Field>
  );

};
