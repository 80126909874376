import { PushMessageOption } from './pushMessage';
import { AutomaticPhoneMessageOption } from './automaticPhoneMessage';
import { PhoneCallModal, PhoneCallOption } from './phoneCall';
import { SmsMessageModal, SmsMessageOption } from './smsMessage';
import { EmailModal, EmailOption } from './email';

import type { ContactOptionsMap } from '.';

export const contactOptionsMap: ContactOptionsMap = {
  pushMessage: {
    enabled: false,
    modalItem: PushMessageOption,
    navigateTo: null,
  },
  automaticPhoneMessage: {
    enabled: false,
    modalItem: AutomaticPhoneMessageOption,
    navigateTo: null,
  },
  phoneCall: {
    enabled: (contact) => {

      return contact.actions.call?.length > 0 ?? false;

    },
    modalItem: PhoneCallOption,
    navigateTo: PhoneCallModal,
    disallowDismissal: true,
  },
  smsMessage: {
    enabled: (contact) => {

      return contact.actions.sms?.length > 0 ?? false;

    },
    modalItem: SmsMessageOption,
    navigateTo: SmsMessageModal,
  },
  email: {
    enabled: (contact) => {

      return contact.actions.email?.length > 0 ?? false;

    },
    modalItem: EmailOption,
    navigateTo: EmailModal,
  },
};
