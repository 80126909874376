import {
  AbortableThunkArg,
  HelpersState, Page, PopulatedAlarmHelper,
} from 'types';
import {
  createSlice,
  withPollablePending,
  withPollableFulfilled,
  withPollableRejected,
  asyncPollableDefaults,
  api,
  createThunk,
  defaultRetryConfig,
  withPollableRetrying,
} from 'lib';
import { addHelper, updateHelper } from 'features/details/helpers/exports';

export * from './exports';

const name = 'details/helpers';

export type FetchHelpersArgs = AbortableThunkArg & {
  alarmId: string;
};

export const fetchAlarmHelpers = createThunk<PopulatedAlarmHelper[], FetchHelpersArgs>({
  name,
  handler: async ({ alarmId, controller: { signal } }) => {

    const { data } = await api<Page<PopulatedAlarmHelper>>(`/alarms/${alarmId}/alarm-helpers`, {
      signal,
      params: {
        $max: 100,
      },
    });

    return data.result;

  },
  retryConfig: defaultRetryConfig,
});

export const helpers = createSlice<HelpersState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    value: [],
  },
  extraReducers: (builder) => builder
    .addCase(fetchAlarmHelpers.pending, withPollablePending)
    .addCase(fetchAlarmHelpers.fulfilled, withPollableFulfilled)
    .addCase(fetchAlarmHelpers.rejected, withPollableRejected)
    .addCase(fetchAlarmHelpers.retrying, withPollableRetrying)
    .addCase(addHelper, (state, action) => {

      state.value = [...state.value, action.payload];

    })
    .addCase(updateHelper, (state, action) => {

      state.value = state.value.map((helper) => {

        return helper._id === action.payload._id ? action.payload : helper;

      });

    }),
});
