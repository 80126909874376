import { AlarmEvent } from '@x-guard/xgac-types/xgac';
import { UserContext } from 'types';

/**
 * Gets whether a message was sent by the current user
 */
export const isOwnMessage = (message: AlarmEvent, userData: UserContext): boolean => {

  return message.user._id === userData.user._id;

};
