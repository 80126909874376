import React, { FC } from 'react';

import { Icon } from '@mdi/react';

import { mdiHome } from '@mdi/js';
import { useBreakpoint, useLocale } from 'lib';
import styles from './style.module.scss';

type TitleProps = {
  backToRoot: () => void;
  forceShowIcon: boolean;
};

export const Title: FC<TitleProps> = ({
  backToRoot,
  forceShowIcon,
}) => {

  const { t } = useLocale();
  const [breakpoint] = useBreakpoint([1440]);

  return (
    <div className={styles.title} onClick={backToRoot}>
      {forceShowIcon || breakpoint === 0 ? (
        <Icon className={styles.homeIcon} path={mdiHome}/>
      ) : (
        t('TopBar_Main')
      )}
    </div>
  );

};
