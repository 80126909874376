import React, { FC } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { mdiAlert, mdiAlertOutline, mdiInformationOutline } from '@mdi/js';
import styles from './style.module.scss';

type AlarmButtonProps = {
  alarmCount: number;
  severity: AlarmSeverity;
  onClick: () => void;
  totalExceedsCount: boolean;
};

type ButtonPresentation = {
  className: string;
  iconPath: string;
};

/**
 * Displays the current amount of alarms for a given severity
 */
export const AlarmButton: FC<AlarmButtonProps> = ({
  alarmCount,
  severity,
  onClick,
  totalExceedsCount,
}) => {

  const getButtonPresentation = (): ButtonPresentation => {

    const map: Record<AlarmSeverity, ButtonPresentation> = {
      [AlarmSeverity.Red]: {
        className: styles.red,
        iconPath: mdiAlert,
      },
      [AlarmSeverity.Orange]: {
        className: styles.orange,
        iconPath: mdiAlertOutline,
      },
      [AlarmSeverity.Green]: {
        className: styles.green,
        iconPath: mdiInformationOutline,
      },
    };

    return map[severity];

  };

  if (alarmCount > 0) {

    const { className, iconPath } = getButtonPresentation();

    return (
      <div
        className={cx(
          styles.alarmButton,
          styles.clickable,
          className,
        )}
        onClick={onClick}
      >
        <Icon path={iconPath}/>
        <span>{alarmCount}{totalExceedsCount ? '+' : ''}</span>
      </div>
    );

  }

  return null;

};
