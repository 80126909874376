import {
  ProcedurePriority, RS, PopulatedAlarm,
} from 'types';
import { createAction, createSelector } from '@reduxjs/toolkit';
import {
  Asset, PointGeometry, Responder, ResponderInfoRef, ResponseGroup,
} from '@x-guard/xgac-types/xgac';
import {
  dynamicResponseStatusSelector,
  dynamicResponseValueSelector,
} from 'features/details/response/dynamic';
import {
  staticResponseStatusSelector,
  staticResponseValueSelector,
} from 'features/details/response/static';

export const setProcedurePriority = createAction<ProcedurePriority>('details/setProcedurePriority');
export const updateCurrentPosition = createAction<PointGeometry>('details/updateCurrentPosition');
export const updateAlarm = createAction<PopulatedAlarm>('details/updateAlarm');
export const updateAlarmAsset = createAction<Asset>('details/updateAlarmAsset');

export const alarmIdSelector = (state: RS) => state.details.alarmId;
export const alarmDetailsStatusSelector = (state: RS) => state.details.status;
export const valueSelector = (state: RS) => state.details.value;
export const alarmDetailsSelector = (state: RS) => state.details.value.alarm;
export const errorSelector = (state: RS) => state.details.error;
export const procedurePrioritySelector = (state: RS) => state.details.preferences.procedurePriority;
export const signalSelector = (state: RS) => state.details.controller?.signal ?? null;
export const alarmPositionSelector = (state: RS) => state.details.value.alarm.position;
export const currentPositionSelector = (state: RS) => state.details.value.alarm.asset.position;
export const alarmCreatorSelector = (state: RS): Asset => state.details.value?.alarm?.asset;
export const alarmCreatorIdSelector = (state: RS) => state.details.value?.alarm?.asset?._id;
export const alarmCenterSelector = (state: RS) => state.details.value.alarm.alarmCenter;
export const frozenInfoSelector = (state: RS) => state.details.value.alarm.meta?.frozenInfo;
export const ackSelector = (state: RS) => state.details.value?.alarm?.ack?.value ?? false;
export const completeMergeHistorySelector = createSelector(
  alarmDetailsSelector,
  (state: RS) => state.details.value.alarm.mergeHistory ?? [],
  (alarm, mergeHistory) => [alarm, ...mergeHistory],
);

export const alarmCreatorResponderSelector = createSelector(
  alarmCreatorSelector,
  (asset): Responder => ({
    info: {
      _id: asset._id,
      _ref: ResponderInfoRef.Asset,
      name: asset.name,
      position: asset.position,
      image: asset.properties?.image,
      locale: asset.properties?.locale,
      contactCode: null,
    },
    distanceKm: 0,
    actions: {
      call: asset.properties?.phoneNumber,
      sms: asset.properties?.smsNumber,
      email: asset.properties?.email,
      notification: asset.app !== null,
    },
    priority: 0,
  }),
);

export const assetIdsInAlarmSelector = createSelector(
  alarmCreatorIdSelector,
  dynamicResponseValueSelector,
  staticResponseValueSelector,
  alarmDetailsStatusSelector,
  dynamicResponseStatusSelector,
  staticResponseStatusSelector,
  (
    alarmCreator,
    dynamicResponse,
    staticResponse,
    alarmDetailsStatus,
    dynamicResponseStatus,
    staticResponseStatus,
  ) => {

    const statuses = [alarmDetailsStatus, dynamicResponseStatus, staticResponseStatus];

    if (statuses.some((status) => !status.fulfilled)) return null;

    const getAssetIds = (response: ResponseGroup[]) => {

      return response
        .flatMap((group) => group.responders)
        .filter((responder) => responder.info._ref === 'Asset')
        .map((responder) => responder.info._id);

    };

    return [
      alarmCreator,
      ...getAssetIds(dynamicResponse),
      ...getAssetIds(staticResponse),
    ];

  },
);

export const allowAlarmCreatorContactSelector = createSelector(
  alarmCreatorSelector,
  (alarmCreator) => alarmCreator.allowContactOnOwnAlarms,
);
