import { useCallback, useEffect, useRef } from 'react';

import { Map } from 'leaflet';
import { useSelector } from 'react-redux';
import { currentPositionSelector } from 'features/details';
import { PointGeometry } from '@x-guard/xgac-types/xgac';
import { useFitBounds } from './useFitBounds';

export const useAutoZoom = (map: Map, autoZoom: boolean, disableAutoZoom: () => void): void => {

  const fitBounds = useFitBounds(map);
  const currentPosition = useSelector(currentPositionSelector);
  const resizingRef = useRef(false);

  const enabled = autoZoom && currentPosition && map !== null;

  // Bind an event handler to the given key, which will disable auto zoom
  const bindDisableAutoZoom = useCallback((event: string) => {

    if (!enabled) return undefined;

    const handler = () => {

      // Don't disable auto zoom if we're currently resizing
      if (resizingRef.current) return;

      disableAutoZoom();

    };

    map.on(event, handler);

    return () => {

      map.off(event, handler);

    };

  }, [map, enabled, disableAutoZoom]);

  useEffect(() => {

    if (!enabled) return undefined;

    const clearZoomStart = bindDisableAutoZoom('zoomstart');
    const clearDragStart = bindDisableAutoZoom('dragstart');

    return () => {

      clearZoomStart();
      clearDragStart();

    };

  }, [map, enabled, bindDisableAutoZoom]);

  // Run effect when ready state is called
  useEffect(() => {

    if (!enabled) return;

    // Collect markers within 500m of the current position
    const points: PointGeometry[] = [];

    /*
    if (dynamicResponseStatus.fulfilled) {

      for (const group of dynamicResponse.value) {

        for (const contact of group.contacts) {

          const distance = getDistance(currentPosition, contact.position);

          if (distance < MAP_AUTOZOOM_BOUNDS_RADIUS) {

            points.push(contact.position);

          }

        }

      }

    }
     */

    // Track when we are resizing the map
    resizingRef.current = true;

    fitBounds([...points, currentPosition].map((p) => p.coordinates));

    // Timeout as the event may be called after we actually fit the bounds
    setTimeout(() => {

      resizingRef.current = false;

    });

  }, [map, fitBounds, enabled, currentPosition]);

};
