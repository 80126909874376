import { createSelector } from '@reduxjs/toolkit';
import { CustomerNode, S } from 'types';
import { userContextSelector } from 'features/identity/auth';
import { usedCustomersSelector } from 'features/identity/preferences';

export const usedCustomersNodeSelector: S<CustomerNode[]> = createSelector(
  userContextSelector,
  usedCustomersSelector,
  (userContext, usedCustomers) => {

    if (!userContext || !usedCustomers) return null;

    return usedCustomers
      .map((customer) => userContext.customerTreeById[customer._id])
      .filter((customer) => !!customer);

  },
);

export const connectedCustomerSelector: S<CustomerNode> = (state) => state.identity.connectedCustomer;
export const connectedCustomerControllerSelector: S<AbortController> = (state) => state.identity.connectedCustomerController;
export const connectedCustomerSignalSelector: S<AbortSignal> = (state) => state.identity.connectedCustomerController?.signal ?? null;
