import React, {
  FC, PropsWithChildren, useMemo, memo, useContext,
} from 'react';
import cx from 'clsx';
import { Icon } from '@mdi/react';
import { mdiCheck, mdiPhone } from '@mdi/js';
import {
  childController, useCustomerContext, useLocale,
} from 'lib';
import { SpinnerCircular } from 'spinners-react';
import colors from 'style/colors.module.scss';
import { Text } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import {
  startProfessionalResponse,
  professionalResponderSelector,
  startResponseStatusSelector, inProgressSelector, cancelResponseStatusSelector, cancelProfessionalResponse,
} from 'features/details/response/professional';
import { AlarmProfessionalResponderStatus as Status } from '@x-guard/xgac-types/xgac';
import { formatNumber, parse } from 'libphonenumber-js';
import { BetterMarkdown } from 'components/molecules';
import styles from './style.module.scss';
import { useAlarmId } from '../../useAlarmId';
import { alarmContext } from '../../alarmContext';

const Details: FC<{ step: number; children: string }> = ({ step, children }) => {

  const { t } = useLocale();

  const stateCell = (index: 0 | 1 | 2) => (
    <div className={styles.stateCellContainer}>
      <div className={cx(
        styles.stateCell,
        step > index && styles.loaded,
        step === index && styles.loading,
        step < index && styles.idle,
      )}>
        {step > index && <Icon path={mdiCheck}/>}
        {step === index && <SpinnerCircular
          thickness={150}
          color={colors.textBlack}
          secondaryColor={colors.textLight}
        />}
        <span>{t(`Alarm_Detail_Response.APR.Step.${index}.${step > index ? 'Done' : 'InProgress'}`)}</span>
      </div>
      {step === index && <div className={styles.loadingTail}/>}
    </div>
  );

  return (
    <div className={styles.details}>
      <div className={styles.state}>
        {stateCell(0)}
        <div className={styles.spacer}/>
        {stateCell(1)}
        <div className={styles.spacer}/>
        {stateCell(2)}
      </div>
      <div className={styles.content}>
        <BetterMarkdown>
          {children}
        </BetterMarkdown>
      </div>
    </div>
  );

};

type InfoButtonKind = 'start' | 'cancel' | 'restart';

type InfoProps = PropsWithChildren<{
  kind: InfoButtonKind;
}>;

const Info: FC<InfoProps> = ({ kind, children }) => {

  const { t } = useLocale();

  const responder = useSelector(professionalResponderSelector);
  const startStatus = useSelector(startResponseStatusSelector);
  const cancelStatus = useSelector(cancelResponseStatusSelector);
  const { onClickAPRAction } = useContext(alarmContext);

  const dispatch = useDispatch();
  const alarmId = useAlarmId();
  const { signal } = useCustomerContext();

  const press = useMemo(() => {

    if (kind === 'cancel' && responder && !cancelStatus.pending) {

      return () => {

        onClickAPRAction({
          type: 'cancel',
          action: () => {

            dispatch(cancelProfessionalResponse({
              controller: childController(signal),
              alarmId,
              aprId: responder._id,
            }));

          },
        });

      };

    }

    if ((kind === 'start' || kind === 'restart') && !startStatus.pending) {

      return () => {

        onClickAPRAction({
          type: kind,
          action: () => {

            dispatch(startProfessionalResponse({
              controller: childController(signal),
              alarmId,
            }));

          },
        });

      };

    }

    return null;

  }, [kind, responder, startStatus, cancelStatus, alarmId, dispatch, signal, onClickAPRAction]);

  return (
    <div className={styles.info}>
      <div className={styles.infoArea}>
        {children}
      </div>
      <div className={styles.chipButtonArea}>
        <div
          className={cx(styles.chipButton, styles[kind], press && styles.clickable)}
          onClick={press}
        >
          <Icon path={mdiPhone}/>
          <Text variant={'span'} theme={'dark'}>
            {t(`Alarm_Detail_Response.APR.${kind}`)}
          </Text>
        </div>
        {kind === 'cancel' && (
          <SpinnerCircular
            thickness={150}
            color={colors.textBlack}
            secondaryColor={colors.textLight}
            style={{ width: 24 }}
          />
        )}
      </div>
    </div>
  );

};

export const ProfessionalResponder = memo(() => {

  const { t } = useLocale();
  const responder = useSelector(professionalResponderSelector);
  const inProgress = useSelector(inProgressSelector);

  if (!responder) {

    return <>
      <Info kind={'start'}>
        {inProgress ? <>
          <span className={styles.bold}>{t('Alarm_Detail_Response.APR.Info.InProgress.Main')}</span>
        </> : <>
          <span>{t('Alarm_Detail_Response.APR.Info.Ready.Main')}</span>
          <span className={styles.bold}>{t('Alarm_Detail_Response.APR.Info.Ready.Sub')}</span>
        </>}
      </Info>
      <div className={styles.sub}>
        <a href={'https://x-guard.nl'} className={styles.anchor}>
          {t('Alarm_Detail_Response.APR.Explanation')}
        </a>
      </div>
    </>;

  }

  if (responder.status === Status.Rejected) {

    return <>
      <Info kind={'cancel'}>
        <span>{t('Alarm_Detail_Response.APR.Info.Rejected', { name: responder.staticResponder.name })}</span>
      </Info>
      <div className={styles.sub}>
        <span className={styles.bold}>{t('Alarm_Detail_Response.APR.FindingReplacement')}</span>
      </div>
    </>;

  }

  const phoneNumber = responder.meta.overridePhoneNumber ?? responder.staticResponder.contact.phone;
  const infoStatus = responder.status === Status.Invited ? 'Finding' : 'Found';

  return <>
    <Info kind={responder.status === Status.Finished ? 'restart' : 'cancel'}>
      <span>{t(`Alarm_Detail_Response.APR.Info.${infoStatus}`)}</span>
      <span className={styles.bold}>{responder.staticResponder.name}</span>
    </Info>
    {responder.status === Status.Invited && (
      <Details step={0}>
        {t('Alarm_Detail_Response.APR.Invited')}
      </Details>
    )}
    {responder.status === Status.Accepted && (
      <Details step={1}>
        {t('Alarm_Detail_Response.APR.Accepted', {
          phoneNumber,
          formattedPhoneNumber: formatNumber(parse(phoneNumber), 'NATIONAL'),
        })}
      </Details>
    )}
    {responder.status === Status.OnLocation && (
      <Details step={2}>
        {t('Alarm_Detail_Response.APR.OnLocation', {
          phoneNumber,
          formattedPhoneNumber: formatNumber(parse(phoneNumber), 'NATIONAL'),
        })}
      </Details>
    )}
    {responder.status === Status.Finished && (
      <Details step={3}>
        {t('Alarm_Detail_Response.APR.Finished')}
      </Details>
    )}
  </>;

});
