export const withPollingHelper = (
  signal: AbortSignal,
  interval: number,
  handler: () => void,
): () => void => {

  let id = window.setInterval(handler, interval);

  signal.addEventListener('abort', () => window.clearInterval(id));

  // Return a function that manually dispatches the handler and resets the interval
  return () => {

    window.clearInterval(id);
    id = window.setInterval(handler, interval) as number;
    handler();

  };

};
