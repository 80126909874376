import { createContext, useContext } from 'react';
import { noop } from '../helpers';

export type ImageOverlayContext = {
  setSrc: (src: string) => void;
};

export const imageOverlayContext = createContext<ImageOverlayContext>({
  setSrc: noop,
});

export const useImageOverlayContext = (): ImageOverlayContext => {

  return useContext(imageOverlayContext);

};
