import React, { FC, useCallback, useContext } from 'react';

import { useAnimatedOverlay } from 'components/molecules';
import { childController, useCustomerContext, useLocale } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { alarmIdSelector } from 'features/details';
import { SurveyState } from 'types';
import { acknowledge } from 'features/details/ack';
import { userContextSelector } from 'features/identity/auth';
import { Survey } from './survey';
import { alarmContext } from '../../alarmContext';
import { Shadow } from '../../shadow';
import { getSurveyMessageBody } from '../../getSurveyMessageBody';

/**
 * Handles optional rendering and submission of the acknowledgement survey
 */
export const AcknowledgementSurvey: FC = () => {

  const {
    onDismissAckForm: onDismiss,
    enableAckForm: enabled,
  } = useContext(alarmContext);

  const { t } = useLocale();
  const { shouldRender, overlayProps } = useAnimatedOverlay(enabled);
  const alarmId = useSelector(alarmIdSelector);
  const userContext = useSelector(userContextSelector);
  const dispatch = useDispatch();
  const { signal } = useCustomerContext();

  const sendAcknowledgement = useCallback((values: SurveyState) => {

    onDismiss();

    const comment = getSurveyMessageBody(values, t);

    dispatch(acknowledge({
      controller: childController(signal),
      userContext,
      comment,
      alarmId,
    }));

  }, [dispatch, alarmId, onDismiss, t, userContext, signal]);

  return (
    <>
      <Shadow enabled={enabled}/>
      {shouldRender && (
        <Survey
          overlayProps={overlayProps}
          onDismiss={onDismiss}
          onSubmit={sendAcknowledgement}
        />
      )}
    </>
  );

};
