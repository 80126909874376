import { useCallback, useState } from 'react';

type UseFunctionState = <T = () => void>(initialState?: T) => [T, (value: T) => void];

/**
 * useState wrapper for storing functions in state.
 * Works by wrapping the function in a simple object
 */
export const useFunctionState: UseFunctionState = <T>(initialState = null) => {

  const [wrappedValue, setState] = useState<{ value: T }>({ value: initialState });

  return [
    wrappedValue.value,
    useCallback((value: T) => {

      setState({ value });

    }, []),
  ];

};
