import { api, CustomerContext, useWebSocketContext } from 'lib';
import { useDispatch } from 'react-redux';
import { PopulatedAlarm } from 'types';
import { addOpenAlarm, updateAlarm } from 'features/overview';
import { useEffect } from 'react';
import { IncomingEntityMessage } from '@x-guard/xgac-ts-ws-client';

export const useAlarmOverviewSubscription = ({ signal, customer }: CustomerContext) => {

  const { ws } = useWebSocketContext();
  const dispatch = useDispatch();

  useEffect(() => {

    const onUpdate = async (message: IncomingEntityMessage<'Alarm', 'update'>) => {

      dispatch(updateAlarm(message.document as PopulatedAlarm));

    };

    // Handle new alarms by appending them to the state, and subscribing to updates
    const onCreate = async (message: IncomingEntityMessage<'Alarm', 'create'>) => {

      const { data } = await api.get<PopulatedAlarm>(`/alarms/${message.document._id}`);

      if (signal.aborted) return;

      dispatch(addOpenAlarm(data));

      ws.subscribe('Alarm', {
        signal,
        events: ['update'],
        id: [message.document._id],
        handler: onUpdate,
      });

    };

    return ws?.subscribe('Alarm', {
      ...(customer.isAlarmCenter ? {
        alarmCenter: customer._id,
      } : {
        customers: [customer._id],
      }),
      events: ['create', 'update'],
      filter: {
        'ack.value': false,
      },
      handler: {
        Alarm: {
          create: onCreate,
          update: onUpdate,
        },
      },
    });

  }, [customer, signal, ws, dispatch]);

};
