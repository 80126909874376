import 'core-js/actual';
import React from 'react';
import './index.scss';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import ReactDOM from 'react-dom';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import { App } from './app';

// start react app
ReactDOM.render(
  <App/>,
  document.getElementById('root'),
);
