import React, {
  FC, MutableRefObject, useCallback, useLayoutEffect, useMemo, useRef, useState,
} from 'react';

import cx from 'clsx';
import FlatList from 'flatlist-react';

import { Chip, BetterMoment, Loader } from 'components';
import {
  useLocale, UsePaginatedStateReturn, useScrollObserver,
} from 'lib';
import {
  AnyDisplayableMessageProps, MessageImageMode, UserContext,
} from 'types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AlarmEventType } from '@x-guard/xgac-types/xgac';
import { ChatInputField } from './input';
import { ControlledMessage } from './message';
import styles from './style.module.scss';
import { ackSelector } from '../../../../../../features/details/ack/exports';

const renderMessage = (message: AnyDisplayableMessageProps) => {

  if (message.content.type === AlarmEventType.Action && message.content.action === 'ACTION_ALARM_ACKNOWLEDGE') {

    return null;

  }

  return (
    <ControlledMessage
      key={message.content._id}
      {...message}
    />
  );

};

type MessagesProps = UsePaginatedStateReturn<AnyDisplayableMessageProps> & {
  sendMessage: (text: string) => void;
  userContext: UserContext;
};

export const useSetInitialScrollTop = (ulRef: MutableRefObject<HTMLUListElement>) => {

  const stateRef = useRef({
    startedScrolling: false,
  });

  useScrollObserver(ulRef, () => {

    stateRef.current.startedScrolling = true;

  });

  useLayoutEffect(() => {

    if (!stateRef.current.startedScrolling) {

      ulRef.current.scrollTop = 0;

    }

  });

};

export const useOnAlmostReachedTop = (ref: MutableRefObject<HTMLElement>, onReachTop: () => void) => {

  const scrollHeightOffset = 80;
  const stateRef = useRef({
    predicateLastValue: true,
  });

  useScrollObserver(ref, (ev, e) => {

    const value = e.scrollHeight - scrollHeightOffset <= -(e.scrollTop - e.offsetHeight);

    if (value && !stateRef.current.predicateLastValue) {

      onReachTop();

    }

    stateRef.current.predicateLastValue = value;

  });

};

// TODO: Reintroduce full 'new message warning' functionality
export const Messages: FC<MessagesProps> = ({
  meta,
  status,
  realStatus,
  value,
  sendMessage,
  paginate,
}) => {

  // Obtain ref from scroll container
  const ulRef = useRef<HTMLUListElement>();
  const ack = useSelector(ackSelector);

  const ackComment = ack.comment;
  const ackDate = ack.ackedAt && moment(ack.ackedAt);
  const acked = ack.value;

  const { t } = useLocale();

  const calendar = useMemo(() => ({
    lastDay: `[${t('Dates.Yesterday')}] LT`,
    sameDay: 'LT',
    nextDay: `[${t('Dates.Tomorrow')}] LT`,
    lastWeek: `[${t('Dates.Last')}] dddd [${t('Dates.At')}] LT`,
    nextWeek: `dddd [${t('Dates.At')}] LT`,
    sameElse: 'DD/MM/YYYY LT',
  }), [t]);

  // Warning visibility and warning click handler
  const [warningCount, setWarningCount] = useState<number>(0);
  const scrollToBottom = useCallback(() => {

    ulRef.current.scrollTop = 0;
    setWarningCount(0);

  }, [ulRef, setWarningCount]);

  // on submit handler
  const onSubmit = useMemo(() => {

    if (sendMessage === null) {

      return null;

    }

    return (message: string) => {

      ulRef.current.scrollTop = 0;
      sendMessage(message);

    };

  }, [ulRef, sendMessage]);

  useSetInitialScrollTop(ulRef);
  useOnAlmostReachedTop(ulRef, paginate.loadMore);

  return (
    <div className={styles.messagesWrapper}>
      <ul
        ref={ulRef}
        className={cx(
          styles.messagesContainer,
          (meta.centerContent && !acked) && styles.center,
          (meta.loadedWithNoContent && !acked) && styles.loadedWithNoContent,
          meta.isInitiallyLoading && styles.isInitiallyLoading,
        )}
      >
        {acked && (
          <>
            <div className={cx(
              styles.alarmAcknowledged,
              ackComment === null && styles.noComment,
            )}>
              {ackComment === null && (
                <div className={styles.titleRow}>
                  <span className={styles.title}>{ack.ackedBy?.name ?? 'X-Guard'}</span>
                </div>
              )}
              <div className={styles.content}>
                <span>{t('Alarm_Acknowledged')}</span>
                {ackDate && (
                  <BetterMoment calendar={calendar}>{ackDate.local()}</BetterMoment>
                )}
              </div>
            </div>
            {status.fulfilled && ackComment !== null && (
              <ControlledMessage
                content={{
                  _id: 'acknowledgement',
                  type: AlarmEventType.Action,
                  action: 'ACTION_ALARM_ACKNOWLEDGE',
                  user: ack.ackedBy ?? {
                    _id: 'xguard',
                    _ref: 'User',
                    name: 'X-Guard',
                  },
                  text: ackComment ?? null,
                  createdAt: ack.ackedAt,
                  updatedAt: null,
                } as any}
                imageMode={MessageImageMode.None}
                isFirstInDay={true}
                joinWithTop={false}
                side={'center'}
              />
            )}
          </>
        )}
        {(meta.loadedWithNoContent && !acked) ? (/* Render when we have finished loading, and no messages were found */
          <div className={styles.noMessage}>
            {t('Alarm_Detail_Log_No_Messages')}
          </div>
        ) : (!meta.isInitiallyLoading && value.length > 0) && (/* Render if there are any messages at all */
          <FlatList list={value} renderItem={renderMessage}/>
        )}
        {realStatus.pending && (
          <div className={styles.scrollingLoader}>
            <Loader variant={'medium'} />
          </div>
        )}
        {/* Items are rendered from last to first, so but spinner here at the bottom.
            This makes it so when the user scrolls to the top, pagination starts doing its thing,
            and the loader is revealed */}
        {status.pending && (
          <div className={styles.loader}>
            <Loader variant={'small'}/>
            <span>{meta.isInitiallyLoading
              ? t('Alarm_Detail_Log_LoadingMessagesStatus')
              : t('Alarm_Detail_Log_LoadingMoreMessagesStatus')
            }</span>
          </div>
        )}
      </ul>
      <div className={styles.scrollToBottomOverlay}>
        {warningCount > 0 && (
          <Chip
            className={styles.scrollToBottom}
            size={'medium'}
            label={warningCount === 1
              ? t('Alarm_Detail_Log_NewMessagesCallToAction.one')
              : t('Alarm_Detail_Log_NewMessagesCallToAction.other')}
            variant={'warning100'}
            onClick={scrollToBottom}
          />
        )}
      </div>
      {status.fulfilled && (
        <div className={styles.inputOverlay}>
          <ChatInputField onSubmit={onSubmit}/>
        </div>
      )}
    </div>
  );

};
