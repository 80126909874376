import colors from 'style/colors.module.scss';

import { AlarmSeverity, Alarm } from '@x-guard/xgac-types/xgac';
import { mdiAlert, mdiAlertOutline, mdiInformationOutline } from '@mdi/js';
import { useMemo } from 'react';

type SeverityAssets = {
  label: string;
  path: string;
  className: string;
};

const assetsBySeverity: Record<AlarmSeverity, Omit<SeverityAssets, 'label'>> = {
  [AlarmSeverity.Red]: {
    path: mdiAlert,
    className: colors.logLevelRed,
  },
  [AlarmSeverity.Orange]: {
    path: mdiAlertOutline,
    className: colors.logLevelOrange,
  },
  [AlarmSeverity.Green]: {
    path: mdiInformationOutline,
    className: colors.logLevelGreen,
  },
};

export const useSeverityAssets = (alarm: Alarm): SeverityAssets => {

  return useMemo(() => ({
    ...assetsBySeverity[alarm.severity],
    label: alarm.name,
  }), [alarm]);

};
