import React, { useEffect } from 'react';
import { useSearch, useCustomerId } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'features/identity/auth/login';
import { statusSelector } from 'features/identity/auth';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/atoms';
import { useRedirectUri } from './useLoginHRef';
import styles from './style.module.scss';

export const AuthCallback = () => {

  const search = useSearch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectUri = useRedirectUri();
  const preferredCustomerId = useCustomerId();

  const status = useSelector(statusSelector);

  useEffect(() => {

    if (status.fulfilled) {

      navigate('/', { replace: true });
      return;

    }

    if (status.idle) {

      dispatch(login({
        controller: new AbortController(),
        type: 'fusionAuthRedirectLogin',
        preferredCustomerId,
        search: {
          code: search.code as string,
          redirectUri,
        },
      }));

    }

  }, [search, status, preferredCustomerId]); // eslint-disable-line

  return (
    <div className={styles.container}>
      {status.rejected ? (
        <div>Failed to login</div>
      ) : (
        <div className={styles.loaderContainer}>
          {'Logging in ...'}
          <Loader className={styles.loader}/>
        </div>
      )}
    </div>
  );

};
