import React, { memo, useContext } from 'react';

import { Icon } from '@mdi/react';

import { mdiCardAccountPhone, mdiPhone } from '@mdi/js';
import { Chip, Text } from 'components';
import { useLocale } from 'lib';
import { ackedSelector } from 'features/details/ack/exports';
import { useSelector } from 'react-redux';
import { ContactType } from 'types';
import styles from './style.module.scss';
import { OverlayContext } from '../../../../../molecules/overlay/overlayContext';

export type ContactChipProps = {
  onClick: (contactType: ContactType) => void;
  contactType: ContactType;
};

/**
 * Button to contact a static response or asset
 */
export const ContactChip = memo<ContactChipProps>(({ onClick, contactType }) => {

  const { t } = useLocale();

  const acked = useSelector(ackedSelector);
  const { overlayCount } = useContext(OverlayContext);

  const disabled = acked || overlayCount > 0;

  return (
    <Chip
      variant={'primary'}
      size={'medium'}
      flex={true}
      className={styles.chip}
      disabled={disabled}
      onClick={(e) => {

        e.stopPropagation();

        onClick(contactType);

      }}
      label={(
        <>
          {contactType === null && (
            <>
              <Icon path={mdiCardAccountPhone}/>
              <Text variant={'span'} theme={'dark'}>
                {t('Alarm_Detail_Response.Contact.Label')}
              </Text>
            </>
          )}
          {contactType === 'phoneCall' && (
            <>
              <Icon path={mdiPhone}/>
              <Text variant={'span'} theme={'dark'}>
                {t('Alarm_Detail_Response.Contact.Call.Label')}
              </Text>
            </>
          )}
        </>
      )}
    />
  );

});
