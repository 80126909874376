import React, { FC, ReactNode } from 'react';

import { Icon } from '@mdi/react';

import { Form } from 'react-final-form';
import {
  useLocale, AutoSave, UseAsyncReturn,
} from 'lib';
import {
  DropdownControl,
  DropdownControlValueMeta,
  TextAreaField,
  ButtonArea,
  Chip,
  Text,
  CheckboxField,
} from 'components';
import type { ContactOptionImplProps } from '..';
import styles from './style.module.scss';

export * from './useTemplates';

type BuilderFormProps<T extends BaseFormState> = ContactOptionImplProps & {
  title: ReactNode;
  titleIcon: string;
  subtitle: ReactNode;
  templates?: DropdownData;
  send: UseAsyncReturn<() => Promise<unknown>>;
  dataFields: ReactNode;
  formValues: T;
  onSubmit: (values: T) => void;
  outputTitle: string;
};

export type MessageTemplate<T extends BaseFormState> = {
  label: string;
  value: {
    formState?: Partial<T>;
    message: string;
  };
};

export type BaseFormState = {
  includeContactCode: boolean;
  message: string;
};

export type DropdownData = {
  values: string[];
  getMeta: (key: string) => DropdownControlValueMeta;
  getValue: (value: string) => string;
};

export const initialFormState: BaseFormState = {
  includeContactCode: false,
  message: '',
};

const mutators = {
  setMessage: ([{ message, formState }]: [MessageTemplate<BaseFormState>['value']], state, tools) => {

    if (formState) {

      for (const key of Object.keys(formState)) {

        tools.changeValue(state, key, () => formState[key]);

      }

    }

    tools.changeValue(state, 'message', () => message);

  },
};

/**
 * Common form structure for a contact form. Used by email and sms contact forms.
 */
// Component props cannot be typed
// eslint-disable-next-line
export const BuilderForm: FC<BuilderFormProps<any>> = ({
  responder,
  title,
  titleIcon,
  subtitle,
  templates,
  send,
  children,
  formValues,
  onSubmit,
  outputTitle,
  dataFields,
}) => {

  const { t } = useLocale();

  return (
    <>
      <div className={styles.builderForm}>
        <div className={styles.left}>
          <Form
            mutators={mutators}
            onSubmit={(values) => {

              onSubmit({
                message: '', // provide default value for message, as when it's empty, react-final-form makes it undefined, which we don't want
                ...values,
              });

            }}
            initialValues={formValues}
            render={({ form }) => (
              <>
                <section>
                  <Text variant={'h6'}>
                    <Icon className={styles.titleIcon} path={titleIcon}/>
                    {title}
                  </Text>
                  <Text variant={'contentSpan'}>{subtitle}</Text>
                </section>
                <div className={styles.horizontalRule}/>
                <section>
                  <Text variant={'titleSpan'}>{t('Alarm_Detail_Contact.Builder_Form.Add_Data.Title')}</Text>
                  <div className={styles.dataFields}>
                    {responder.info.contactCode && (
                      <CheckboxField
                        name={'includeContactCode'}
                        label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Include_Contact_Code')}
                        size={'small'}
                      />
                    )}
                    {dataFields}
                    <AutoSave save={form.submit} wait={100}/>
                  </div>
                </section>
                <div className={styles.horizontalRule}/>
                <section>
                  <Text variant={'titleSpan'}>{t('Alarm_Detail_Contact.Builder_Form.Message.Title')}</Text>
                  <Text variant={'contentSpan'}>{t('Alarm_Detail_Contact.Builder_Form.Message.Subtitle')}</Text>
                  <TextAreaField
                    name={'message'}
                    placeholder={t('Alarm_Detail_Contact.Builder_Form.Message.Placeholder')}
                  />
                  {templates && (
                    <DropdownControl
                      getMeta={templates.getMeta}
                      value={null}
                      values={templates.values}
                      onSelectValue={(key: string) => {

                        form.mutators.setMessage(templates.getValue(key));
                        form.submit();

                      }}
                      label={t('Alarm_Detail_Contact.Builder_Form.Message.Choose_Template')}
                    />
                  )}
                </section>
              </>
            )}
          />
        </div>
        <div className={styles.verticalRule}/>
        <div className={styles.right}>
          <Text variant={'titleSpan'}>{outputTitle}</Text>
          <div className={styles.outputPreview}>
            {children}
          </div>
        </div>
      </div>
      <ButtonArea>
        <Chip
          size={'large'}
          variant={'primary'}
          label={t('Alarm_Detail_Contact.Builder_Form.Send')}
          onClick={send.execute}
          disabled={!send.status.idle}
        />
      </ButtonArea>
    </>
  );

};
