import React, { FC } from 'react';

import { useLocale } from 'lib';
import { HELPDESK_HREF } from 'config';
import { Form } from './form';
import styles from './style.module.scss';

export const LoginBox: FC = () => {

  const { t } = useLocale();

  return (
    <div className={styles.sub}>
      <div className={styles.loginBox}>
        <div className={styles.loginBoxHeader}>
          <span className={styles.text}>{t('Login_Login')}</span>
        </div>
        {/* TODO: Add error message
        {message && message in messageTranslationMap && (
          <div className={styles.message}>
            {t(messageTranslationMap[message])}
          </div>
        )}
        */}
        <div className={styles.loginBoxContent}>
          <div className={styles.text}>{t('Login_Login_SubText')}</div>
          <Form/>
        </div>
      </div>
      <div className={styles.helpDeskText}>
        {t('Login_Helpdesk_1')}<br />
        <a
          href={HELPDESK_HREF}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {t('Login_Helpdesk_2')}
        </a>
      </div>
    </div>
  );

};
