import {
  AbortableThunkArg,
  AsyncPollableState,
  AsyncStatus, Page,
  PaginatedAsyncState,
  PaginatedThunkArg,
  PaginationMeta, RS,
} from 'types';
import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkPendingActionCreator, AsyncThunkRejectedActionCreator,
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import { logger } from 'classes/logger';
import { RetryingPayloadCreator } from 'lib/helpers/redux/createThunk';
import { isAbortError } from 'lib/helpers/redux/index';

export const withPollablePending = <T>(
  state: Draft<AsyncPollableState<T>>,
  action: ReturnType<AsyncThunkPendingActionCreator<AbortableThunkArg>>,
): void => {

  if (state.status.idle) {

    state.status = AsyncStatus.Pending;

  }

  state.realStatus = AsyncStatus.Pending;
  state.isRetrying = false;
  state.controller?.abort();
  state.controller = action.meta.arg.controller;

};

export const withPollableFulfilled = <TValue, TResult = TValue>(
  state: Draft<AsyncPollableState<TValue>>,
  action: ReturnType<AsyncThunkFulfilledActionCreator<TResult, AbortableThunkArg>>,
  onResult: (value: TResult) => void = null,
): void => {

  // Thunk was aborted, so do nothing
  if (action.meta.arg.controller.signal.aborted) {

    return;

  }

  // Change statuses
  state.status = AsyncStatus.Fulfilled;
  state.realStatus = AsyncStatus.Fulfilled;
  state.isRetrying = false;

  // Check if a user provided callback is present, otherwise just mutate the state value
  if (onResult === null) {

    state.value = action.payload as unknown as Draft<TValue>;

  } else {

    onResult(action.payload);

  }

};

export const withPollableRejected = <T>(
  state: Draft<AsyncPollableState<T>>,
  action: ReturnType<AsyncThunkRejectedActionCreator<unknown>>,
): void => {

  // Always ignore abort errors
  if (isAbortError(action.error)) return;

  state.controller?.abort();
  state.status = AsyncStatus.Rejected;
  state.realStatus = AsyncStatus.Rejected;
  state.error = action.error;
  state.isRetrying = false;

  logger.warn('Captured error action in withPollableRejected', action);

};

export const withPollableRetrying = <T>(
  state: Draft<AsyncPollableState<T>>,
  action: ReturnType<RetryingPayloadCreator<unknown>>,
): void => {

  state.status = AsyncStatus.Rejected;
  state.realStatus = AsyncStatus.Rejected;
  state.error = action.payload.error;
  state.isRetrying = true;

};

export const withPaginatedPending = <TValue>(
  state: Draft<AsyncPollableState<TValue[]>>,
  action: ReturnType<AsyncThunkPendingActionCreator<PaginatedThunkArg>>,
): void => {

  withPollablePending<TValue[]>(state, action);

  // When we aren't paginating, reset status
  if (action.meta.arg.paginate === false) {

    state.status = AsyncStatus.Pending;

  }

};

export const withPaginatedFulfilled = <TValue, TResult extends Page<TValue> = Page<TValue>>(
  state: Draft<PaginatedAsyncState<TValue>>,
  action: ReturnType<AsyncThunkFulfilledActionCreator<TResult, PaginatedThunkArg>>,
): void => {

  withPollableFulfilled<TValue[], TResult>(state, action, (payload) => {

    state.total = action.payload.total;

    // If we are paginating, append the result to the existing value, otherwise write over the stale value
    if (action.meta.arg.paginate) {

      state.value = [...state.value, ...payload.result] as Draft<TValue[]>;

    } else {

      state.value = payload.result as Draft<TValue[]>;

    }

  });

};

export const withPagination = <
  TValue,
  TResult extends Page<TValue> = Page<TValue>,
  TState extends PaginatedAsyncState<TValue> = PaginatedAsyncState<TValue>,
  >(
    builder: ActionReducerMapBuilder<TState>,
    thunk: AsyncThunk<TResult, PaginatedThunkArg, { state: RS }>,
  ): ActionReducerMapBuilder<TState> => builder
    .addCase(thunk.pending, (state, action) => {

      withPaginatedPending<TValue>(state, action);

    })
    .addCase(thunk.fulfilled, (state, action) => {

      withPaginatedFulfilled<TValue, TResult>(state, action);

    })
    .addCase(thunk.rejected, (state, action) => {

      withPollableRejected<TValue[]>(state, action);

    });

export const computePaginationMeta = (state: PaginatedAsyncState<unknown>): PaginationMeta => {

  const hasMoreItems = state.status.idle || state.value.length < state.total;

  return {
    hasMoreItems,
    loadedWithNoContent: state.status.fulfilled && state.value.length === 0,
  };

};
