import React, { FC, ReactNode } from 'react';
import styles from './style.module.scss';

type GridProps = {
  title: ReactNode;
};

const Grid: FC<GridProps> = (props) => {

  const {
    title,
    children,
  } = props;

  return (
    <div className={styles.gridContainer}>
      <span className={styles.title}>{title}</span>
      <div className={styles.grid}>
        {children}
      </div>
    </div>
  );

};

export default Grid;
