import React from 'react';
import styles from 'components/organisms/header/style.module.scss';
import { setFilter } from 'features/overview';
import { defaultFilters } from 'features/overview/alarmFilters';
import { AlarmButton } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { useNavigate } from 'react-router-dom';
import { openAlarmsStateSelector } from 'features/overview/openAlarms';

export const AlarmButtons = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { value, total } = useSelector(openAlarmsStateSelector);

  if (!value) return null;

  const alarmIdsBySeverity = value.reduce<{ [K in AlarmSeverity]?: string[] }>((acc, alarm) => {

    acc[alarm.severity] ??= [];
    acc[alarm.severity].push(alarm._id);
    return acc;

  }, {});

  const button = (severity: AlarmSeverity) => {

    const alarms = alarmIdsBySeverity[severity] ?? [];
    const alarmCount = alarms.length;

    const onClick = () => {

      if (alarms.length > 1) {

        navigate('/alarms');

        dispatch(setFilter({
          source: 'external',
          filter: {
            ...defaultFilters,
            severity: [severity],
          },
        }));

      } else {

        navigate(`/alarms/${alarms[0]}`);

      }

    };

    return (
      <AlarmButton
        totalExceedsCount={total > value.length}
        alarmCount={alarmCount}
        key={severity}
        onClick={onClick}
        severity={severity}
      />
    );

  };

  return (
    <div className={styles.alarmButtons}>
      {button(AlarmSeverity.Red)}
      {button(AlarmSeverity.Orange)}
      {button(AlarmSeverity.Green)}
    </div>

  );

};
