import React, { FC, ReactNode } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type SectionProps = {
  title: ReactNode;
  className?: string;
};

/**
 * A single section that is comprised by a header, and content
 */
export const Section: FC<SectionProps> = ({
  title,
  children,
  className,
}) => {

  return (
    <section className={cx(
      styles.section,
      className,
    )}>
      <div className={styles.title}>
        {title}
      </div>
      {children}
    </section>
  );

};
