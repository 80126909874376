import {
  MutableRefObject, SyntheticEvent, useEffect, useState,
} from 'react';
import { ResizeCallbackData } from 'react-resizable';
import {
  getLocal, setLocal, useMeasure, UseMeasureRect,
} from 'lib';
import { clone, isEqual } from 'lodash';

type OnResize = (e: SyntheticEvent, data: ResizeCallbackData) => void;

type Size = { width: number; height: number };

// Defines the relative min and max ratios of width the map should have compared to parent
const [min, max] = [0.3, 0.75];

// Returns a clamped size using the given rect
// Return has a stable identity if no changes were made when clamping
const clampSize = (size: Size, rect: UseMeasureRect): Size => {

  if (!size || !rect) {

    return size;

  }

  const sizeCopy = clone(size);

  if (size.width > rect.width * max) {

    sizeCopy.width = rect.width * max;

  }

  if (size.width < rect.width * min) {

    sizeCopy.width = rect.width * min;

  }

  return isEqual(size, sizeCopy) ? size : sizeCopy;

};

export const useResize = (parentItemRef: MutableRefObject<HTMLDivElement>, afterResize: (size: Size) => void): [Size, OnResize] => {

  const [size, setSizeState] = useState<Size>({
    width: getLocal('mapResizeRatio', 0.5) * window.innerWidth,
    height: 0,
  });

  const setSize = (newSize: Size) => {

    setLocal('mapResizeRatio', newSize.width / window.innerWidth);

    setSizeState(newSize);
    afterResize(newSize);

  };

  const [rect] = useMeasure(parentItemRef);

  useEffect(() => {

    const clampedSize = clampSize(size, rect);

    if (clampedSize !== size) {

      setSize(clampedSize);

    }
    // We don't care about resizes after they already happened, we only care about parent container rect changes

  }, [rect]); // eslint-disable-line

  const onResize = (e: SyntheticEvent, data: ResizeCallbackData) => {

    setSize(clampSize(data.size, rect));

  };

  return [size, onResize];

};
