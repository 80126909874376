import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import {
  alarmCreatorParticipantSelector,
  otherParticipantsSelector,
} from 'features/details/dcc/participants';
import { useLocale } from 'lib';
import { anyParticipantsSelector } from 'features/details/dcc';
import Participant from './participant';
import Grid from './grid';
import styles from './style.module.scss';

const Participants: FC = () => {

  const { t } = useLocale();
  const otherParticipants = useSelector(otherParticipantsSelector);
  const alarmCreatorParticipant = useSelector(alarmCreatorParticipantSelector);
  const anyParticipants = useSelector(anyParticipantsSelector);

  if (!anyParticipants) {

    return null;

  }

  return (
    <div className={styles.body}>
      <Grid title={t('Alarm_Detail_DCC.Connected.Incoming_Call_From')}>
        {alarmCreatorParticipant && (
          <Participant participant={alarmCreatorParticipant}/>
        )}
      </Grid>
      {otherParticipants.length > 0 && (
        <Grid title={t('Alarm_Detail_DCC.Connected.Connected_To')}>
          {otherParticipants.map((participant) => (
            <Participant
              key={participant.callSid}
              participant={participant}
            />
          ))}
        </Grid>
      )}
    </div>

  );

};

export default Participants;
