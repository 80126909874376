import { createAlarmsSlice } from '../withAlarmsPagination';

export const {
  stateSelector: closedAlarmsStateSelector,
  valueSelector: closedAlarmsSelector,
  highestSeveritySelector,
  anyHiddenAlarmsSelector,
  fetchAlarms: fetchClosedAlarms,
  slice: closedAlarms,
} = createAlarmsSlice('closed');
