import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import { Box, Email, Item } from 'react-html-email';
import { Header } from './header';
import { Footer } from './footer';
import { Text } from './text';

type TemplateProps = {
  alarmId: string;
  contactCode: string;
};

export const Template: FC<TemplateProps> = ({
  children,
  alarmId,
  contactCode,
}) => {

  return (
    <Email title={'Email'}>
      <Header/>
      <Item style={{
        padding: '20px',
        backgroundColor: 'white',
      }}>
        <Box style={{
          backgroundColor: colors.backgroundCultured,
          width: '100%',
        }}>
          <Text padding={'12px 16px'} fontWeight={'bold'}>
            U heeft een alarm melding ontvangen
            {contactCode ? <><br/>{contactCode}</> : ''}
          </Text>
          <Text padding={'0 16px 12px'}>
            In de onderstaande e-mail staat informatie over de maker van een alarm.
            Deze informatie is u toegezonden door een centralist.
          </Text>
          <Text padding={'0 16px 12px'}>
            U kunt deze informatie gebruiken bij de opvolging van het alarm.
          </Text>
        </Box>
        {children}
        <Box style={{
          width: '100%',
        }}>
          <Text padding={'16px'} textAlign={'center'} fontSize={'10px'}>
            {`Alarm ID: ${alarmId}`}
          </Text>
        </Box>
      </Item>
      <Footer/>
    </Email>
  );

};
