import { logger } from '../../classes/logger';

const isNewNotificationSupported = () => {

  if (!window.Notification || !Notification.requestPermission) return false;

  if (Notification.permission === 'granted') {

    return true;

  }

  try {

    // eslint-disable-next-line no-new
    new Notification('');

  } catch (e) {

    if (e.name === 'TypeError') return false;

  }

  return true;

};

export const notificationConstructorSupported = isNewNotificationSupported();
