import {
  createSlice, noop,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected, deviceRegistrationApi,
} from 'lib';
import { MutedState, RS, SetMuteArgs } from 'types';
import { createAsyncThunk } from '@reduxjs/toolkit';

const name = 'details/dcc/muted';

/**
 * Sets the DCC mute setting for the user
 */
export const setMute = createAsyncThunk<void, SetMuteArgs, { state: RS }>(
  name,
  async ({ value, conferenceSid }) => {

    await deviceRegistrationApi.post('/v1/conference/join-options/mute', {
      conferenceSid,
      muted: value,
    });

  },
);

/**
 * Handles the mute state for the dynamic conference call
 */
export default createSlice<MutedState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    value: false,
  },
  extraReducers: (builder) => builder
    .addCase(setMute.pending, (state, action) => {

      withPollablePending(state, action);

      state.value = action.meta.arg.value;

    })
    .addCase(setMute.fulfilled, (state, action) => {

      // Standard fulfilled handler, but with a noop 'onResult' callback, as we don't want to modify the value
      withPollableFulfilled(state, action, noop);

    })
    .addCase(setMute.rejected, withPollableRejected),
});

export const mutedSelector = (state: RS): boolean => state.details.dcc.muted.value;
