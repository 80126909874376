import { MutableRefObject, useEffect } from 'react';

type UseOutsideAlerter = (
  ref: MutableRefObject<HTMLDivElement>,
  callback: () => boolean,
) => void;

/**
 * Hook used to invoke a callback when a mousedown event occurs whose target is not an inclusive descendant of the given
 * HTMLDivElement
 */
export const useOutsideAlerter: UseOutsideAlerter = (ref, callback) => {

  useEffect(() => {

    const handleClickOutside = (e: MouseEvent) => {

      // Trigger the callback if the ref is set, and the event target is not an inclusive descendant of ref.current
      if (ref.current) {

        if (!ref.current.contains(e.target as Node) && callback()) {

          e.preventDefault();
          e.stopImmediatePropagation();

        }

      }

    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {

      window.removeEventListener('mousedown', handleClickOutside);

    };

  }, [ref, callback]);

};
