import React, {
  FC, ClipboardEvent, KeyboardEvent, useRef, InputHTMLAttributes,
} from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { mdiPlus } from '@mdi/js';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import styles from './style.module.scss';
import { FieldError } from './fieldError';

export type InputControlContentType = {
  text: string;
  number: number;
};

export type InputControlVariant = 'classic' | 'bubble';

export type InputControlButtonType = 'add';

export type InputControlContentTypeKey = 'text' | 'number' | 'password';

export type InputControlProps = {
  /**
   * Called when the control is blurred, receives a clipboard paste, enter is pressed or button is pressed
   *
   * @param value - New value
   */
  submit: (value: string) => void;
  /**
   * Placeholder string
   */
  placeholder: string;
  /**
   * Button type to show
   *
   * @default null
   */
  buttonType?: InputControlButtonType;
  /**
   * Error string to display
   *
   * @default null
   */
  error?: string;
  variant?: InputControlVariant;
  className?: string;
  input?: FieldInputProps<string>;
  meta?: FieldMetaState<string>;
  inputExtraProps?: InputHTMLAttributes<HTMLInputElement>;
};

/**
 * Controlled input field supporting multiple content types
 */
export const InputControl: FC<InputControlProps> = ({
  submit,
  input,
  meta,
  placeholder,
  buttonType = null,
  variant = 'bubble',
  className,
  inputExtraProps = {},
}) => {

  const inputRef = useRef<HTMLInputElement>();

  // Handle value submission
  const handleSubmit = (content: string) => {

    submit(content);

  };

  // Submit on enter
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {

    if (e.key === 'Enter') {

      handleSubmit(input.value);

    }

  };

  // Intercept paste
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {

    // check if clipboard data exists
    if (e.clipboardData) {

      // only prevent default in case we can actually do something
      e.preventDefault();

      // submit with clipboard data
      handleSubmit(e.clipboardData.getData('text/plain'));

    }

  };

  const handleButtonClick = () => {

    if (buttonType === 'add') {

      handleSubmit(input.value);
      inputRef.current.focus();

    }

  };

  return (
    <>
      <div className={cx(
        meta.error && meta.touched && styles.error,
        styles.field,
        styles[variant],
        className,
      )}>
        {variant === 'classic' && (
          <>
            <div className={cx(styles.decorator, styles.decorator1)}/>
            <div className={cx(styles.decorator, styles.decorator2)}/>
            <div className={cx(styles.decorator, styles.decorator3)}/>
          </>
        )}
        <input
          ref={inputRef}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          {...input}
          {...inputExtraProps}
        />
        {buttonType && (
          <div className={styles.button} onClick={handleButtonClick}>
            {buttonType === 'add' && (
              <Icon className={styles.icon} path={mdiPlus}/>
            )}
          </div>
        )}
      </div>
      {meta.error && meta.touched && meta.error !== 'silent-error' && (
        <FieldError message={meta.error}/>
      )}
    </>
  );

};
