import React, { memo, useCallback } from 'react';

import cx from 'clsx';

import { OverviewFilters } from 'types';
import { Mutator } from 'final-form';
import { Form as ReactFinalForm } from 'react-final-form';
import { isDefaultFiltersSelector, currentFiltersSelector } from 'features/overview/alarmFilters';
import { setFilter } from 'features/overview';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'lib';
import { Form } from './form';
import styles from './style.module.scss';

type Mutators = {
  setValues: Mutator<OverviewFilters>;
};

const mutators: Mutators = {
  /**
   * Copies the given state over to the current state
   */
  setValues: ([values]: [OverviewFilters], state, tools) => {

    for (const key in values) {

      if (Object.prototype.hasOwnProperty.call(values, key)) {

        tools.changeValue(state, key, () => values[key]);

      }

    }

  },
};

/**
 * Display a form
 */
export const AlarmFiltersForm = memo(() => {

  const { t } = useLocale();
  const dispatch = useDispatch();
  const filters = useSelector(currentFiltersSelector);

  // Get whether the current filters are default
  // This is used to determine whether to show the filters warning
  const isDefaultFilters = useSelector(isDefaultFiltersSelector);

  // Set filters when submitting
  const onSubmit = useCallback((values: OverviewFilters) => {

    dispatch(setFilter({
      source: 'form',
      filter: values,
    }));

  }, [dispatch]);

  return (
    <div className={styles.filter}>
      <div className={styles.header}>
        <span className={styles.text}>{t('Main_Filter')}</span>
      </div>
      <div className={cx(
        styles.activeFiltersWarningWrapper,
        // Show the warning if filters aren't default and the user hasn't chosen to ignore the warning
        !isDefaultFilters && styles.show,
      )}>
        <div className={styles.activeFiltersWarning}>
          <div className={styles.text}>
            <span>{t('Warning_AlarmFilter_Active')}</span>
          </div>
        </div>
      </div>
      <ReactFinalForm
        initialValues={filters}
        onSubmit={onSubmit}
        mutators={mutators}
        render={Form}
      />
    </div>
  );

});
