import { ParsedQs } from 'qs';
import { useDeepMemo } from './useDeepMemo';
import { useSearch } from './useSearch';

/**
 * Parses the query string and returns the value of the given key
 * Uses deep equality to maintain a stable identity
 */
export const useQuery = <T extends ParsedQs[string], TResult = T>(key: string, transformer?: (value: T) => TResult): TResult => {

  const search = useSearch();

  return useDeepMemo<TResult>(() => {

    const rawValue = search[key] as T;

    if (transformer) {

      return transformer(rawValue);

    }

    return rawValue as unknown as TResult;

  }, [key, search, transformer]);

};
