import React, { FC } from 'react';
import { Text } from 'components/atoms';
import { ModalGrid, ModalGridProps } from '../modalGrid';
import { ModalGridItem, ModalGridItemProps, ModalGridItemIconSize } from '../modalGridItem';
import styles from './style.module.scss';

type AnswerMap<TQuestion, TArg> = Record<keyof TQuestion, ModalGridItemProps & {
  arg?: TArg;
}>;

type QuestionProps<TQuestion, TArg> = {
  modalGridProps: Omit<ModalGridProps, 'children'>;
  items: AnswerMap<TQuestion, TArg>;
  onAnswer: (value: string, arg?: TArg) => void;
  iconSize?: ModalGridItemIconSize; // Default props for every modal grid item
  title?: string;
  selected?: string;
};

export const ChoiceGrid: FC<QuestionProps<unknown, unknown>> = ({
  modalGridProps,
  items,
  onAnswer,
  iconSize = 'medium',
  title = null,
  selected = null,
}) => {

  return (
    <>
      {title !== null && (
        <Text variant={'h6'} textAlign={'center'} className={styles.title}>
          {title}
        </Text>
      )}
      <ModalGrid {...modalGridProps}>
        {Object.keys(items).map((item) => {

          const {
            arg = null,
            ...rest
          } = items[item];

          return (
            <ModalGridItem
              key={item}
              iconSize={iconSize}
              selected={selected === item}
              {...rest}
              onClick={() => onAnswer(item, arg)}
            />
          );

        })}
      </ModalGrid>
    </>
  );

};
