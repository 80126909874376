import React, { FC } from 'react';

import moment from 'moment';
import cx from 'clsx';
import { Icon } from '@mdi/react';

import {
  Address, BetterMarkdown, BetterMoment, Image,
} from 'components/molecules';
import { useSelector } from 'react-redux';
import {
  alarmPositionSelector,
  currentPositionSelector,
  alarmDetailsSelector,
} from 'features/details';
import { useSeverityAssets, areSamePosition, useLocale } from 'lib';
import { connectedCustomerSelector } from 'features/identity';
import { mdiAccount } from '@mdi/js';
import styles from './style.module.scss';

export const Info: FC = () => {

  const { t } = useLocale();

  const details = useSelector(alarmDetailsSelector);
  const alarmPosition = useSelector(alarmPositionSelector);
  const currentPosition = useSelector(currentPositionSelector);
  const customer = useSelector(connectedCustomerSelector);
  const dateCreated = moment.utc(details.createdAt).local();
  const hideCurrentPosition = alarmPosition && currentPosition && areSamePosition(alarmPosition, currentPosition);
  const externalId = details.asset.externalId;
  const { className } = useSeverityAssets(details);

  return (
    <div className={cx(styles.info, className)}>
      <div className={styles.infoHeader}>
        <span>{details.name}</span>
        {details.asset.properties?.image ? (
          <Image src={details.asset.properties.image} alt='' className={styles.pic} />
        ) : (
          <div className={styles.pic}>
            <Icon className={styles.account} path={mdiAccount}/>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.fields}>
          <div className={styles.title}>{t('Main_OpenAlarms_MadeBy')}</div>
          <div className={styles.subtitle}>{details.asset.name}</div>

          <div className={styles.title}>{t('Alarm.Date_Created')}</div>
          <div className={styles.subtitle}>
            <BetterMoment
              format={'DD/MM/YYYY HH:mm:ss'}
              calendarOptions={{ includeTime: true }}
              timeFromNow
            >
              {dateCreated}
            </BetterMoment>
          </div>

          {/* eslint-disable-next-line */}
          {(externalId && customer['telluId'] !== 5271348) && (
            <>
              <div className={styles.title}>{t('AlarmDetails_Extern')}</div>
              <div className={styles.subtitle}>{externalId}</div>
            </>
          )}

          {details.customer._id !== customer._id && (
            <>
              <div className={styles.title}>{t('AlarmDetails_Customer')}</div>
              <div className={styles.subtitle}>{details.customer.name}</div>
            </>
          )}
        </div>

        {details.asset?.description && (
          <div className={styles.description}>
            <div className={styles.descriptionTitle}>{t('AlarmDetails_Description')}</div>
            <BetterMarkdown>{details.asset.description}</BetterMarkdown>
          </div>
        )}

        <div className={styles.horizontalRule} />

        {!hideCurrentPosition && (
          <Address
            position={currentPosition}
            alarm={false}
            label={t('Address_CurrentPos')}
          />
        )}

        <Address
          position={alarmPosition}
          alarm={true}
          label={t('Address_AlarmPos')}
        />
      </div>
    </div>
  );

};
