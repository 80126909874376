import React, { FC, useMemo } from 'react';

import cx from 'clsx';

import { Moment } from 'moment';
import { useLocale } from 'lib';
import styles from './style.module.scss';

type AmPm = 'am' | 'pm';

const getDateAmPm = (value: Moment): AmPm => {

  return value.hour() >= 12 ? 'pm' : 'am';

};

type AmPmViewProps = {
  date: Moment;
  onChange: (value: Moment) => void;
};

export const AmPmView: FC<AmPmViewProps> = ({ date, onChange }) => {

  const { t } = useLocale();

  const currentAmPm = useMemo(() => {

    return getDateAmPm(date);

  }, [date]);

  return (
    <div className={styles.ampm}>
      {(['am', 'pm'] as const).map((value) => (
        <span
          key={value}
          className={cx(currentAmPm === value && styles.active)}
          onClick={() => {

            if (currentAmPm !== value) {

              const clone = date.clone();

              const newDate = clone.hour(clone.hour() + (value === 'am' ? -12 : 12));

              onChange(newDate);

            }

          }}
        >
          {/* uses-translation-asset: "DateTimePickerField.AmPm.*" */}
          {t(`DateTimePickerField.AmPm.${value}`)}
        </span>
      ))}
    </div>
  );

};
