import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { isoLangSelector } from 'features/identity/preferences/exports';
import parse, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

/**
 * Formats the international input phone number to the current locale's human-readable format
 */
export const usePhoneNumber = (number: string): {
  international: string;
  uri: string;
} => {

  const iso = useSelector(isoLangSelector);

  return useMemo(() => {

    if (!isValidPhoneNumber(number)) {

      return null;

    }

    // Lang seems to be an iso country code with uppercase formatting
    const lang = iso.toUpperCase() as unknown as CountryCode;

    const parsed = parse(number, lang);

    return {
      international: parsed.formatInternational(),
      uri: parsed.getURI(),
    };

  }, [number, iso]);

};
