import React, { FC } from 'react';

import cx from 'clsx';
import { Loader } from 'components/atoms';
import styles from '../style.module.scss';

const DCCLoader: FC = () => {

  return (
    <div className={cx(styles.shortStatus, styles.loading)}>
      <Loader variant={'small'}/>
    </div>
  );

};

export default DCCLoader;
