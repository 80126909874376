import React, { FC } from 'react';

import { XGAC_API_BASE_URL } from 'config';
import { useLocale } from 'lib';
import { TranslationKey } from 'config/i18n';
import { ErrorBoundary } from 'components/molecules/errorBoundary';
import styles from './style.module.scss';

export const Banner: FC = () => {

  const { t } = useLocale();

  let label: string = null;

  if (XGAC_API_BASE_URL.includes('localhost')) {

    label = 'localhost';

  } else if (XGAC_API_BASE_URL.endsWith('.dev')) {

    label = 'staging';

  } else {

    return null;

  }

  return (
    <ErrorBoundary>
      <div className={styles.bannerAnchor}/>
      <div className={styles.banner}>
        {/* uses-translation-asset: "EnvBanner.*" */}
        {t(`EnvBanner.${label}` as TranslationKey)}
      </div>
    </ErrorBoundary>
  );

};
