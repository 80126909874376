import {
  RS,
  StaticResponseState, AbortableThunkArg,
} from 'types';
import {
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
  createThunk, defaultRetryConfig, withPollableRetrying, api, essentialStatePicker,
} from 'lib';
import { ResponseGroup, ResponseGroupType } from '@x-guard/xgac-types/xgac';

const name = 'details/response/static';

export type FetchStaticResponseArgs = AbortableThunkArg & {
  alarmId: string;
};

export const fetchStaticResponse = createThunk<ResponseGroup[], FetchStaticResponseArgs>({
  name,
  handler: async ({ alarmId, controller: { signal } }) => {

    const { data } = await api.get<{ result: ResponseGroup[] }>(`/alarms/${alarmId}/response/static`, {
      signal,
    });

    return data.result;

  },
  retryConfig: defaultRetryConfig,
});

const checkAprReplacedByZone = (oldValue: ResponseGroup[], newValue: ResponseGroup[]): boolean => {

  const countByType = (value: ResponseGroup[], type: ResponseGroupType) => {

    return value?.filter((group) => group.type === type).length ?? 0;

  };

  const aprCount = countByType(oldValue, ResponseGroupType.AlarmProfessionalResponder);
  const newAprCount = countByType(newValue, ResponseGroupType.AlarmProfessionalResponder);

  const zoneCount = countByType(oldValue, ResponseGroupType.Zone);
  const newZoneCount = countByType(newValue, ResponseGroupType.Zone);

  return newAprCount < aprCount && newZoneCount > zoneCount;

};

export const staticResponse = createSlice<StaticResponseState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    aprReplacedByZone: false,
  },
  extraReducers: (builder) => builder
    .addCase(fetchStaticResponse.pending, withPollablePending)
    .addCase(fetchStaticResponse.fulfilled, (state, action) => {

      state.aprReplacedByZone ||= checkAprReplacedByZone(state.value, action.payload);

      withPollableFulfilled(state, action);

    })
    .addCase(fetchStaticResponse.rejected, withPollableRejected)
    .addCase(fetchStaticResponse.retrying, withPollableRetrying),
});

export const staticResponseStatusSelector = (state: RS) => state.details.response.static.status;
export const staticResponseValueSelector = (state: RS) => state.details.response.static.value;
export const staticResponseSelector = essentialStatePicker((state) => state.details.response.static);
export const staticResponseAprReplacedByZoneSelector = (state: RS) => state.details.response.static.aprReplacedByZone;
