import { OverlayPoints } from '@x-guard/xgac-types/xgac';
import L from 'leaflet';
import { FC, useEffect, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet-toolbar';
import '@x-guard/xgac-leaflet-distortable-image';
import '@x-guard/xgac-leaflet-distortable-image/vendor.css';

type ImageOverlayProps = {
  url: string;
  bounds: OverlayPoints;
};

export const ImageOverlay: FC<ImageOverlayProps> = ({
  url,
  bounds,
}) => {

  const map = useMap();

  const corners = useMemo(() => {

    return [
      L.latLng(bounds.nw.lat, bounds.nw.lng),
      L.latLng(bounds.ne.lat, bounds.ne.lng),
      L.latLng(bounds.sw.lat, bounds.sw.lng),
      L.latLng(bounds.se.lat, bounds.se.lng),
    ];

  }, [bounds]);

  useEffect(() => {

    const overlay = L.distortableImageOverlay(url, {
      corners,
      editable: false,
      selected: false,
      actions: [],
    });

    overlay.addTo(map);

    return () => {

      overlay.removeFrom(map);

    };

  }, [map, url, bounds, corners]);

  return null;

};
