import React, { forwardRef } from 'react';
import cx from 'clsx';
import styles from './style.module.scss';

type ShadowProps = {
  enabled: boolean;
};

/**
 * Component that renders a simple animated background shadow
 */
export const Shadow = forwardRef<HTMLDivElement, ShadowProps>(({ enabled }, ref) => {

  return (
    <div
      ref={ref}
      className={cx(
        styles.shadowArea,
        enabled && styles.enabled,
      )}
    >
      <div className={styles.shadow}/>
    </div>
  );

});
