import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { USERSNAP_GLOBAL_API_KEY } from 'config';

import { userContextSelector } from 'features/identity/auth/exports';
import { connectedCustomerSelector } from 'features/identity/exports';
import { useLocale } from '../hooks/useLocale';

export const UserSnap: FC = () => {

  const { iso } = useLocale();
  const userData = useSelector(userContextSelector);
  const currentCustomer = useSelector(connectedCustomerSelector);

  // no typings available for the UserSnap API
  // eslint-disable-next-line
  const [api, setApi] = useState<any>(null);

  // If there is userData present, set the visitor value to the username
  useEffect(() => {

    if (!api || !userData || !currentCustomer) return undefined;

    const onOpen = (event) => {

      const email = userData.user.properties.email;

      event.api.setValue('custom', {
        username: userData.user.username,
        connectedCustomer: {
          id: currentCustomer._id,
          name: currentCustomer.name,
        },
      });

      if (email) {

        event.api.setValue('visitor', email);

      }

    };

    api.on('open', onOpen);

    return () => api.off('open', onOpen);

  }, [api, userData, currentCustomer]);

  useEffect(() => {

    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['onUsersnapCXLoad'] = (userSnap) => {

      userSnap.init({
        locale: iso,
      });

      setApi(userSnap);

    };

    const script = document.createElement('script');

    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;

    document.getElementsByTagName('head')[0].appendChild(script);

    // Don't need to rerun this hook when locale changes, as the locale can only be set once on initialisation

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

};
