import React, { FC, useCallback } from 'react';

import { Icon } from '@mdi/react';

import { Text } from 'components/atoms';
import { CheckboxControl } from 'components/molecules';
import {
  mdiMinus, mdiPlus, mdiFullscreen, mdiFullscreenExit,
} from '@mdi/js';
import { useLocale } from 'lib';
import { useMap } from 'react-leaflet';
import styles from './style.module.scss';

type ControlsProps = {
  fullscreen: boolean;
  toggleFullscreen: () => void;
  autoZoom: boolean;
  toggleAutoZoom: () => void;
  renderImageOverlays: boolean;
  toggleRenderImageOverlays: () => void;
};

export const Controls: FC<ControlsProps> = ({
  fullscreen,
  toggleFullscreen,
  autoZoom,
  toggleAutoZoom,
  renderImageOverlays,
  toggleRenderImageOverlays,
}) => {

  const { t } = useLocale();

  const map = useMap();

  const zoomIn = useCallback(() => {

    map.setZoom(map.getZoom() + 1);

  }, [map]);

  const zoomOut = useCallback(() => {

    map.setZoom(map.getZoom() - 1);

  }, [map]);

  return (
    <div
      className={styles.controlsLayout}
      onMouseEnter={() => map.doubleClickZoom.disable()}
      onMouseLeave={() => map.doubleClickZoom.enable()}
    >
      <div className={styles.controls}>
        <Text variant={'titleSpan'} className={styles.title}>
          {t('AlarmMap_Map')}
        </Text>
        <div className={styles.buttons}>
          <div className={styles.control} onClick={() => toggleFullscreen()}>
            <Icon path={fullscreen ? mdiFullscreenExit : mdiFullscreen}/>
          </div>
          <div className={styles.control} onClick={zoomOut}>
            <Icon path={mdiMinus}/>
          </div>
          <div className={styles.control} onClick={zoomIn}>
            <Icon path={mdiPlus}/>
          </div>
        </div>
      </div>
      {toggleAutoZoom && (
        <div className={styles.controls}>
          <CheckboxControl
            size={'medium'}
            label={t('Alarm_Detail_Map.AutoZoom')}
            input={{
              checked: autoZoom,
              onChange: () => toggleAutoZoom(),
            }}
          />
        </div>
      )}
      {toggleRenderImageOverlays && (
        <div className={styles.controls}>
          <CheckboxControl
            size={'medium'}
            label={t('Alarm_Detail_Map.ImageOverlays')}
            input={{
              checked: renderImageOverlays,
              onChange: () => toggleRenderImageOverlays(),
            }}
          />
        </div>
      )}
    </div>
  );

};
