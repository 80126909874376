/* eslint-disable */
import { XGAC_API_BASE_URL } from 'config';
import axios from 'axios';
import { attachAuthorizationInterceptor } from 'lib/api/authorizationInterceptor';

type AuthRefreshResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

/* eslint-enable */

export const api = axios.create({
  baseURL: XGAC_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

attachAuthorizationInterceptor(api);
