import React, { FC } from 'react';
import cx from 'clsx';
import styles from '../style.module.scss';

import { Location } from '../location';

type UnauthorizedHeaderProps = {
  hide: boolean;
};

export const UnauthorizedHeader: FC<UnauthorizedHeaderProps> = ({ hide }) => {

  return (
    <div className={cx(styles.header, hide && styles.hide)}>
      <div className={styles.contentRow}>
        <Location/>
      </div>
    </div>
  );

};
