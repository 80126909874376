export enum Connection {
  Disconnected,
  Connecting,
  Connected,
}

/**
 * Websocket built in events
 */
export type WebSocketBuiltInEvents = {
  connect: (message: string) => void;
  connectError: (message: string) => void;
  message: (message: object) => void;
  error: (message: string) => void;
};
