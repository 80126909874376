import { RS } from 'types';
import {
  AnyAction,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  PayloadAction,
  miniSerializeError,
  SerializedError,
  AsyncThunk, AsyncThunkPayloadCreatorReturnValue,
} from '@reduxjs/toolkit';
import { ActionCreatorWithPreparedPayload } from '@reduxjs/toolkit/src/createAction';

export type AsyncThunkApi = {
  state: RS;
};

export type RetryPayload<Arg> = {
  arg: Arg;
  error: SerializedError;
  retryIndex: number;
};

export type RetryConfig<Arg> = {
  shouldRetry?: (payload: RetryPayload<Arg>) => boolean;
  dispatchOnRetry?: (payload: RetryPayload<Arg>) => boolean;
  waitProvider: () => WaitProvider;
};

export type WaitProvider = () => Promise<boolean>;

export type CreateThunkOptions<Returned, ThunkArg> = {
  name: string;
  handler: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkApi>;
  retryConfig?: RetryConfig<ThunkArg>;
};

type Return<T> = AsyncThunkPayloadCreatorReturnValue<T, AsyncThunkApi>;

const retryable = async <Returned>(
  payloadCreator: () => Return<Returned>,
  onError: (error: Error) => Promise<boolean>,
) => {

  let error: Error | undefined;

  do {

    try {

      return await payloadCreator();

    } catch (e) {

      error = e;

    }

  } while (await onError(error));

  throw error;

};

export type RetryingPayloadCreator<Arg> = ActionCreatorWithPreparedPayload<
  [RetryPayload<Arg>],
  RetryPayload<Arg>
>;

export type ThunkWithRetry<Returned, ThunkArg> = AsyncThunk<
  Returned,
  ThunkArg,
  AsyncThunkApi
> & {
  retrying: RetryingPayloadCreator<ThunkArg>;
};

const getRetryingPayloadCreator = <Arg>(typePrefix: string): RetryingPayloadCreator<Arg> => {

  const type = `${typePrefix}/retrying`;

  const retrying = ((payload) => ({ type, payload })) as RetryingPayloadCreator<Arg>;

  retrying.type = type;
  retrying.match = (action: AnyAction): action is PayloadAction<RetryPayload<Arg>> => action.type === type;

  return retrying;

};

export const createThunk = <Returned, ThunkArg>({
  name,
  handler,
  retryConfig,
}: CreateThunkOptions<Returned, ThunkArg>): ThunkWithRetry<Returned, ThunkArg> => {

  const thunk = createAsyncThunk<Returned, ThunkArg, AsyncThunkApi>(
    name,
    async (arg, api) => {

      if (retryConfig) {

        const strategy = retryConfig.waitProvider();
        let retryIndex = -1;

        return (retryable<Returned>(
          // Wraps the payload creator in a function that returns the result of the payload creator,
          async () => handler(arg, api) as any,
          // This callback decides whether to retry or not
          async (error: Error): Promise<boolean> => {

            retryIndex++;

            const payload: RetryPayload<ThunkArg> = {
              arg,
              error: miniSerializeError(error),
              retryIndex,
            };

            // If the config contains a method that decides whether we should continue retrying, use it is a guard
            if (retryConfig.shouldRetry && !retryConfig.shouldRetry({ ...payload })) {

              return false;

            }

            // If the dispatchOnRetry option is set, dispatch an action with the error
            if (retryConfig.dispatchOnRetry({ ...payload })) {

              api.dispatch(thunk.retrying({ ...payload }));

            }

            return strategy();

          },
        ) as any);

      }

      return handler(arg, api) as any;

    },
  ) as ThunkWithRetry<Returned, ThunkArg>;

  thunk.retrying = getRetryingPayloadCreator(name);

  return thunk;

};
