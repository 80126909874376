import React, { FC, Fragment } from 'react';

import Markdown from 'react-markdown';

type BetterMarkdownProps = {
  children: string;
};

export const BetterMarkdown: FC<BetterMarkdownProps> = ({ children }) => {

  return (
    <Markdown components={{
      p: Fragment,
    }}>
      {children}
    </Markdown>
  );

};
