import React, { FC, ReactNode } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';
import styles from './style.module.scss';

type AnimatedButtonProps = {
  iconPath: string;
  label: ReactNode;
  onClick: () => void;
};

export const AnimatedButton: FC<AnimatedButtonProps> = ({
  iconPath,
  label,
  onClick,
}) => {

  return (
    <div
      className={cx(styles.animatedButton, onClick && styles.clickable)}
      onClick={() => onClick?.()}
    >
      <div className={styles.icon}>
        <Icon path={iconPath}/>
      </div>
      <div className={styles.content}>
        {label}
      </div>
    </div>
  );

};
