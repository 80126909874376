import React, { ReactElement } from 'react';
import { authenticatedSelector } from 'features/identity/auth/exports';
import { useQuery } from 'lib/hooks/useQuery';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const useAuthenticatedRedirect = (): ReactElement | null => {

  const isAuthenticated = useSelector(authenticatedSelector);
  const redirect = useQuery<string>('r');

  if (isAuthenticated) {

    return (
      <Navigate to={redirect ?? '/alarms'}/>
    );

  }

  return null;

};
