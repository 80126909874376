import { qs } from 'lib/helpers';

type Falsy = false | null | undefined | 0 | '';

type QueryStringPart = string | any | Falsy;

export const advancedQs = (...queryStringParts: QueryStringPart[]) => {

  return queryStringParts
    .filter(Boolean)
    .map((part) => {

      if (typeof part === 'string') return part;

      return qs(part);

    })
    .join('&');

};
