import React, { FC, MouseEventHandler } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';
import { mdiCheck, mdiClose } from '@mdi/js';
import styles from './style.module.scss';

export type ContactSwitchProps = {
  checked: boolean;
  setChecked: (value: boolean) => void;
};

export const ContactSwitch: FC<ContactSwitchProps> = ({ checked, setChecked }) => {

  const onClick = (value: boolean): MouseEventHandler<HTMLDivElement> => {

    return (e) => {

      e.stopPropagation();

      if (checked !== value) {

        setChecked?.(value);

      }

    };

  };

  return (
    <div className={styles.contactSwitch}>
      <div
        className={cx(
          styles.icon,
          checked === false && styles.active,
        )}
        onClick={onClick(false)}
      >
        <Icon path={mdiClose}/>
      </div>
      <div
        className={cx(
          styles.icon,
          checked === true && styles.active,
        )}
        onClick={onClick(true)}
      >
        <Icon path={mdiCheck}/>
      </div>
      <div className={cx(
        styles.iconBackground,
        checked === true && styles.checked,
        checked === false && styles.notChecked,
      )}/>
    </div>
  );

};
