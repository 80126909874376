import React, { FC, ReactNode, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  ErrorDisplay, Text, Chip,
} from 'components/atoms';
import { WithAsync } from 'components/molecules';
import {
  AlarmsState, OverviewAlarm, S,
} from 'types';
import { defaultFilters, isDefaultFiltersSelector } from 'features/overview/alarmFilters';
import { computePaginationMeta, useLocale } from 'lib';
import { setFilter } from 'features/overview';
import { LoadMore } from '../loadMore';
import styles from './style.module.scss';

type AlarmListProps = {
  className: string;
  stateSelector: S<AlarmsState>;
  item: FC<{ alarm: OverviewAlarm }>;
  title: ReactNode;
  subtitle?: ReactNode;
  noResults: ReactNode;
  loadMore: () => void;
};

export const AlarmList: FC<AlarmListProps> = ({
  className,
  stateSelector,
  title,
  subtitle,
  noResults,
  loadMore,
  item: Alarm,
}) => {

  const { t } = useLocale();

  const isDefaultFilters = useSelector(isDefaultFiltersSelector);
  const rawState = useSelector(stateSelector);
  const dispatch = useDispatch();

  const resetFilters = () => {

    dispatch(setFilter({
      source: 'external',
      filter: defaultFilters,
    }));

  };

  const [state, meta] = useMemo(() => {

    const newState = isDefaultFilters ? rawState : rawState.filtered;

    return [newState, computePaginationMeta(newState)];

  }, [isDefaultFilters, rawState]);

  const hiddenByFilters = useMemo(() => {

    if (!isDefaultFilters && rawState.status.fulfilled && rawState.filtered.status.fulfilled) {

      return rawState.total && rawState.filtered.total;

    }

    return false;

  }, [rawState, isDefaultFilters]);

  return (
    <>
      <div className={styles.title}>
        <span>{title}</span>
        {hiddenByFilters !== false && (
          <Chip
            className={styles.chip}
            variant={'dark'}
            size={'small'}
            label={t('Alarm_Overview_List.Filtered.Count_Visible', {
              filteredCount: rawState.filtered.total,
              unfilteredCount: rawState.total,
            })}
          />
        )}
        {!isDefaultFilters && (
          <Chip
            className={styles.chip}
            variant={'warning100'}
            size={'small'}
            label={t('Alarm_Overview_List.Filtered.Remove_Filters')}
            onClick={resetFilters}
            onDelete={resetFilters}
          />
        )}
      </div>
      {subtitle}
      <WithAsync
        status={state.status}
        error={() => (
          <ErrorDisplay
            message={state.error.message}
          />
        )}
      >
        <div className={className}>
          {state.value.map((value) => (
            <Alarm
              key={value._id}
              alarm={value}
            />
          ))}
          {meta.hasMoreItems && (
            <LoadMore
              size={'small'}
              status={state.realStatus}
              onClick={loadMore}
            />
          )}
          {meta.loadedWithNoContent && (
            <Text className={styles.noResults}>
              {noResults}
            </Text>
          )}
        </div>
      </WithAsync>
    </>
  );

};
