import React, { FC } from 'react';
import styles from './style.module.scss';

type FieldErrorProps = {
  message: string;
};

export const FieldError: FC<FieldErrorProps> = ({ message }) => {

  return (
    <div className={styles.errorContainer}>
      {message}
    </div>
  );

};
