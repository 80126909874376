import { WebSocketController } from '@x-guard/xgac-ts-ws-client';
import { createContext, useContext } from 'react';

export type WebSocketContext = {
  ws: WebSocketController | null;
  retryIndex: number;
};

export const webSocketContext = createContext<WebSocketContext>({
  ws: null,
  retryIndex: 0,
});

export const useWebSocketContext = (): WebSocketContext => {

  return useContext(webSocketContext);

};
