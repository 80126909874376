import {
  useMemo, useState, useLayoutEffect, MutableRefObject,
} from 'react';

export type UseMeasureRect = Pick<DOMRectReadOnly, 'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'>;
export type UseMeasureRef<E extends Element = Element> = (element: E) => void;
export type UseMeasureResult<E extends Element = Element> = [UseMeasureRect, UseMeasureRef<E>];

/**
 * Consumes the resize observer api to report layout changes of a html element
 */
export const useMeasure = <E extends Element>(elementRef: MutableRefObject<E> = null): UseMeasureResult<E> => {

  const [element, setElement] = useState<E | null>(null);

  // Support two mechanisms for providing the hook with an element to observe, either a ref parameter, or a setter return
  const selectedElement = elementRef?.current ?? element;

  // Get and set the rect of the selected element
  const [rect, setRect] = useState<UseMeasureRect>(() => {

    return selectedElement?.getBoundingClientRect() ?? null;

  });

  // Setup observer
  const observer = useMemo(() => new window.ResizeObserver((entries) => {

    if (entries[0]) {

      const {
        x, y, width, height, top, left, bottom, right,
      } = entries[0].contentRect;
      setRect({
        x, y, width, height, top, left, bottom, right,
      });

    }

  }), [setRect]);

  useLayoutEffect(() => {

    if (!selectedElement) return undefined;

    observer.observe(selectedElement);

    return () => {

      observer.unobserve(selectedElement);

    };

  }, [observer, selectedElement]);

  return [rect, setElement];

};
