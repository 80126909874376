import React, {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  KeyboardEvent,
  Dispatch,
} from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { wrap } from 'lib';
import { DropdownItems, DropdownControlValueMeta } from '../dropdownItems';
import styles from './style.module.scss';

export type FilterableDropdownControlProps<T> = {
  value: T;
  onSelectValue: (value: T) => void;
  values: T[];
  getMeta: (value: T) => DropdownControlValueMeta;
  placeholder: string;
  resetOnFocus?: boolean;
  filter?: (value: string) => void;
  noResults: FC;
  openState: [boolean, Dispatch<boolean>];
};

/**
 * Controlled dropdown
 */
export const FilterableDropdownControl: FC<FilterableDropdownControlProps<unknown>> = ({
  getMeta,
  value,
  onSelectValue,
  values,
  filter,
  placeholder,
  noResults,
  openState: [open, setOpen],
  resetOnFocus,
}) => {

  // Input field text
  const [text, setText] = useState(getMeta(value).label);

  // Tracks the index of the selected item
  const [selectedIndex, setSelectedIndex] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickOutside = useCallback(() => {

    setOpen(false);
    inputRef.current.blur();

    if (!text) {

      setText(getMeta(value).label);

    }

  }, [text, getMeta, setOpen, value]);

  useEffect(() => {

    setSelectedIndex(0);

  }, [values]);

  // Focus on the dropdown input if it is dropdown is open
  useEffect(() => {

    if (open && inputRef.current) {

      if (resetOnFocus) {

        setText('');

      }

      inputRef.current.focus();

    }

  }, [open, inputRef, resetOnFocus]);

  // Opens the dropdown and selects text when the text is focused
  useEffect(() => {

    const current = inputRef.current;

    if (!current) return undefined;

    const handler = () => {

      setOpen(true);

      // Gross fix to a bug relating to the .select() only working sometimes, because chrome sucks
      setTimeout(() => current.select(), 1);

    };

    current.addEventListener('focus', handler);

    return () => current.removeEventListener('focus', handler);

  }, [setOpen, inputRef]);

  const toggle = () => setOpen(!open);

  // Select item handler
  const onSelectItem = useCallback((item: unknown) => {

    const valueMeta = getMeta(value);
    const itemMeta = getMeta(item);

    setOpen(false);
    setText(itemMeta.label);
    filter?.(null);

    if (itemMeta.key !== valueMeta.key) {

      onSelectValue(item);

    }

  }, [value, setOpen, onSelectValue, setText, filter, getMeta]);

  // Handle input element text change
  // Will immediately change the input field content, and
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {

    if (open === false) {

      setOpen(true);

    }

    const newText = e.target.value;

    setText(newText);
    filter?.(newText);

  };

  // Moves the selected index by either 1 or -1. Allows for index wrapping
  const moveSelectedIndexBy = (diff: 1 | -1) => {

    const newIndex = wrap(selectedIndex + diff, values.length);
    setSelectedIndex(newIndex);
    setText(getMeta(values[newIndex]).label);

  };

  // Handles keyboard interaction with the dropdown
  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {

    // If the dropdown is closed, open it
    if (open === false) {

      setOpen(true);
      setSelectedIndex(values.indexOf(value));
      return;

    }

    switch (e.key) {

    // Hide selection
    case 'Backspace':
      if (selectedIndex !== -1) {

        setSelectedIndex(-1);

      }
      break;
      // Select the item associated with the given index
    case 'Enter':
      // Only select item if we don't have a hidden index and filtered values have at least one result
      if (selectedIndex !== -1 && values.length > 0) {

        onSelectItem(values[selectedIndex]);

      }
      e.preventDefault();
      break;
    default: break;

    }

  };

  const onControlKeyDown = (e: KeyboardEvent): void => {

    // If the dropdown is closed, open it
    if (open === false) {

      setOpen(true);
      setSelectedIndex(values.indexOf(value));
      return;

    }

    // Don't need to capture the event if no values can be selected
    if (values.length === 0) {

      return;

    }

    switch (e.key) {

    // Move selection index up
    case 'ArrowUp':
      moveSelectedIndexBy(-1);
      e.preventDefault();
      break;
      // Move selection index down
    case 'ArrowDown':
      moveSelectedIndexBy(1);
      e.preventDefault();
      break;
    default: break;

    }

  };

  const icon = open ? mdiChevronUp : mdiChevronDown;

  return (
    <div
      ref={ref}
      className={cx(
        styles.dropdown,
        open && styles.open,
      )}
      onKeyDown={onControlKeyDown}
      tabIndex={-1}
    >
      <div className={styles.selectedContainer}>
        <div className={styles.selected}>
          <input
            ref={inputRef}
            value={text}
            onChange={onChange}
            onKeyDown={onInputKeyDown}
            placeholder={placeholder}
          />
        </div>
        <div className={styles.iconContainer} onClick={toggle}>
          <Icon path={icon}/>
        </div>
      </div>
      {open && (
        <DropdownItems
          value={value}
          onSelectValue={onSelectItem}
          values={values}
          getMeta={getMeta}
          selectedIndex={selectedIndex}
          parent={ref}
          onDismiss={onClickOutside}
          noResults={noResults}
        />
      )}
    </div>
  );

};
