import React, { FC } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { useLocale } from 'lib';
import { mdiAccount, mdiIncognito } from '@mdi/js';
import { PopulatedAlarmHelper } from 'types';
import { Image } from 'components/molecules';
import styles from './style.module.scss';

type HelpersProps = {
  helpers: PopulatedAlarmHelper[];
};

const Helper: FC<{ helper: PopulatedAlarmHelper }> = ({ helper }) => {

  const { t } = useLocale();

  if (!helper.asset) {

    return (
      <div className={styles.helper}>
        <div className={cx(styles.helperPic, styles.account)}>
          <Icon path={mdiIncognito}/>
        </div>
        <div className={styles.helperName}>{t('Helpers_Anonymous')}</div>
      </div>
    );

  }

  return (
    <div className={styles.helper} title={helper.asset.name}>
      {helper.asset.properties?.image ? (
        <Image src={helper.asset.properties.image} alt='' className={cx(styles.helperPic, styles.magnify)}/>
      ) : (
        <div className={cx(styles.helperPic, styles.account)}>
          <Icon path={mdiAccount}/>
        </div>
      )}
      <div className={styles.helperName}>{helper.asset.name}</div>
    </div>
  );

};

export const HelpersList: FC<HelpersProps> = ({ helpers }) => {

  const { t } = useLocale();

  return (
    <div className={cx(
      styles.helpersContainer,
      helpers.length === 0 && styles.noHelpers,
    )}>
      {helpers.length === 0 && (
        t('Helpers_None')
      )}
      {helpers.map((helper) => (
        <Helper helper={helper} key={helper._id}/>
      ))}
    </div>
  );

};
