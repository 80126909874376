import React, { FC, useMemo, useState } from 'react';

import {
  api,
  isPosition, useAsync, useLocale, useLogOperatorAction,
} from 'lib';
import { mdiMessageText } from '@mdi/js';
import { useSelector } from 'react-redux';
import { connectedCustomerSelector } from 'features/identity/exports';
import { toast } from 'react-toastify';
import { CheckboxField } from 'components';
import { alarmPositionSelector, currentPositionSelector } from 'features/details';
import { logOperatorAction } from 'features/details/chat';
import { ackedSelector } from 'features/details/ack/exports';
import { useSmsBuilder } from './useSmsBuilder';
import {
  BuilderForm, BaseFormState, initialFormState, useTemplates,
} from '../builderForm';
import type { ContactOptionImplProps } from '..';

export * from './option';

export type FormState = BaseFormState & {
  name: boolean;
  alarmPosition: boolean;
  currentPosition: boolean;
};

const disablePersonalInformation: Partial<FormState> = {
  name: false,
  alarmPosition: false,
  currentPosition: false,
};

export const SmsMessageModal: FC<ContactOptionImplProps> = (props) => {

  const {
    responder,
    onDismiss,
  } = props;

  const { t } = useLocale();

  const currentPosition = useSelector(currentPositionSelector);
  const alarmPosition = useSelector(alarmPositionSelector);

  const [formValues, setFormValues] = useState<FormState>({
    ...initialFormState,
    includeContactCode: !!responder.info.contactCode,
    name: true,
    alarmPosition: isPosition(alarmPosition),
    currentPosition: isPosition(currentPosition),
  });

  const customer = useSelector(connectedCustomerSelector);
  const smsOutput = useSmsBuilder(formValues, responder);

  const templates = useTemplates<FormState>(useMemo(() => [{
    label: 'Pand betreden veilig',
    value: {
      formState: disablePersonalInformation,
      message: 'Alles is veilig. U kunt het pand veilig betreden. Het alarm wordt voor u uitgeschakeld. Succes vandaag.',
    },
  }, {
    label: 'Pand verlaten veilig',
    value: {
      formState: disablePersonalInformation,
      message: 'Er zijn geen bijzonderheden geconstateerd. U kunt de vestiging verlaten. Het alarm wordt aangezet en is binnen 2 minuten actief. Fijne avond.',
    },
  }], []));

  const logAction = useLogOperatorAction(logOperatorAction, ackedSelector, {
    name: responder.info.name,
    sms: responder.actions.sms,
  });

  const sendSms = useAsync(async () => {

    onDismiss();

    logAction('ACTION_SMS');

    const promise = api.post('contact/sms', {
      to: [responder.actions.sms],
      body: smsOutput.getSmsBody(),
    });

    return toast.promise(promise, {
      pending: t('Alarm_Detail_Contact.Sms_Message.Toast.Sending'),
      success: t('Alarm_Detail_Contact.Sms_Message.Toast.Sent'),
      error: t('Alarm_Detail_Contact.Sms_Message.Toast.Error'),
    });

  }, [onDismiss, smsOutput, customer]);

  return (
    <BuilderForm
      {...props}
      title={t('Alarm_Detail_Contact.Sms_Message.Title')}
      titleIcon={mdiMessageText}
      subtitle={t('Alarm_Detail_Contact.Sms_Message.Subtitle')}
      templates={templates}
      send={sendSms}
      dataFields={(
        <>
          <CheckboxField
            name={'name'}
            label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Name')}
            size={'small'}
          />
          {isPosition(alarmPosition) && (
            <CheckboxField
              name={'alarmPosition'}
              label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Alarm_Position')}
              size={'small'}
            />
          )}
          {isPosition(currentPosition) && (
            <CheckboxField
              name={'currentPosition'}
              label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Current_Position')}
              size={'small'}
            />
          )}
        </>
      )}
      formValues={formValues}
      onSubmit={setFormValues}
      outputTitle={t('Alarm_Detail_Contact.Sms_Message.Output.Title')}
    >
      <smsOutput.component/>
    </BuilderForm>
  );

};
