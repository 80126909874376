import { useSelector } from 'react-redux';
import { openAlarmsStateSelector } from 'features/overview/openAlarms';
import { useQuery } from 'lib';

export const useRedirectToAlarmOfAsset = (): string | null => {

  const openAlarmsState = useSelector(openAlarmsStateSelector);
  const targetAsset: number | null = useQuery('toAlarmOfAsset', (value) => {

    return (typeof value === 'string' && Number.parseInt(value, 10)) || null;

  });

  if (openAlarmsState.status.fulfilled && targetAsset) {

    const targetAlarm = openAlarmsState.value.find((alarm) => {

      // eslint-disable-next-line
      return alarm.asset['telluId'] === targetAsset;

    });

    return targetAlarm?._id ?? null;

  }

  return null;

};
