import { pause } from '../helpers';
import type { WaitProvider } from './createThunk';

export type ExponentialBackoffOptions = {
  minWait: number;
  maxWait: number;
  factor: number;
};

export const getExponentialBackoff = (options: ExponentialBackoffOptions): WaitProvider => {

  let wait = options.minWait;

  return async () => {

    await pause(wait);

    wait = Math.min(wait * options.factor, options.maxWait);

    return true;

  };

};
