import { useLocation } from 'react-router-dom';
import { parse, ParsedQs } from 'qs';
import { useDeepMemo } from './useDeepMemo';

/**
 * Return the current parsed location search
 * Return object has a stable identity
 */
export const useSearch = (): ParsedQs => {

  const { search } = useLocation();

  return useDeepMemo(() => {

    if (search.length > 0) {

      // Slice off the '?' at the start of the search string, parse as an object, and return the value
      return parse(search.slice(1));

    }

    return {};

  }, [search]);

};
