import { useCallback, useState } from 'react';

export const useStack = <T>(firstValue: T): {
  last: T;
  push: ((value: T) => void);
  pop: () => void;
} => {

  const [state, setState] = useState<T[]>([firstValue]);

  return {
    last: state[state.length - 1],
    push: useCallback((value) => {

      setState([...state, value]);

    }, [state, setState]),
    pop: useCallback(() => {

      setState(state.slice(0, -1));

    }, [state, setState]),
  };

};
