import { LegacyStoredToken, AnyStoredToken, StoredToken } from 'types';

export const isLegacyStoredToken = (token: AnyStoredToken): token is LegacyStoredToken => {

  return !!(token as LegacyStoredToken).token && !!(token as LegacyStoredToken).username;

};

export const isStoredToken = (token: AnyStoredToken): token is StoredToken => {

  return !!(token as StoredToken).refreshToken;

};
