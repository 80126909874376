import React, { FC, useState } from 'react';
import textAreaStyles from 'components/molecules/textAreaField/style.module.scss';

import cx from 'clsx';

import { Chip, ButtonArea, Text } from 'components/atoms';
import styles from './style.module.scss';
import type { SurveyStep } from '../survey';

type TextAreaProps = {
  title: string;
  nextStep: SurveyStep;
  onAnswer: (value: string, nextStep: SurveyStep) => void;
  placeholder: string;
  label: string;
};

export const TextArea: FC<TextAreaProps> = ({
  title,
  nextStep,
  onAnswer,
  placeholder,
  label,
}) => {

  const [text, setText] = useState<string>('');

  return (
    <>
      <Text variant={'h6'} textAlign={'center'} className={styles.title}>
        {title}
      </Text>
      <textarea
        className={cx(
          textAreaStyles.textarea,
          styles.textarea,
        )}
        value={text}
        onChange={(e) => setText(e.target.value || '')}
        placeholder={placeholder}
      />
      <ButtonArea>
        <Chip
          label={label}
          variant={'primary'}
          size={'large'}
          onClick={() => onAnswer(text, nextStep)}
        />
      </ButtonArea>
    </>
  );

};
