import React, { memo } from 'react';

import { sortBy } from 'lodash';

import { WithAsync, ErrorDisplay } from 'components';
import { ResponseGroup } from '@x-guard/xgac-types/xgac';
import { ResponseSectionGroup } from './responseSectionGroup';
import styles from './style.module.scss';
import type { EssentialStatePick } from '../index';

type ResponseSectionProps = {
  classNameMap: Record<string, string>;
  state: EssentialStatePick<ResponseGroup[]>;
};

export const ResponseSection = memo<ResponseSectionProps>(({
  state,
  classNameMap,
}) => {

  return (
    // Render when loaded
    <WithAsync
      status={state.status}
      error={() => (
        <ErrorDisplay
          className={styles.error}
          isRetrying={state.isRetrying}
          {...state.error}
        />
      )}
    >
      {() => sortBy(state.value, (group) => group.priority).map((group, index) => (
        <ResponseSectionGroup
          key={`${group.priority}-${index}`}
          group={group}
          className={classNameMap[group.type]}
        />
      ))}
    </WithAsync>
  );

});
