import React, { FC, useCallback, useContext } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { mdiChevronRight } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocale } from 'lib';
import { orderBy } from 'lodash';
import { authenticatedSelector } from 'features/identity/auth';
import { Title } from './title';
import { useHeaderLocation } from './useHeaderLocation';
import { headerContext } from './headerContext';
import styles from './style.module.scss';

export const Location: FC = () => {

  const { t } = useLocale();
  const navigate = useNavigate();
  const authenticated = useSelector(authenticatedSelector);

  const backToRoot = useCallback(() => {

    navigate(authenticated ? '/alarms' : '/');

  }, [authenticated, navigate]);

  const { path } = useContext(headerContext);

  return (
    <div className={styles.locationStack}>
      <div className={styles.locationContainer}>
        <Title
          backToRoot={backToRoot}
          forceShowIcon={path.length > 2}
        />
        {orderBy(path, 'order').map((location, idx) => {

          const isLast = idx === path.length - 1;

          return (
            <div
              key={idx}
              className={cx(
                styles.location,
                isLast && styles.last,
              )}
              onClick={() => {

                navigate(location.location);

              }}
            >
              <Icon path={mdiChevronRight}/>
              <div className={styles.locationText}>
                {location.label}
              </div>
            </div>
          );

        })}
        {authenticated === false && (
          <div className={styles.unauthorized}>
            {t('TopBar_Title')}
          </div>
        )}
      </div>
    </div>
  );

};

export {
  headerContext,
  useHeaderLocation,
};
