import React, { FC } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { ErrorDisplay } from 'components';
import {
  conferenceStatusSelector,
  connectedSelector,
  errorSelector,
  statusSelector,
} from 'features/details/dcc';
import { useSelector } from 'react-redux';
import { DccStatus, FetchDccRecordingReturn } from 'types';
import { useLocale } from 'lib';
import { mdiPhoneOff } from '@mdi/js';
import { dccRecordingSrcSelector } from 'features/details/dcc/recording';
import { ackedSelector } from 'features/details/ack/exports';
import Participants from './participants';
import Disconnected from './disconnected';
import Connected from './connected';
import styles from './style.module.scss';
import Recording from './recording';
import DCCLoader from './loader';
import DCCDestroyed from './destroyed';

type DCCSwitchProps = {
  dccStatus: DccStatus;
  recording: FetchDccRecordingReturn;
};

const DCCSwitch: FC<DCCSwitchProps> = ({ recording, dccStatus }) => {

  const connected = useSelector(connectedSelector);
  const { t } = useLocale();

  if (dccStatus === DccStatus.Created) {

    return (
      <div className={styles.created}>
        {connected ? <Connected/> : <Disconnected/>}
        <Participants/>
      </div>
    );

  }

  if (recording) {

    return <Recording recording={recording}/>;

  }

  if (dccStatus === DccStatus.Destroyed) {

    return <DCCDestroyed/>;

  }

  if (dccStatus === DccStatus.NotCreated) {

    return (
      <div className={cx(styles.shortStatus, styles.notCreated)}>
        <Icon path={mdiPhoneOff}/>
        <span>{t('Alarm_Detail_DCC.Not_Created')}</span>
      </div>
    );

  }

  return null;

};

export const DynamicConferenceCall: FC = () => {

  const status = useSelector(statusSelector);
  const error = useSelector(errorSelector);
  const conferenceStatus = useSelector(conferenceStatusSelector);
  const recording = useSelector(dccRecordingSrcSelector);
  const acked = useSelector(ackedSelector);

  if (status.idle || acked) return null;

  return (
    <div
      tabIndex={-1}
      className={cx(
        styles.dynamicConferenceCall,
      )}
    >
      {/**
        * If we loaded, and a conference exists, display either the disconnected or connected view,
        * depending on the connected state of the user
        */}
      {status.pending && (
        <DCCLoader/>
      )}
      {status.fulfilled && (
        <DCCSwitch
          recording={recording}
          dccStatus={conferenceStatus}
        />
      )}
      {status.rejected && (
        <ErrorDisplay {...error}/>
      )}
    </div>
  );

};
