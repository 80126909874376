import React, { FC } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { useImageOverlayContext } from 'lib';
import { mdiMagnify } from '@mdi/js';
import styles from './style.module.scss';

type ImageProps = {
  src: string;
  alt: string;
  className?: string;
};

/**
 * Image supporting a fullscreen popup that display the image
 */
export const Image: FC<ImageProps> = ({
  src,
  alt,
  className,
}) => {

  const { setSrc } = useImageOverlayContext();

  return (
    <div
      className={cx(styles.container, className)}
      onClick={(e) => {

        e.preventDefault();
        e.stopPropagation();

        setSrc(src);

      }}
    >
      <div className={styles.shadow}>
        <Icon path={mdiMagnify}/>
      </div>
      <img
        src={src}
        alt={alt}
      />
    </div>
  );

};
