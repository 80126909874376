import React, { FC, ReactNode, useMemo } from 'react';

import cx from 'clsx';
import { Icon } from '@mdi/react';

import { FieldInputProps } from 'react-final-form';
import { uniqueId } from 'lodash';
import { mdiCheck } from '@mdi/js';
import styles from './style.module.scss';

export type CheckboxSize = 'medium' | 'small' | 'extra-small';

export type CheckboxTheme = 'primary' | 'green';

type CheckboxControlProps = {
  size: CheckboxSize;
  input: Partial<FieldInputProps<string | number, HTMLInputElement>>;
  label: ReactNode;
  className?: string;
  theme?: CheckboxTheme;
};

export const CheckboxControl: FC<CheckboxControlProps> = ({
  size,
  input,
  label,
  className,
  theme = 'primary',
}) => {

  const id = useMemo(() => uniqueId('checkbox-'), []);

  return (
    <div className={cx(
      styles.field,
      styles[size],
      styles[theme],
      input.checked && styles.checked,
      className,
    )}>
      <div className={styles.inputContainer}>
        <input
          id={id}
          type={'checkbox'}
          {...input}
        />
        <Icon path={mdiCheck}/>
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );

};
