import React, { FC } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { useDistanceLabel, useLocale } from 'lib';
import { mdiEarth, mdiHome, mdiMapMarkerOffOutline } from '@mdi/js';
import { PointGeometry } from '@x-guard/xgac-types/xgac';
import styles from './style.module.scss';

type AddressProps = {
  position: PointGeometry;
  alarm: boolean;
  label: string;
};

type Zone = 'inside' | 'outside' | 'unknown';

export const Address: FC<AddressProps> = ({
  position,
  alarm,
  label,
}) => {

  const { t } = useLocale();

  const accuracy = useDistanceLabel(position?.properties?.accuracy);

  const zonalPosition = position?.properties?.provider?.startsWith('ips') ? 'inside' : 'outside';

  const zone: Zone = position?.properties?.provider
    ? zonalPosition
    : 'unknown';

  const zonalIcon = zone === 'inside' ? mdiHome : mdiEarth;

  const iconPath = zone === 'unknown'
    ? mdiMapMarkerOffOutline
    : zonalIcon;

  const address = zone === 'unknown'
    ? t('Address_Unknown')
    : position.properties.address.formattedAddress;

  const anchorProps = position ? {
    href: `https://maps.google.com/maps?q=${position.coordinates[1]},${position.coordinates[0]}`,
    className: cx(styles.sub2, styles.hasHref),
    title: t('Address_View'),
    target: '_blank',
  } : {
    className: styles.sub2,
  };

  return (
    <div>
      <div className={styles.title}>
        <span className={alarm ? 'red' : 'primary'}>{label}</span>
        {zone !== 'unknown' && (
          <>
            &nbsp;
            <span>{zone === 'inside' ? t('Address_Inside') : t('Address_Outside')}</span>
          </>
        )}
      </div>
      <div className={styles.text}>
        <div className={styles.sub1}>
          <Icon path={iconPath}/>
        </div>
        <a {...anchorProps}>
          <span className={styles.address}>{address}</span>
          {(zone === 'outside' && position.properties.accuracy !== null) && (
            <span className={styles.accuracy}>
              {t('Address_Accuracy', { accuracy })}
            </span>
          )}
        </a>
      </div>
    </div>
  );

};
