import React, {
  CSSProperties, FC, useLayoutEffect, useMemo, useRef, useState,
} from 'react';

import { Icon } from '@mdi/react';

import { AnimatedButton, ButtonArea } from 'components/atoms';
import { mdiClose, mdiArrowLeft } from '@mdi/js';
import { getChildrenHeight } from 'lib';
import cx from 'clsx';
import styles from './style.module.scss';

type ModalTitleProps = {
  text: string;
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
};

export const ModalTitle: FC<ModalTitleProps> = ({
  text,
  onBack,
  onClose,
  className,
}) => {

  const [controlAreaHeight, setControlAreaHeight] = useState<number>();
  const controlAreaRef = useRef<HTMLDivElement>();

  // Get the height of the button area
  useLayoutEffect(() => {

    setControlAreaHeight(getChildrenHeight(controlAreaRef));

  }, [controlAreaRef]);

  // Compute the style for the control area
  const controlAreaStyle = useMemo<CSSProperties>(() => {

    return {
      height: `${onBack ? controlAreaHeight : 0}px`,
    };

  }, [controlAreaHeight, onBack]);

  return (
    <>
      <div className={cx(styles.modalTitle, className)}>
        <h4>{text}</h4>
        <div className={styles.modalTitleButtons}>
          {onClose && (
            <div className={styles.icon} onClick={() => onClose()}>
              <Icon path={mdiClose}/>
            </div>
          )}
        </div>
      </div>
      <div
        ref={controlAreaRef}
        className={styles.controlAreaContainer}
        style={controlAreaStyle}
      >
        <ButtonArea className={styles.controlArea}>
          <AnimatedButton
            onClick={onBack}
            iconPath={mdiArrowLeft}
            label={'Terug'}
          />
        </ButtonArea>
      </div>
    </>
  );

};
