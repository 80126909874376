import React, { CSSProperties, FC } from 'react';

import { Item, Span } from 'react-html-email';

type FontSize = '18px' | '14px' | '10px';

type TextProps = {
  padding: string;
  textAlign?: CSSProperties['textAlign'];
  fontWeight?: CSSProperties['fontWeight'];
  color?: CSSProperties['color'];
  fontSize?: FontSize;
  backgroundColor?: CSSProperties['backgroundColor'];
};

export const Text: FC<TextProps> = ({
  padding,
  children,
  textAlign = 'initial',
  fontWeight = 'normal',
  color = 'black',
  fontSize = '14px',
  backgroundColor = 'transparent',
}) => {

  return (
    <Item style={{
      padding,
      lineHeight: 1.2,
      textAlign,
      backgroundColor,
    }}>
      <Span style={{
        fontWeight,
        fontSize,
        color,
      }}>
        {children}
      </Span>
    </Item>
  );

};
