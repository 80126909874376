import React, {
  FC, useEffect, useMemo, useState, MutableRefObject,
} from 'react';
import cx from 'clsx';
import { Resizable } from 'react-resizable';
import {
  useLocale, useToggle, areSamePosition, latLng, OMSClass,
} from 'lib';
import { Map, Marker } from 'leaflet';
import {
  MapContainer, MapContainerProps, Pane, TileLayer,
} from 'react-leaflet';
import { useSelector } from 'react-redux';
import { currentPositionSelector, alarmPositionSelector } from 'features/details';
import { focusedPositionSelector, valueSelector } from 'features/details/map';
import styles from './style.module.scss';

import { Controls } from './controls';
import { CustomMarker } from './marker';
import { ImageOverlays } from './imageOverlays';
import { Label } from './label';
import { useAutoZoom } from './useAutoZoom';
import { useResize } from './useResize';
import { useFitBounds } from './useFitBounds';

export type OMS = {
  addMarker: (marker: Marker) => void;
  removeMarker: (marker: Marker) => void;
};

const OverlappingMarkerSpiderfier = OMSClass as unknown as {
  new(map: Map, options?: {
    nearbyDistance?: number;
    legLengthMultiplier?: number;
  }): OMS;
};

type AlarmMapProps = {
  parentItemRef: MutableRefObject<HTMLDivElement>;
  fullscreen: boolean;
  toggleFullscreen: () => void;
};

/**
 * Alarm map
 */
export const AlarmMap: FC<AlarmMapProps> = ({
  parentItemRef,
  fullscreen,
  toggleFullscreen,
}) => {

  const [map, setMap] = useState<Map>(null);

  const { t } = useLocale();

  const [autoZoom, toggleAutoZoom] = useToggle(true);
  const [renderImageOverlays, toggleRenderImageOverlays] = useToggle(true);
  const fitBounds = useFitBounds(map);

  const imageOverlays = useSelector(valueSelector);

  const oms = useMemo(() => {

    if (!map) return undefined;

    return new OverlappingMarkerSpiderfier(map, {
      nearbyDistance: 50,
      legLengthMultiplier: 5,
    });

  }, [map]);

  useAutoZoom(map, autoZoom, toggleAutoZoom);

  // Invalidate the map sizing when fullscreen mode changes
  useEffect(() => {

    map?.invalidateSize();

  }, [fullscreen, map]);

  const alarmPosition = useSelector(alarmPositionSelector);
  const currentPosition = useSelector(currentPositionSelector);

  const hideCurrentPosition = alarmPosition && currentPosition && areSamePosition(alarmPosition, currentPosition);

  const focusedPosition = useSelector(focusedPositionSelector);

  useEffect(() => {

    if (focusedPosition) {

      fitBounds([focusedPosition]);

    }

  }, [fitBounds, focusedPosition]);

  const extraMapContainerProps = useMemo<Pick<MapContainerProps, 'center' | 'zoom'>>(() => {

    if (currentPosition) {

      return {
        center: latLng(currentPosition),
        zoom: 14,
      };

    }

    return {
      center: [52.312500, 5.548611],
      zoom: 7,
    };

  }, [currentPosition]);

  const [size, onResize] = useResize(parentItemRef, () => {

    map?.invalidateSize();

  });

  const resizable = !fullscreen;

  return (
    <Resizable
      className={cx(styles.mapContainer, resizable && styles.resizable)}
      height={size.height}
      width={size.width}
      onResize={onResize}
      axis={'x'}
      resizeHandles={['w']}
      handle={(
        <div className={styles.handleArea}>
          <div/>
        </div>
      )}
    >
      <div className={styles.map} style={resizable ? {
        flexBasis: `${size.width}px`,
      } : {}}>
        <MapContainer
          ref={setMap}
          maxZoom={22}
          minZoom={4}
          className={styles.map}
          zoomControl={false}
          {...extraMapContainerProps}
        >
          <Controls
            fullscreen={fullscreen}
            toggleFullscreen={toggleFullscreen}
            autoZoom={autoZoom}
            toggleAutoZoom={currentPosition ? toggleAutoZoom : null}
            renderImageOverlays={renderImageOverlays}
            toggleRenderImageOverlays={(imageOverlays?.length ?? 0) > 0 ? toggleRenderImageOverlays : null}
          />
          <TileLayer
            maxZoom={22}
            maxNativeZoom={19}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Pane name={'responseMarkers'}>
            {/* TODO: Add response support oms && (
              <DynamicResponseMarkers oms={oms}/>
            ) */}
          </Pane>
          <Pane name={'alarmMarkers'}>
            {alarmPosition && (
              <CustomMarker
                oms={oms}
                position={alarmPosition}
                variant={'alarmPosition'}
                label={(
                  <Label text={t('Alarm_Detail_Map.Marker.Alarm_Position.Label')}/>
                )}
              />
            )}
            {!hideCurrentPosition && currentPosition && (
              <CustomMarker
                oms={oms}
                position={currentPosition}
                variant={'currentPosition'}
                label={(
                  <Label text={t('Alarm_Detail_Map.Marker.Current_Position.Label')}/>
                )}
              />
            )}
            {renderImageOverlays && (
              <ImageOverlays/>
            )}
          </Pane>
        </MapContainer>
      </div>
    </Resizable>
  );

};
