import { NODE_ENV, XG_SENTRY_KEY } from 'config';
import { isLocalhost } from 'lib/helpers/window';
import { captureException, init } from '@sentry/react';
import type { ScopeContext } from '@sentry/types';
import { SerializedError } from '@reduxjs/toolkit';

const isSerializedError = (error: unknown): error is SerializedError => {

  return !!error
    && typeof error === 'object'
    && !(error instanceof Error)
    && 'name' in error
    && 'stack' in error
    && 'message' in error;

};

/**
 * Logger Class
 * Makes all the console logs visible in the developer tools.
 *
 * @version 1.0.0
*/
class Logger {

  public constructor() {

    if (!isLocalhost()) {

      init({
        dsn: XG_SENTRY_KEY,
        normalizeDepth: 10,
        ignoreErrors: [
          // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
          'ResizeObserver loop limit exceeded',
        ],
      });

    }

  }

  public getExceptionContext(context: Record<string, unknown>): Partial<ScopeContext> {

    return {
      extra: {
        ...context,
      },
    };

  }

  public debug(...args: unknown[]): void {

    Array.prototype.unshift.call(args, 'Debug -');

    if (NODE_ENV === 'development') {

      console.debug.apply(this, args);

    }

  }

  public info(...args: unknown[]): void {

    Array.prototype.unshift.call(args, 'Info -');

    if (NODE_ENV === 'development') {

      console.info.apply(this, args);

    }

  }

  public warn(...args: unknown[]): void {

    Array.prototype.unshift.call(args, 'Warning -');

    console.warn.apply(this, args);

  }

  public error(e: Error | SerializedError | string | string[], context: Record<string, unknown> = {}): void {

    if (Array.isArray(e)) {

      e = e.join(' ');

    }

    if (isSerializedError(e)) {

      const tempError = new Error();
      tempError.message = e.message || 'Unknown error message';
      tempError.name = e.name || 'Unknown error name';
      tempError.stack = e.stack;
      e = tempError;

    }

    captureException(e, this.getExceptionContext(context));

    console.error.apply(this, ['Error - ', e, context]);

  }

}

export const logger = new Logger();
