import { FC, useMemo, useRef } from 'react';
import { debounce, isEqual } from 'lodash';
import { useFormState } from 'react-final-form';
import { FormState } from 'final-form';

type AutoSaveProps = {
  save: (values: object) => void;
  wait: number;
};

/**
 * Component used to capture changes in form state values
 */
export const AutoSave: FC<AutoSaveProps> = ({ save, wait }) => {

  // Use a ref instead of state, because we don't need to rerender when setting the value
  const prevValues = useRef<object | null>(null);

  // Debounced callback
  const saveCallback = useMemo(() => debounce((state: FormState<object>) => {

    const { values } = state;

    // Don't cause a save if we don't have any values set
    if (prevValues.current === null) {

      prevValues.current = values;
      return;

    }

    // Save if previous and current values are not deeply equal
    if (isEqual(prevValues.current, values) === false) {

      prevValues.current = values;

      // User defined callback. Usually form.submit
      save(values);

    }

  }, wait), [save, wait]);

  useFormState({
    onChange: saveCallback,
  });

  return null;

};
