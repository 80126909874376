import { createContext } from 'react';

import { APRAction, ContactableConfig } from 'types';
import { noop } from 'lib';

export type AlarmContext = {
  onClickAPRAction: (action: APRAction) => void;
  showAprAction: boolean;
  aprAction: APRAction | null;
  onDismissAprAction: () => void;
  onClickContact: (config: ContactableConfig) => void;
  showContact: boolean;
  contact: ContactableConfig | null;
  onDismissContact: () => void;
  enableAckForm: boolean;
  onRequestAckForm: () => void;
  onDismissAckForm: () => void;
};

export const alarmContext = createContext<AlarmContext>({
  onClickAPRAction: noop,
  showAprAction: false,
  aprAction: null,
  onDismissAprAction: noop,
  onClickContact: noop,
  showContact: false,
  onDismissContact: noop,
  contact: null,
  enableAckForm: false,
  onRequestAckForm: noop,
  onDismissAckForm: noop,
});
