import { useCallback, useMemo } from 'react';
import { LoadState, PaginatedAsyncState, brand } from 'types';

export type UsePaginatedStateReturn<T> = Omit<PaginatedAsyncState<T>, typeof brand> & {
  paginate: {
    hasMore: boolean;
    loadMore: () => void;
  };
  // Extra properties derived from state that helps the consumer display the content properly
  meta: {
    loadedWithNoContent: boolean;
    isInitiallyLoading: boolean;
    centerContent: boolean; // Whether the content should be centered, instead of displayed as a list
  };
};

/**
 * Hooks into a slice of global state and returns props needed to render a paginated list
 *
 * NOTE: Once the current total has been queried, no more requests are allowed. This is because new content should be
 * pushed with websockets, and not manually polled
 */
export const usePaginatedState = <TResult, TSelectedResult = TResult>(
  state: PaginatedAsyncState<TResult>,
  fetchMoreItems: (lastItem: TResult | null) => void,
  resultTransformer: null | ((state: TResult[]) => TSelectedResult[]),
): UsePaginatedStateReturn<TSelectedResult> => {

  // Maps TResult to TSelectedResult using the result transformer. If it isn't provided, just return state.result
  const result = useMemo(() => {

    return resultTransformer?.(state.value) ?? state.value;

  }, [resultTransformer, state.value]);

  // Get whether or not more items can be queried
  const hasMoreItems = useMemo(() => {

    if (state.value === null && state.error !== null) {

      return true;

    }

    return state.value.length < state.total;

  }, [state]);

  // Guard the fetch by making sure it's only called when we aren't currently requesting more results
  const loadMoreItems = useCallback(() => {

    // Do fetch more items if we aren't already and we haven't encountered any errors
    if (hasMoreItems && state.realStatus.loadState !== LoadState.Settling) {

      // Select the last item, if it exists, otherwise null
      const param = (state.value?.length ?? 0) > 0
        ? state.value[state.value.length - 1]
        : null;

      fetchMoreItems(param);

    }

  }, [state, hasMoreItems, fetchMoreItems]);

  // Return items
  return useMemo<UsePaginatedStateReturn<TSelectedResult>>(() => {

    const loadedWithNoContent = state.status.loadState === LoadState.Settled && result.length === 0;
    const isInitiallyLoading = state.status.loadState === LoadState.Settling && result.length === 0;
    const centerContent = loadedWithNoContent || isInitiallyLoading;

    return {
      ...state,
      value: result as TSelectedResult[],
      paginate: {
        hasMore: hasMoreItems,
        loadMore: loadMoreItems,
      },
      meta: {
        loadedWithNoContent,
        isInitiallyLoading,
        centerContent,
      },
    };

  }, [state, loadMoreItems, hasMoreItems, result]);

};
