import React, { Component, PropsWithChildren, ReactNode } from 'react';

import { logger } from 'classes/logger';
import { ErrorDisplay } from 'components/atoms';

type ErrorBoundaryProps = PropsWithChildren<{
  name?: string;
}>;

type ErrorBoundaryState = {
  error: Error;
  hasError: boolean;
};

/**
 * Capture uncaught javascript errors of children.
 * Displays a fallback UI in case of an error
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {

  public constructor(props: ErrorBoundaryProps) {

    super(props);

    this.state = {
      error: null,
      hasError: false,
    };

  }

  private static getDerivedStateFromError(error: Error): ErrorBoundaryState {

    // Update state so the next render will show the fallback UI.
    return {
      error,
      hasError: true,
    };

  }

  // Log caught error
  public componentDidCatch(error: Error, errorInfo: unknown): void {

    logger.error(error, { errorInfo });

  }

  // Render error display if we caught an error, otherwise just render children
  public render(): ReactNode {

    if (this.state.hasError) {

      return <ErrorDisplay message={this.state.error.message} />;

    }

    return this.props.children;

  }

}
