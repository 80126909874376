import React, { FC } from 'react';
import colors from 'style/colors.module.scss';

import {
  Box, Image, Item, Span,
} from 'react-html-email';

export const Header: FC = () => {

  return (
    <>
      <Item
        align={'center'}
        style={{
          backgroundColor: 'white',
          maxWidth: '600px',
        }}
      >
        <Box style={{
          margin: '20px 20px 20px 20px',
        }}>
          <Image
            alt={'X-Guard logo'}
            height={75}
            src={'https://download.x-guard.nl/x-guard-header.png'}
            width={300}
          />
        </Box>
      </Item>
      <Item style={{
        backgroundColor: colors.primary,
        padding: '12px 24px',
      }}>
        <Span style={{
          color: 'white',
        }}>
          {'Alarmmelding'}
        </Span>
      </Item>
    </>
  );

};
