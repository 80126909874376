import React, { FC, useMemo } from 'react';

import cx from 'clsx';

import { frozenInfoSelector, procedurePrioritySelector } from 'features/details';
import { useSelector } from 'react-redux';
import { staticResponseSelector } from 'features/details/response/static';
import { dynamicResponseSelector } from 'features/details/response/dynamic';
import {
  AsyncPollableState, AsyncStatus,
} from 'types';
import { ResponseGroup } from '@x-guard/xgac-types/xgac';
import { isEqual } from 'lodash';
import { ResponseSection } from './responseSection';
import { AlarmCreatorSection } from './alarmCreatorSection';
import styles from './style.module.scss';
import { useResponseRefetch } from './useResponseRefetch';

export type EssentialStatePick<T> = Pick<AsyncPollableState<T>, 'value' | 'status' | 'error' | 'isRetrying'>;

export const Response: FC = () => {

  const priority = useSelector(procedurePrioritySelector);
  const staticState = useSelector(staticResponseSelector, isEqual);
  const dynamicState = useSelector(dynamicResponseSelector, isEqual);
  const frozenInfo = useSelector(frozenInfoSelector);

  useResponseRefetch();

  // When static and dynamic states are merged, we have the freedom to sort the items however we want
  const mergedState = useMemo<EssentialStatePick<ResponseGroup[]>>(() => {

    const state = (value: ResponseGroup[]) => ({
      value,
      status: AsyncStatus.Fulfilled,
      error: null,
      isRetrying: false,
    });

    if (frozenInfo) {

      return state([...frozenInfo.dynamicResponse, ...frozenInfo.staticResponse]);

    }

    if (!(dynamicState.status.fulfilled && staticState.status.fulfilled)) {

      return null;

    }

    return state([...dynamicState.value, ...staticState.value]);

  }, [frozenInfo, dynamicState, staticState]);

  return (
    <div className={cx(
      styles.container,
      styles[priority],
    )}>
      <AlarmCreatorSection/>
      {mergedState ? (
        <ResponseSection classNameMap={styles} state={mergedState}/>
      ) : (
        <>
          <ResponseSection classNameMap={styles} state={dynamicState}/>
          <ResponseSection classNameMap={styles} state={staticState}/>
        </>
      )}
    </div>
  );

};
