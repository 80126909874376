import React, { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { store } from 'features';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import {
  RootSwitch, HeaderProvider, Banner, UnsupportedDevicePopup, useUnsupportedDevicePopup, ErrorBoundary,
} from 'components';
import { UserSnap, standardTheme } from 'lib';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './style.module.scss';

export const App: FC = () => {

  const [unsupportedDevicePopupIsRendering, popupProps] = useUnsupportedDevicePopup();

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <UserSnap/>
      </ErrorBoundary>
      <ThemeProvider theme={standardTheme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <Router>
            <div className={styles.app}>
              <Banner/>
              {unsupportedDevicePopupIsRendering && (
                <UnsupportedDevicePopup {...popupProps}/>
              )}
              <HeaderProvider hide={unsupportedDevicePopupIsRendering}>
                <RootSwitch hide={unsupportedDevicePopupIsRendering}/>
              </HeaderProvider>
            </div>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );

};
