import { useCallback } from 'react';
import { Moment } from 'moment';

type DateFormatter = (value: Moment) => string;

export const useDateTimePickerFormatter = (): {
  formatter: DateFormatter;
  shortFormat: string;
  longFormat: string;
} => {

  // Formats should originate from loco
  const shortFormat = 'YYYY/MM/DD';
  const longFormat = 'YYYY/MM/DD HH:mm';

  return {
    shortFormat,
    longFormat,
    formatter: useCallback<DateFormatter>((moment) => {

      if (!moment) {

        return '';

      }

      return moment.format(longFormat);

    }, [longFormat]),
  };

};
