import React, { FC, useMemo, useState } from 'react';

import logoWhite from 'assets/images/logo_white.png';
import header1 from 'assets/images/header_1.png';
import header2 from 'assets/images/header_2.png';

import { isMobile } from 'mobile-device-detect';
import { useBreakpoint, useLocale } from 'lib';
import { TranslationKey } from 'config/i18n';
import { Chip } from 'components/atoms';
import styles from './style.module.scss';

export type PopupReason = 'Resolution' | 'Mobile_Device';

export const useUnsupportedDevicePopup = (): [boolean, UnsupportedDevicePopupProps] => {

  const [breakpoint] = useBreakpoint([1280]);
  const [dismissed, setDismissed] = useState(false);

  const displayPopup: PopupReason = useMemo(() => {

    if (!dismissed) {

      if (isMobile) {

        return 'Mobile_Device';

      }

      if (breakpoint === 0) {

        return 'Resolution';

      }

    }

    return undefined;

  }, [breakpoint, dismissed]);

  return useMemo(() => {

    return [
      !!displayPopup,
      {
        dismiss: () => setDismissed(true),
        reason: displayPopup,
      },
    ];

  }, [displayPopup]);

};

export type UnsupportedDevicePopupProps = {
  dismiss: () => void;
  reason: PopupReason;
};

export const UnsupportedDevicePopup: FC<UnsupportedDevicePopupProps> = ({
  dismiss,
  reason,
}) => {

  const { t } = useLocale();

  return (
    <div className={styles.popup}>
      <div className={styles.header}>
        {t('TopBar_Main')}
      </div>
      <div className={styles.content}>
        <img src={logoWhite} alt='logo' className={styles.logo} />
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <div className={styles.title}>
              {/* uses-translation-asset: "Unsupported_Device_Popup.Title.*" */}
              {t((`Unsupported_Device_Popup.Title.${reason}`) as TranslationKey)}
            </div>
            <div className={styles.body}>
              <div>
                {/* uses-translation-asset: "Unsupported_Device_Popup.Body.*" */}
                {t((`Unsupported_Device_Popup.Body.${reason}`) as TranslationKey)}
              </div>
              <div className={styles.continue}>
                <Chip
                  className={styles.continueChip }
                  size={'large'}
                  variant={'primary'}
                  label={t('Unsupported_Device_Popup.Continue')}
                  onClick={dismiss}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomImages}>
          <img src={header1} alt='header' className={styles.person} />
          <img src={header2} alt='header' className={styles.emergencyTeam} />
        </div>
      </div>
    </div>
  );

};
