import React, { FC } from 'react';

import cx from 'clsx';

import { useLocale } from 'lib';
import styles from './style.module.scss';
import { Granularity } from './useDateTimePickerFieldState';

type GranularitySelectorProps = {
  granularity: Granularity;
  setGranularity: (value: Granularity) => void;
};

const Button: FC<GranularitySelectorProps & { targetGranularity: 'days' | 'hours' }> = ({
  granularity,
  setGranularity,
  targetGranularity,
}) => {

  const { t } = useLocale();

  const active = granularity === targetGranularity;

  return (
    <div
      className={cx(
        styles.granularitySelectorButton,
        active && styles.active,
      )}
      onClick={() => {

        if (!active) {

          setGranularity(targetGranularity);

        }

      }}
    >
      <span>
        {/* uses-translation-asset: "DateTimePickerField.granularity.*" */}
        {t(`DateTimePickerField.granularity.${targetGranularity}`)}
      </span>
    </div>
  );

};

export const GranularitySelector: FC<GranularitySelectorProps> = (props) => {

  return (
    <div className={styles.granularitySelector}>
      <Button {...props} targetGranularity={'days'}/>
      <Button {...props} targetGranularity={'hours'}/>
    </div>
  );

};
