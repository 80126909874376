import React, { memo, useCallback } from 'react';

import { Icon } from '@mdi/react';

import { childController, noop, useLocale } from 'lib';
import { Chip, CheckboxControl, Loader } from 'components';
import { mdiPhone } from '@mdi/js';
import { DCC_PHONE_NUMBER_RAW, DCC_PHONE_NUMBER_FORMATTED } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import {
  codeSelector,
  conferenceSidSelector,
  mutedSelector,
  signalSelector,
} from 'features/details/dcc';
import { setMute } from 'features/details/dcc/muted';
import styles from './style.module.scss';

const Disconnected = memo(() => {

  const { t } = useLocale();

  const dispatch = useDispatch();
  const conferenceSid = useSelector(conferenceSidSelector);
  const signal = useSelector(signalSelector);
  const muted = useSelector(mutedSelector);
  const code = useSelector(codeSelector);
  const onClickPhoneNumber = useCallback(noop, []);

  const onChangeMute = useCallback(() => {

    dispatch(setMute({
      controller: childController(signal),
      value: !muted,
      conferenceSid,
    }));

  }, [muted, conferenceSid, dispatch, signal]);

  return (
    <div className={styles.disconnected}>
      <div>
        <span className={styles.title}>
          {t('Alarm_Detail_DCC.Disconnected.Title')}
        </span>
        <div className={styles.joinCodeLayout}>
          <span className={styles.joinCode}>{code === null ? (
            <Loader
              variant={'small'}
              theme={'light'}
            />
          ) : code}</span>
          <span className={styles.joinCodeTooltip}>{t('Alarm_Detail_DCC.Disconnected.Tooltip')}</span>
        </div>
      </div>
      <div className={styles.right}>
        <Chip
          variant={'green250'}
          size={'large'}
          onClick={onClickPhoneNumber}
          htmlTag={'a'}
          extraProps={{
            href: `tel:${DCC_PHONE_NUMBER_RAW}`,
          }}
          label={(
            <div className={styles.phoneNumber}>
              <Icon path={mdiPhone}/>
              <span>{DCC_PHONE_NUMBER_FORMATTED}</span>
            </div>
          )}
        />
        <CheckboxControl
          className={styles.checkbox}
          size={'extra-small'}
          theme={'green'}
          label={t('Alarm_Detail_DCC.Disconnected.Mute_On_Join')}
          input={{
            checked: muted,
            onChange: onChangeMute,
          }}
        />
      </div>
    </div>
  );

});

export default Disconnected;
