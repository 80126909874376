import { useMemo } from 'react';
import type { BaseFormState, DropdownData, MessageTemplate } from '.';

export const useTemplates = <T extends BaseFormState>(templates: MessageTemplate<T>[]): DropdownData => {

  return useMemo(() => {

    return {
      values: Object.keys(templates),
      getMeta: (key) => ({
        key,
        label: templates[key].label,
      }),
      getValue: (key) => {

        return templates[key].value;

      },
    };

  }, [templates]);

};
