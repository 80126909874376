import React, {
  FC, memo, MutableRefObject, useCallback, useMemo,
} from 'react';

import { ContactableConfig, PopulatedAlarm } from 'types';
import {
  Overlay, ModalTitle, ModalGrid, useDraggableOverlay, GetAnimatedOverlayOutput,
} from 'components';
import {
  useLocale, useFunctionState, getLocal, setLocal,
} from 'lib';
import { Responder } from '@x-guard/xgac-types/xgac';
import type {
  ContactOptionCondition,
  ContactOptionImplProps,
  ContactOptionModalItemProps,
  ContactType,
} from '.';
import { contactOptionsMap } from './contactOptionsMap';
import styles from './style.module.scss';

type ContactOptionsProps = {
  overlayProps: GetAnimatedOverlayOutput;
  containerRef: MutableRefObject<HTMLDivElement>;
  config: ContactableConfig;
  alarm: PopulatedAlarm;
  onDismiss: () => void;
};

const optionNames = Object.keys(contactOptionsMap) as ContactType[];

export const getEnabledContactOptions = (contact: Responder): ContactType[] => {

  return optionNames.filter((optionName) => {

    // An item without a modal shouldn't be displayed
    if (contactOptionsMap[optionName].modalItem === null) {

      return false;

    }

    // Get and evaluate condition
    const condition: ContactOptionCondition = contactOptionsMap[optionName].enabled;

    return typeof condition === 'function' ? condition(contact) : condition;

  });

};

export const getResponderCanBeContacted = (contact: Responder): boolean => {

  return getEnabledContactOptions(contact).length > 0;

};

export const ContactOptions = memo<ContactOptionsProps>(({
  overlayProps,
  containerRef,
  config: {
    responder,
    contactType = null,
    onContactConfirmation,
  },
  onDismiss,
  alarm,
}) => {

  const { t } = useLocale();

  const [selectedOption, setSelectedOption] = useFunctionState<ContactType>(null);
  const [backHandler, setBackHandler] = useFunctionState(null);

  // Get an array with the options names to map
  const enabledOptions = useMemo<ContactType[]>(() => {

    return getEnabledContactOptions(responder);

  }, [responder]);

  const onDismissOption = useCallback(() => {

    setSelectedOption(null);
    setBackHandler(null);

  }, [setBackHandler, setSelectedOption]);

  // Select the handler for the 'back' navigation on the modal title.
  const onBack = selectedOption === null
    ? null
    : backHandler ?? onDismissOption;

  const isAlarmCreator = responder.info._id === alarm.asset._id;
  const activeOption: ContactType = selectedOption ?? contactType ?? null;

  const disallowDismissal = useMemo(() => {

    if (activeOption === null) {

      return false;

    }

    return contactOptionsMap[activeOption].disallowDismissal ?? false;

  }, [activeOption]);

  // Get the contact component to display, instead of the option grid
  const ContactOption: FC<ContactOptionImplProps> = useMemo(() => {

    if (activeOption === null) {

      return null;

    }

    return contactOptionsMap[activeOption].navigateTo;

  }, [activeOption]);

  const draggableProps = useDraggableOverlay({
    containerRef,
    offsetPersistence: {
      get: () => getLocal('contactOptionsOverlayOffset'),
      set: (value) => setLocal('contactOptionsOverlayOffset', value),
    },
  });

  return (
    <Overlay
      onDismiss={disallowDismissal === true ? null : onDismiss}
      {...draggableProps}
      {...overlayProps({ modal: true, centered: false, className: styles.overlay })}
    >
      <ModalTitle
        text={t('Alarm_Detail_Contact.Title', { name: responder.info.name })}
        onBack={disallowDismissal === true ? null : onBack}
        onClose={disallowDismissal === true ? null : onDismiss}
      />
      {ContactOption ? (
        <ContactOption
          isAlarmCreator={isAlarmCreator}
          onDismiss={onDismiss}
          alarm={alarm}
          responder={responder}
          setOnBack={setBackHandler}
          onContactConfirmation={onContactConfirmation}
        />
      ) : (
        <ModalGrid
          mode={'stretch'}
          columnLength={enabledOptions.length === 3 ? 3 : 2} /* Fewer than 3 options means we use a horizontal item alignment */
          borderAlignment={enabledOptions.length > 3 ? 'vertical' : 'horizontal'}
        >
          {enabledOptions.map((name) => {

            const ModalItem: FC<ContactOptionModalItemProps> = contactOptionsMap[name].modalItem;

            return (
              <ModalItem
                key={name}
                responder={responder}
                onClick={() => {

                  setSelectedOption(name);
                  setBackHandler(null);

                }}
              />
            );

          })}
        </ModalGrid>
      )}
    </Overlay>
  );

});
