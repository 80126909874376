import { useMemo } from 'react';
import { convertDistance } from 'geolib';
import { useLocale } from './useLocale';

import { toFixed } from '../math';

export const useDistanceLabel = (meters = 0): string => {

  const { t } = useLocale();

  return useMemo(() => {

    let distance = meters;

    // return meters
    if (distance < 500) {

      return t('Distance_Label.Meters', {
        distance: toFixed(distance),
      });

    }

    if (distance < 1000) {

      // remove significance for km
      distance = Math.round(distance / 10) * 10;

    } else {

      // remove significance for km
      distance = Math.round(distance / 100) * 100;

    }

    return t('Distance_Label.KiloMeters', {
      distance: toFixed(convertDistance(distance, 'km')),
    });

  }, [meters, t]);

};
