// React default component
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';

// moment
import moment, { Moment } from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nl';

import { useCalendarFormat, useLocale, FormatCalendar } from 'lib';
import { BetterMarkdown } from '../betterMarkdown';

type CalendarOptions = {
  includeTime?: boolean;
  boldTime?: boolean;
};

type UseCalendarStrings = (options: CalendarOptions) => FormatCalendar;

type BetterMomentProps = {
  calendar?: FormatCalendar;
  calendarOptions?: CalendarOptions;
  format?: string;
  timeFromNow?: boolean;
  children: moment.Moment;
};

type UseBetterMoment = (options: BetterMomentProps) => {
  text: string;
  title: string;
};

/**
 * Gets a memoized calendar. Prefer this to useMemo, because of the sheer amount of betterMoment components and the size
 * of an individual calendar in memo
 */
const useCalendarStrings: UseCalendarStrings = ({ includeTime = false, boldTime = false }) => {

  const { t } = useLocale();

  return useMemo(() => {

    const time = (includeAt: boolean): string => {

      if (!includeTime) return '';

      const hours = boldTime ? '**HH:mm:ss**' : 'HH:mm:ss';

      return includeAt ? `[${t('Dates.At')}] ${hours}` : hours;

    };

    return {
      sameMinute: `**[${t('Dates.Now')}]**`,
      lastDay: `[${t('Dates.Yesterday')}] ${time(true)}`,
      sameDay: `[${t('Dates.Today')}] ${time(true)}`,
      nextDay: `[${t('Dates.Tomorrow')}] ${time(true)}`,
      lastWeek: `[${t('Dates.Last')}] dddd ${time(true)}`,
      nextWeek: `dddd [${t('Dates.At')}] ${time(false)}`,
      sameElse: `DD/MM/YYYY ${time(false)}`,
    };

  }, [includeTime, boldTime, t]);

};

const useTextOutput = (value: Moment, format: string, timeFromNow: boolean) => {

  const getTextOutput = useCallback(() => {

    return [
      value.format(format),
      timeFromNow && `(${value.fromNow()})`,
    ].filter(Boolean).join(' ');

  }, [value, format, timeFromNow]);

  const [text, setText] = useState(getTextOutput);

  // Check every second if the text needs to be updated
  useEffect(() => {

    const interval = setInterval(() => {

      const newText = getTextOutput();

      if (newText !== text) setText(newText);

    }, 1000);

    return () => clearInterval(interval);

  }, [getTextOutput, text]);

  return text;

};

const useBetterMoment: UseBetterMoment = ({
  calendar,
  calendarOptions,
  children,
  format,
  timeFromNow,
}) => {

  const { iso } = useLocale();

  const value = moment(children).locale(iso);

  const calendarStrings = useCalendarStrings(calendarOptions ?? {});
  const actualCalendar = calendar ?? calendarStrings;
  const actualFormat = useCalendarFormat(actualCalendar, value);
  const text = useTextOutput(value, format ?? actualFormat, timeFromNow);

  return {
    text,
    title: value.format('DD-MM-YYYY HH:mm:ss'),
  };

};

/**
 * Better Moment. Because normal Moment is not good enough.
 */
export const BetterMoment = memo<BetterMomentProps>((props) => {

  const { text, title } = useBetterMoment(props);

  return (
    <time title={title}>
      <BetterMarkdown>
        {text}
      </BetterMarkdown>
    </time>
  );

});
