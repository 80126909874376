import React, { FC } from 'react';
import styles from './style.module.scss';

type ProgressBarProps = {
  current: number;
  total: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({
  current,
  total,
}) => {

  return (
    <div className={styles.progressContainer}>
      <div
        className={styles.fill}
        style={{
          width: `${((current / total) * 100).toFixed(0)}%`,
        }}
      />
    </div>
  );

};
