import { useEffect, useState } from 'react';

const findBreakpointByWidth = (width: number, breakpoints: number[]): number => {

  for (const idx of Array.from(breakpoints.keys()).reverse()) {

    if (width < breakpoints[idx]) {

      return idx;

    }

  }

  return breakpoints.length;

};

export const useBreakpoint = (breakpoints: number[]): [number] => {

  const [breakpoint, setBreakpoint] = useState(findBreakpointByWidth(window.innerWidth, breakpoints));

  useEffect(() => {

    const handleWindowResize = () => {

      const newBreakpoint = findBreakpointByWidth(window.innerWidth, breakpoints);
      if (newBreakpoint !== breakpoint) {

        setBreakpoint(newBreakpoint);

      }

    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);

  });

  return [breakpoint];

};
