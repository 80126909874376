import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  multipleCustomersExistsSelector, userContextSelector,
} from 'features/identity/auth/exports';
import { connectedCustomerSelector } from 'features/identity/exports';
import { useLocale, useToggle, getDisplayName } from 'lib';
import { SplitChipButton } from 'components/atoms';
import { mdiAccount, mdiClose, mdiCog } from '@mdi/js';
import { Location } from '../location';
import { useMuteButton } from './useMuteButton';
import { Menu } from './menu';
import styles from '../style.module.scss';
import { AlarmButtons } from './alarmButtons';

type AuthorizedHeaderProps = {
  hide: boolean;
};

export const AuthorizedHeader: FC<AuthorizedHeaderProps> = ({ hide }) => {

  const { t } = useLocale();

  const customer = useSelector(connectedCustomerSelector);
  const userContext = useSelector(userContextSelector);
  const multipleCustomers = useSelector(multipleCustomersExistsSelector);
  const [initiallyOpen, setInitiallyOpen] = useToggle(false);
  const [menuVisible, toggleMenuVisibility] = useToggle(false);
  const settingsButton = useRef<HTMLDivElement>();
  const buttonProps = useMuteButton();
  const dispatch = useDispatch();

  // If the dropdown is initially open, toggle it back to false for the next render
  useEffect(() => {

    if (initiallyOpen) {

      setInitiallyOpen(false);

    }

  }, [initiallyOpen, setInitiallyOpen]);

  const logoutHandler = () => {

    // confirm is fine here
    if (window.confirm(t('TopBar_Logout_Text'))) { // eslint-disable-line

      dispatch(logout());

    }

  };

  const dismissHandler = useCallback(() => {

    toggleMenuVisibility(false);

  }, [toggleMenuVisibility]);

  return (
    <div className={cx(styles.header, hide && styles.hide)}>
      {menuVisible && (
        <Menu
          initiallyOpen={initiallyOpen}
          dismiss={dismissHandler}
          settingsButton={settingsButton}
        />
      )}
      <div className={styles.contentRow}>
        <div
          className={styles.settings}
          onClick={() => toggleMenuVisibility()}
          ref={settingsButton}
        >
          <Icon path={mdiCog}/>
        </div>
        <Location/>
        <AlarmButtons/>
        <SplitChipButton {...buttonProps}/>
      </div>
      <div className={styles.userContainer}>
        <div>
          <Icon path={mdiAccount}/>
        </div>
        <div className={styles.user}>
          <span className={styles.name}>{getDisplayName(userContext)}</span>
          <span
            className={cx(styles.customer, multipleCustomers && styles.clickable)}
            onClick={() => {

              if (multipleCustomers) {

                toggleMenuVisibility(true);
                setInitiallyOpen(true);

              }

            }}
          >
            {customer?.name ?? 'No customer'}
          </span>
        </div>
        <div className={styles.divider}/>
        <div className={styles.logout} onClick={logoutHandler}>
          <Icon path={mdiClose}/>
        </div>
      </div>
    </div>
  );

};
