import React, { memo } from 'react';

import { Icon } from '@mdi/react';
import cx from 'clsx';

import { Chip, Image } from 'components';
import { mdiVolumeMute } from '@mdi/js';
import Timer, { TimerProps } from './timer';
import styles from './style.module.scss';

export type ItemProps = {
  icon?: string;
  image?: string;
  name: string;
  label?: string;
  timer?: TimerProps;
  muted: boolean;
  toggleMuted: () => void;
};

const Item = memo<ItemProps>((props) => {

  const {
    icon,
    image,
    name,
    label,
    timer,
    muted,
  } = props;

  return (
    <div className={styles.itemContainer}>
      <div className={styles.item}>
        <div className={styles.iconContainer}>
          {icon && (
            <Icon className={styles.icon} path={icon}/>
          )}
          {image && (
            <Image className={styles.image} src={image} alt={'user'}/>
          )}
          <Icon
            className={cx(
              styles.muted,
              muted && styles.active,
            )}
            path={mdiVolumeMute}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            {timer && (
              <>
                <span className={styles.timer}>
                  <Timer {...timer}/>
                </span>
                <div className={styles.verticalRule}/>
              </>
            )}
            <span className={styles.name}>{name}</span>
          </div>
          <div className={styles.right}>
            {label && (
              <Chip
                className={styles.labelChip}
                size={'small'}
                variant={'primary'}
                label={(
                  <span className={styles.label}>{label}</span>
                )}
              />
            )}
            {/* <Options
              muted={muted}
              toggleMuted={toggleMuted}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );

});

export default Item;
