import { createAction, createSelector } from '@reduxjs/toolkit';
import {
  BCPLang, CustomerNode, ISOLang, S, UsedCustomer,
} from 'types';
import { userIdSelector } from 'features/identity/auth/exports';
import { UserPreferences } from 'lib';

export type SetCustomerArgs = { node: CustomerNode; userId: string };
export type RemoveUsedCustomerArgs = { node: UsedCustomer; userId: string };

export const setCustomer = createAction<SetCustomerArgs>('shared/setCustomer');
export const setSelectedLocale = createAction<BCPLang>('identity/preferences/setSelectedLocale');
export const setMute = createAction<boolean>('identity/preferences/setMute');
export const removeUsedCustomer = createAction<RemoveUsedCustomerArgs>('identity/preferences/removeUsedCustomer');
export const setBlockNotifications = createAction<boolean>('identity/preferences/setBlockNotifications');

export const mutedSelector: S<boolean> = (state) => state.identity.preferences.muted;
export const bcpLangSelector: S<BCPLang> = (state) => state.identity.preferences.selectedLocale.bcp;
export const isoLangSelector: S<ISOLang> = (state) => state.identity.preferences.selectedLocale.iso;
export const blockNotificationsSelector: S<boolean> = (state) => state.identity.preferences.blockNotifications;
export const userPreferencesSelector: S<Record<string, UserPreferences>> = (state) => state.identity.preferences.userPreferences;
export const usedCustomersSelector: S<UsedCustomer[]> = createSelector(
  userIdSelector,
  userPreferencesSelector,
  (userId, userPreferences) => {

    return userPreferences[userId]?.usedCustomers ?? [];

  },
);
